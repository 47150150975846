import { MenuItem, Stack, Box, FormControl, Select } from '@mui/material'
import React, { useState } from 'react'
import { TextInputField } from './textInputField'

interface IAmountInputType {
    inputValue: any
    onInputChange?: any
    addClass?: any
    anyValidation?: any
}
export const AmountInputType: React.FC<IAmountInputType> = ({ inputValue, onInputChange, addClass, anyValidation }) => {
    const [currency, setCurrency] = useState('XAF')
    const handleCurrency = (event: any): void => {
        setCurrency(event.target.value as string)
    }
    return (
        <>
            <Stack direction='row' alignItems='center' className='!w-full h-12'>
                <Stack className=''>
                    <Box sx={{ width: 'fit-content', height: '47px' }} className='bg-light relativeborder border-r-0 border-secondary-xlight' >
                        <FormControl fullWidth className='relative bg-light !h-full'>
                            <Select
                                labelId='currency-select-label'
                                id='currency-select'
                                value={currency}
                                onChange={handleCurrency}
                                style={{ borderRadius: '6px', height: '' }}
                                className='bg-light absolute !top-0 !rounded-r-none !h-full'
                            >
                                <MenuItem value='XAF' className=''>XAF</MenuItem>
                                <MenuItem value='USD' className=''>USD</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>
                <Stack className='w-full'>
                    <TextInputField
                        onChange={onInputChange}
                        name='contribution-per-member'
                        value={inputValue}
                        anyValidation={anyValidation}
                        addClass={`!rounded-s-none ${addClass as string}`} />
                </Stack>
            </Stack>

        </>
    )
}
