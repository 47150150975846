import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../redux/app/create_njangee_store/store'

export interface UserAuth {
    user: any
}

const initialState: UserAuth = {
    user: {}
}

export const createUserAuthSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserAuth: (state, action: PayloadAction<UserAuth>) => {
            return {
                ...state,
                user: action.payload.user
            }
        }
    }
})

export const { setUserAuth } = createUserAuthSlice.actions

export const createUserAuthSelector = (state: RootState): UserAuth => state.createUserAuthReducer
export default createUserAuthSlice.reducer
