import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface GetJoinedGroups {
    groups: any[]
    meta: any
}

const initialState: GetJoinedGroups = {
    groups: [],
    meta: {}
}

export const createGetJoinedGroupsSlice = createSlice({
    name: 'get_joined_groups',
    initialState,
    reducers: {
        setGetJoinedGroups: (state, action: PayloadAction<GetJoinedGroups>) => {
            return {
                ...state,
                groups: action.payload.groups,
                meta: action.payload.meta
            }
        }
    }
})

export const { setGetJoinedGroups } = createGetJoinedGroupsSlice.actions

export const createGetJoinedGroupsSelector = (state: RootState): GetJoinedGroups => state.createGetJoinedGroupsReducer
export default createGetJoinedGroupsSlice.reducer
