import React from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Toolbar, IconButton, Stack } from '@mui/material'
import Logo from '../../../images/web_images/logo/njamgee-Logo.png'
import { LanguageDropdown } from '../../user/common/fragments/language_dropDown'
import { MobileWebLeftMenu } from './fragments/mobileLeftNav'

export const MobileWebHeader: React.FC = () => {
    return (
        <AppBar position='static' className='!bg-white app-bar-mobile border-b border-primary-3xlight sm:!flex md:!hidden'>
            <Toolbar className='h-full flex flex-wrap md:gap-8'>
                <MobileWebLeftMenu />
                <IconButton className='text-center ml-auto mr-auto' aria-label='logo'>
                    <Link to='/'><img alt='' src={Logo} height='35px' width='120px' className='' /></Link>
                </IconButton>

                <Stack direction='row' className='h-full nav-menu-2 lg:ml-16 !ml-auto'>
                    <LanguageDropdown textColor='!text-black' />
                </Stack>
            </Toolbar>
        </AppBar>
    )
}
