import React from 'react'
import { Box, FormControl, MenuItem, Select, Stack } from '@mui/material'
import CameroonFlag from '../../../images/dashboard_images/icon-flag-camer.png'
import { useAppSelector } from '../../../redux/app/create_njangee_store/hooks'
import { createCountriesSelector } from '../../common/slices/countrySlice'

interface ISelectCountryInput {
    label: string
    countryValue?: any
    anyValidation?: any
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}
export const SelectCountryInput: React.FC<ISelectCountryInput> = ({ label, countryValue, anyValidation, onChange }) => {
    const countries = useAppSelector(createCountriesSelector)
    return (
        <Stack className='w-full gap-1'>
            <p className='font-semibold'>{label}</p>
            <Stack direction='row' className='w-full'>
                <Stack className='bg-th-white py-1 px-2 border border-r-0 border-secondary-xlight rounded-l w-fit h-full flex items-center justify-center'>
                    <img alt='' src={CameroonFlag} className='h-7 w-12' />
                </Stack>
                <Box sx={{ width: '100%' }} >
                    <FormControl fullWidth>
                        <Select
                            id='country-select'
                            defaultValue={countryValue}
                            onChange={onChange}
                            style={{ borderRadius: '6px', height: '45px' }}
                            className='bg-white !rounded-l-none !border-l-0 '
                            {...anyValidation}
                        >
                            <MenuItem value='' className=''>Select Country...</MenuItem>
                            {countries === undefined
                                ? <MenuItem value='' className=''>Loading...</MenuItem>
                                : countries?.countries?.map((country: any) => {
                                    return <MenuItem key={country.id} value={country.countryCode} className=''>{country.name}</MenuItem>
                                })

                            }
                        </Select>
                    </FormControl>
                </Box>
            </Stack>
        </Stack>
    )
}
