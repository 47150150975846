import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer, Box, IconButton, Stack } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'
import PlayNjangeeIcon from '../../../../images/icon_images/icon-play.svg'
import AddIcon from '../../../../images/icon_images/icon-add.svg'
import HomeIcon from '../../../../images/icon_images/icon-dashboard.svg'
import GroupsIcon from '../../../../images/icon_images/icon-group.svg'
import PaymentIcon from '../../../../images/icon_images/icon-payment.svg'
import ReminderIcon from '../../../../images/icon_images/icon-reminder-2.svg'
import SettingsIcon from '../../../../images/icon_images/icon-settings-2.svg'
import ProfileIcon from '../../../../images/icon_images/icon-profile.svg'
import HelpIcon from '../../../../images/icon_images/icon-help-2.svg'
import InviteFriendsIcon from '../../../../images/icon_images/icon-invite-2.svg'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import {
  showModalF,
  showModalSelector
} from '../../njangeeGroupModals/slices/showModalSlice'

export const MobileLeftMenu: React.FC = () => {
  const { t } = useTranslation(['dashboard'])
  const showModalState = useAppSelector(showModalSelector)
  const dispatch = useAppDispatch()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [openAddNew, setOpenAddNew] = useState(false)
  const showAddNewDropDown = (): void => {
    setOpenAddNew(!openAddNew)
  }
  const showNjangeeModal = (): void => {
    dispatch(showModalF({ ...showModalState, showModal: true }))
    setIsDrawerOpen(false)
  }

  return (
    <div className='mobile-left-menu'>
      <IconButton
        size='large'
        edge='start'
        arai-label='logo'
        onClick={() => {
          setIsDrawerOpen(true)
        }}
      >
        <MenuIcon className='text-th-white' />
      </IconButton>
      <Drawer
        anchor='left'
        className='dash-drawer'
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false)
        }}
      >
        <Box
          width={{
            xs: 'auto',
            sm: '230px'
          }}
          height='100%'
          className='drawer-box bg-primary h-full !mb-0'
          role=''
        >
          <Stack
            direction='column'
            spacing={2}
            className='!h-fit !flex md:!hidden'
          >
            <NavLink
              className={({ isActive }) =>
                isActive ? 'flex items-center activeNav' : 'flex items-center'
              }
              to='/user-groups/play-njangee'
            >
              <img
                src={PlayNjangeeIcon}
                alt=''
                className='pr-2 nav-menu-icon'
              />{' '}
              {t('general.navigation.play_njangee')}
            </NavLink>

            <Stack className='!mt-0'>
              <button
                onClick={showAddNewDropDown}
                className={`flex items-center !w-full ${
                  openAddNew ? 'activeNav' : ''
                }`}
              >
                <img src={AddIcon} alt='' className='pr-2 nav-menu-icon' />
                {t('general.navigation.add_new')}
                {!openAddNew && <ExpandMore className='ml-auto' />}
                {openAddNew && <ExpandLess className='ml-auto' />}
              </button>
              {openAddNew && (
                <Stack
                  className={`pl-6 ${openAddNew && 'animation-slide-down'}`}
                >
                  <button
                    className='flex items-center bg-none border-0'
                    onClick={showNjangeeModal}
                  >
                    {' '}
                    {t('general.common.new_njangee_group')}
                  </button>
                  <NavLink className='flex items-center' to='/'>
                    {' '}
                    {t('general.navigation.new_announcement')}
                  </NavLink>
                  <NavLink
                    className='flex items-center'
                    to='/user-settings?payment-methods'
                  >
                    {' '}
                    {t('general.navigation.new_payment_method')}
                  </NavLink>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Stack direction='column' spacing={2} className='!h-fit'>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'flex items-center activeNav' : 'flex items-center'
              }
              to='/user'
            >
              <img src={HomeIcon} alt='' className='pr-2 nav-menu-icon' />{' '}
              {t('general.navigation.dashboard')}
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? 'flex items-center activeNav' : 'flex items-center'
              }
              to='/user-groups'
            >
              <img src={GroupsIcon} alt='' className='pr-2 nav-menu-icon' />{' '}
              {t('general.navigation.groups')}
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? 'flex items-center activeNav' : 'flex items-center'
              }
              to='/user-payments'
            >
              <img src={PaymentIcon} alt='' className='pr-2 nav-menu-icon' />{' '}
              {t('general.navigation.payments')}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'flex items-center activeNav' : 'flex items-center'
              }
              to='/user-invitations'
            >
              <img
                src={InviteFriendsIcon}
                alt=''
                className='pr-2 nav-menu-icon'
              />{' '}
              {t('dashboard.invitations')}
            </NavLink>

            {/* <NavLink
              className={({ isActive }) =>
                isActive ? 'flex items-center activeNav' : 'flex items-center'
              }
              to='/dsfjdkjk'
            >
              <img
                src={AnnouncementsIcon}
                alt=''
                className='pr-2 nav-menu-icon'
              />{' '}
              {t('general.navigation.announcements')}
            </NavLink> */}
            <NavLink
              className={({ isActive }) =>
                isActive ? 'flex items-center activeNav' : 'flex items-center'
              }
              to='/'
            >
              <img src={ReminderIcon} alt='' className='pr-2 nav-menu-icon' />{' '}
              {t('general.navigation.reminder')}
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? 'flex items-center activeNav' : 'flex items-center'
              }
              to='/user-settings'
            >
              <img src={SettingsIcon} alt='' className='pr-2 nav-menu-icon' />{' '}
              {t('general.navigation.settings')}
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? 'flex items-center activeNav' : 'flex items-center'
              }
              to='/user-settings?edit-profile'
            >
              <img src={ProfileIcon} alt='' className='pr-2 nav-menu-icon' />{' '}
              {t('general.navigation.profile')}
            </NavLink>
          </Stack>

          <Stack direction='column' spacing={2} className='!h-fit'>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'flex items-center activeNav' : 'flex items-center'
              }
              to='/'
            >
              <img
                src={InviteFriendsIcon}
                alt=''
                className='pr-2 nav-menu-icon'
              />{' '}
              {t('general.navigation.invite_friends')}
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? 'flex items-center activeNav' : 'flex items-center'
              }
              to='/contact'
            >
              <img src={HelpIcon} alt='' className='pr-2 nav-menu-icon' />{' '}
              {t('general.navigation.help_n_support')}
            </NavLink>
          </Stack>
        </Box>
      </Drawer>
    </div>
  )
}
