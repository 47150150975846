import React from 'react'
import { AppModalSkeleton } from '../../common/fragments/AppModalSkeleton'
import { Stack } from '@mui/material'
import MemberImg1 from '../../../../images/dashboard_images/dashboard-group-profile-img-1@2x.png'
import MemberProfile from '../../../../images/dashboard_images/dashboard-invitations-userThumb-1@2x.png'
import { Circle } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import {
  getGroupFrequencyString,
  getTimeAgo
} from '../../common/helper/dateTimeFunctions'

interface IRejectInvitationModal {
  groupInfo: any
  showModal: boolean
  handleReject: () => void
  handleClose: () => void
}
export const RejectInvitationModal: React.FC<IRejectInvitationModal> = ({
  groupInfo,
  showModal,
  handleReject,
  handleClose
}) => {
  const { t } = useTranslation(['dashboard'])
  if (!showModal || groupInfo === null) {
    return null
  }

  return (
    <AppModalSkeleton noBackdrop={true} showModal={showModal}>
      <Stack className='py-1 px-3 md:px-7'>
        <p className='text-3xl font-bold'>
          {t('invitations.reject_invitation')}
        </p>
        <br />
        <Stack className='py-4 bg-primary-5xlight rounded-xl w-full'>
          <Stack
            direction='row'
            className='flex-wrap xl:flex-nowrap px-4 items-start gap-4'
          >
            <img
              alt=''
              src={MemberImg1}
              // style={{ height: '130px !important', width: ' !important' }}
              className='rounded-sm !w-[60px] md:!w-[120px]'
            />
            <Stack className='w-fit'>
              <small className='text-secondary text-10'>
                {' '}
                {getTimeAgo(groupInfo?.updatedAt)}
              </small>
              <Stack className='gap-1 pb-2 pr-2'>
                <p className='text-2xl font-bold'>
                  {groupInfo.group.name ?? '-'}
                </p>
                <Stack direction='row' className='gap-3'>
                  <img
                    src={MemberProfile}
                    className='h-5 w-5 rounded-full'
                    alt=''
                  />
                  <p className='text-secondary'>
                    {' '}
                    {t('invitations.sent_by')}{' '}
                    {groupInfo?.invitedBy.firstName ?? '-'}{' '}
                    {groupInfo?.invitedBy.lastName ?? ''}
                  </p>
                </Stack>
                <Stack direction='row' className='gap-4 flex-wrap'>
                  <Stack direction='row' className='gap-1 items-center'>
                    <Circle className='text-9 text-blue-light2' />
                    <p className='text-secondary font-bold'>
                      15 {t('invitations.members')}
                    </p>
                  </Stack>
                  <Stack direction='row' className='gap-1 items-center'>
                    <Circle className='text-9 text-blue-light2' />
                    <p className='text-secondary font-bold'>
                      XAF {groupInfo?.group.amount ?? '0'}/
                      {t(`
                            ${getGroupFrequencyString(
                              groupInfo?.group?.frequency
                            )}
                          `)}
                    </p>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack className='pt-4 pb-1'>
          <p className='font-bold text-base text-black text-opacity-75'>
            {t('invitations.reason_for_reject_label')}
          </p>
          <textarea
            name=''
            id=''
            rows={8}
            placeholder='I am rejecting because ...'
            className='placeholder:font-bold placeholder:text-slate-400'
          ></textarea>
          <br />
          <Stack direction='row' className='flex-wrap gap-4 py-2'>
            <button
              className='btn btn-primary !px-8'
              type='button'
              onClick={handleReject}
            >
              {t('invitations.reject')}
            </button>
            <button
              className='btn bg-primary-4xlight !px-8 text-secondary'
              type='button'
              onClick={handleClose}
            >
              {t('general.common.cancel')}
            </button>
          </Stack>
        </Stack>
      </Stack>
    </AppModalSkeleton>
  )
}
