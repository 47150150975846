import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface GroupDetail {
  id: number
  group: any
  allMyContributions: any
  isLoading: boolean
  contributionFilters?: {
    from?: string
    to?: string
    status?: string
  }
}

const initialState: GroupDetail = {
  id: 0,
  group: [],
  allMyContributions: [],
  isLoading: false,
  contributionFilters: {
    from: '',
    to: '',
    status: ''
  }
}

export const groupDetailSlice = createSlice({
  name: 'group_detail',
  initialState,
  reducers: {
    setGroupDetail: (state, action: PayloadAction<GroupDetail>) => {
      return {
        ...state,
        id: action.payload.id,
        group: action.payload.group,
        allMyContributions: action.payload.allMyContributions,
        isLoading: action.payload.isLoading
      }
    },
    setContriutionFilters: (state, action: PayloadAction<GroupDetail>) => {
      return {
        ...state,
        contributionFilters: action.payload.contributionFilters
      }
    }
  }
})

export const { setGroupDetail, setContriutionFilters } =
  groupDetailSlice.actions

export const groupDetailSelector = (state: RootState): GroupDetail =>
  state.groupDetailReducer
export default groupDetailSlice.reducer
