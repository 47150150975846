import React, { useEffect, useState } from 'react'
import { Stack, IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ActiveArrowNavLeft from '../../../../images/icon_images/icon-navigation-circle-active-arrowleft.svg'
import ActiveArrowNavRight from '../../../../images/icon_images/icon-navigation-circle-active-arrowright.svg'
import InactiveArrowNavLeft from '../../../../images/icon_images/icon-navigation-circle-inactive-arrowleft.svg'
import InactiveArrowNavRight from '../../../../images/icon_images/icon-navigation-circle-inactive-arrowright.svg'
import { CreatedNjangiGroups } from './createdNjangiGroups'
import { JoinedNjangiGroups } from './joinedNjangiGroups'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import {
  createDashboardStateSelector,
  updateCreatedDisplay,
  updateJoinedDisplay
} from '../slices/dashboardSlice'
import {
  createGetCreatedGroupsSelector,
  setGetCreatedGroups
} from '../slices/createdGroupsSlice'
import { appGetRequest } from '../../../common/helper/httpRequests'
import {
  createGetJoinedGroupsSelector,
  setGetJoinedGroups
} from '../slices/joinedGroupsSlice'

export const NjangiGroups: React.FC = () => {
  const { t } = useTranslation(['dashboard'])
  const [showCreatedGroups, setShowCreatedGroups] = useState<boolean>(true)
  const createdGroupDisplay = useAppSelector(createDashboardStateSelector)
  const createdGroupsData = useAppSelector(createGetCreatedGroupsSelector)
  const joinedGroupsData = useAppSelector(createGetJoinedGroupsSelector)
  const dispatch = useAppDispatch()
  //   const [groupLength, setGroupLength] = useState(createdGroupsData.groups.length)

  const onShowCreatedGroups = (): void => {
    setShowCreatedGroups(true)
  }
  const onShowJoinedGroups = (): void => {
    setShowCreatedGroups(false)
  }

  const showNext = async (): Promise<void> => {
    const { initial, final } = showCreatedGroups
      ? createdGroupDisplay.createdGroupDisplay
      : createdGroupDisplay.joinedGroupDisplay
    const groupsLength = showCreatedGroups
      ? createdGroupsData.groups.length
      : joinedGroupsData.groups.length

    if (initial >= 0 && final >= 2 && groupsLength >= final) {
      const updateAction = showCreatedGroups
        ? updateCreatedDisplay
        : updateJoinedDisplay

      dispatch(
        updateAction({
          ...createdGroupDisplay,
          [showCreatedGroups ? 'createdGroupDisplay' : 'joinedGroupDisplay']: {
            initial: initial + 2,
            final: final + 2
          }
        })
      )
    }
  }

  const showPrev = (): void => {
    const { initial, final } = showCreatedGroups
      ? createdGroupDisplay.createdGroupDisplay
      : createdGroupDisplay.joinedGroupDisplay
    const groupsLength = showCreatedGroups
      ? createdGroupsData.groups.length
      : joinedGroupsData.groups.length

    if (initial > 0 && final > 2 && groupsLength <= final) {
      const updateAction = showCreatedGroups
        ? updateCreatedDisplay
        : updateJoinedDisplay

      dispatch(
        updateAction({
          ...createdGroupDisplay,
          [showCreatedGroups ? 'createdGroupDisplay' : 'joinedGroupDisplay']: {
            initial: initial - 2,
            final: final - 2
          }
        })
      )
    }
  }

  const getJoinedGroups = async (): Promise<void> => {
    try {
      const allGroups = await appGetRequest('/groups?joinedByMe=true')

      dispatch(
        setGetJoinedGroups({
          ...joinedGroupsData,
          groups: allGroups.data.data,
          meta: allGroups.data.meta
        })
      )
    } catch (error) {
      // console.error(error)
    }
  }

  const getCreatedGroups = async (): Promise<void> => {
    dispatch(
      setGetCreatedGroups({
        ...createdGroupsData,
        isLoading: true
      })
    )
    try {
      const allGroups = await appGetRequest('/groups?createdByMe=true')
      dispatch(
        setGetCreatedGroups({
          ...createdGroupsData,
          groups: allGroups.data.data,
          meta: allGroups.data.meta,
          isLoading: false
        })
      )
    } catch (error) {
      // console.error(error)
      dispatch(
        setGetCreatedGroups({
          ...createdGroupsData,
          isLoading: false
        })
      )
    }
  }
  useEffect(() => {
    void getCreatedGroups()
    void getJoinedGroups()
  }, [])

  return (
    <div className='njangi-groups section-box'>
      <Stack
        direction='row'
        className='border-0 border-b-w-1half border-main py-3 px-5'
      >
        <h6 className=''>{t('general.common.my_njangee_groups')}</h6>
        <Link
          to='/user-groups'
          className='ml-auto font-semibold text-black-medium'
        >
          {t('general.common.see_all')}
        </Link>
      </Stack>
      <Stack direction='column' className='py-4 px-5'>
        <Stack direction='row' className='items-center flex-wrap gap-4'>
          <Stack
            direction='row'
            spacing={1}
            className='group-option-buttons bg-light'
          >
            <button
              onClick={onShowCreatedGroups}
              className={`btn-option ${
                showCreatedGroups
                  ? 'btn-option btn-option-primary'
                  : 'btn-option btn-option-white'
              }`}
            >
              {t('general.common.created')}
            </button>
            <button
              onClick={onShowJoinedGroups}
              className={`btn-option ${
                !showCreatedGroups
                  ? 'btn-option btn-option-primary'
                  : 'btn-option btn-option-white'
              }`}
            >
              {t('general.common.joined')}
            </button>
          </Stack>
          <small>
            {showCreatedGroups
              ? t('general.common.overview_created_groups')
              : t('general.common.overview_joined_groups')}
          </small>
          <Stack
            direction='row'
            className='border border-b-w-1half border-main rounded-full bg-light ml-auto'
          >
            <>
              <IconButton onClick={showPrev} className='!py-1 !px-1'>
                <img
                  alt=''
                  src={
                    (showCreatedGroups
                      ? createdGroupDisplay.createdGroupDisplay.initial
                      : createdGroupDisplay.joinedGroupDisplay.initial) === 0
                      ? InactiveArrowNavLeft
                      : ActiveArrowNavLeft
                  }
                  className='!p-0 !px-1 text-red-700'
                />
              </IconButton>
              <span className='text-secondary border border-b-w-1half'></span>
              <IconButton onClick={showNext} className='!py-1 !px-1'>
                <img
                  alt=''
                  src={
                    (
                      showCreatedGroups
                        ? createdGroupsData.groups.length >=
                          createdGroupDisplay.createdGroupDisplay.final
                        : joinedGroupsData.groups.length >=
                          createdGroupDisplay.joinedGroupDisplay.final
                    )
                      ? ActiveArrowNavRight
                      : InactiveArrowNavRight
                  }
                  className='!p-0 !px-1'
                />
              </IconButton>
            </>
          </Stack>
        </Stack>

        <div className=''>
          {showCreatedGroups && <CreatedNjangiGroups />}
          {!showCreatedGroups && <JoinedNjangiGroups />}
        </div>
      </Stack>
    </div>
  )
}
