import React, { useEffect, useState } from 'react'
import { WelcomeModal } from './welcome'
import { GroupName } from './groupName'
import { GroupType } from './groupType'
import { ModalSkeleton } from './modalSkeleton'
import { MoreAboutGroup } from './moreAboutGroup'
import { InviteGroupMembers } from './inviteMembers'
import { Congrats } from './congrats'
import { useAppDispatch, useAppSelector } from '../../../redux/app/create_njangee_store/hooks'
import { showModalF, showModalSelector } from '../njangeeGroupModals/slices/showModalSlice'

export const NjangeeModalWizard: React.FC = () => {
    const showModalState = useAppSelector(showModalSelector)
    const [showModalX, setShowModalX] = useState(0)
    const [isTrue] = useState(true)
    const dispatchModal = useAppDispatch()

    const skipOnboarding = (): void => {
        dispatchModal(showModalF({ ...showModalState, showModal: !isTrue }))
    }

    const nextModal = (modalNumber: number): void => {
        setShowModalX(modalNumber)
    }
    useEffect(() => {
        setShowModalX(showModalState.modalIndex)
    }, [showModalState.modalIndex])
    return (
        <div className='create_njangee_group-modal'>
            <ModalSkeleton noBackdrop={showModalX === 5 ? isTrue : !isTrue}>
                {showModalX === 0 && <WelcomeModal nextModal={nextModal} />}
                {showModalX === 1 && <GroupName skipOnboarding={skipOnboarding} nextModal={nextModal} />}
                {showModalX === 2 && <GroupType skipOnboarding={skipOnboarding} nextModal={nextModal} />}
                {showModalX === 3 && <MoreAboutGroup skipOnboarding={skipOnboarding} nextModal={nextModal} />}
                {showModalX === 4 && <InviteGroupMembers skipOnboarding={skipOnboarding} nextModal={nextModal} />}
                {showModalX === 5 && <Congrats />}
            </ModalSkeleton>
        </div>
    )
}
