import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface Notification {
    descriptionTranslationString: string
    email: boolean
    name: string
    nameTranslationString: string
    notificationTypeId: number
    push: boolean
    slug: string
    sms: boolean
}

export interface Notifications {
    id?: number
    notifications: Notification[]
}

const initialState: Notifications = {
    id: 0,
    notifications: []
}

export const createNotificationsSlice = createSlice({
    name: 'user_profile',
    initialState,
    reducers: {
        setNotifications: (state, action: PayloadAction<Notifications>) => {
            return {
                ...state,
                id: action.payload.id,
                notifications: action.payload.notifications
            }
        }
    }
})

export const { setNotifications } = createNotificationsSlice.actions

export const createNotificationsSelector = (state: RootState): Notifications => state.createNotificationsReducer
export default createNotificationsSlice.reducer
