import React, { useState } from 'react'
import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ModalNavs } from './fragments/modalNavs'
import InviteGroupMembersIcon from '../../../images/icon_images/icon-onboarding-invite-.svg'
import { TextInputField } from '../common/textInputField'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  setInvitedMembers,
  invitedMembersSelector
} from '../njangeeGroupModals/slices/invitedMembersSlice'
import { inviteGroupMembersFunc } from '../common/helper/groupFunctions'
import { createGroupSelector } from './slices/newGroupSlices'
import { showModalF, showModalSelector } from './slices/showModalSlice'
import { toast } from 'react-toastify'

interface IModalValue {
  nextModal: (arg: number) => void
  skipOnboarding: () => void
}
export const InviteGroupMembers: React.FC<IModalValue> = ({
  nextModal,
  skipOnboarding
}) => {
  const invitedMembers = useAppSelector(invitedMembersSelector)
  const groupVals = useAppSelector(createGroupSelector)
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['dashboard'])

  const [contactType, setContactType] = useState('email')
  const [invitedMembersArray, setInvitedMembersArray] = useState<object[]>(
    invitedMembers.invitedMembers
  )
  const [nameError, setNameError] = useState(false)
  const [contactError, setContactError] = useState(false)
  const [phoneEmailValidation, setPhoneEmailValidation] = useState(true)
  const showModalState = useAppSelector(showModalSelector)
  const [isAddingMembers, setIsAddingMember] = useState(false)
  const [member, setMemberInfo] = useState({
    id: Date.now().toString(),
    name: '',
    contact: '',
    type: 'email'
  })

  const isValidEmail = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }
  const onChangeContactType = (e: any): void => {
    setContactType(e.target.value)
    setMemberInfo((prevVals) => {
      const newVals = { ...prevVals }
      newVals.type = e.target.value
      return newVals
    })
  }
  const onChangeName = (e: any): void => {
    setMemberInfo((prevVals) => {
      const newVals = { ...prevVals }
      newVals.name = e.target.value
      return newVals
    })
  }
  const onChangeContact = (e: any): void => {
    setMemberInfo((prevVals) => {
      const newVals = { ...prevVals }
      newVals.contact = e.target.value
      return newVals
    })
  }

  const addMember = (): void => {
    if (member.name !== '' && member.contact !== '') {
      setNameError(false)
      setContactError(false)
      if (contactType === 'email' && !isValidEmail(member.contact)) {
        setPhoneEmailValidation(false)
      } else {
        setInvitedMembersArray((previousMembers) => [
          ...previousMembers,
          member
        ])
        setMemberInfo((prevVals) => {
          const newVals = { ...prevVals }
          newVals.id = `${Date.now().toString()}`
          newVals.name = ''
          newVals.contact = ''
          newVals.type = contactType
          return newVals
        })
        const allMembers = invitedMembersArray.concat(member)
        console.log(allMembers)
        dispatch(
          setInvitedMembers({
            ...invitedMembers,
            invitedMembers: allMembers
          })
        )
        setPhoneEmailValidation(true)
      }
    } else if (member.name === '' && member.contact !== '') {
      setNameError(true)
      setContactError(false)
    } else if (member.name !== '' && member.contact === '') {
      setNameError(false)
      setContactError(true)
    } else {
      setNameError(true)
      setContactError(true)
    }
  }

  const inviteMembers = async (): Promise<void> => {
    const members = invitedMembers.invitedMembers
    if (members.length > 0) {
      setIsAddingMember(true)
      await inviteGroupMembersFunc(
        invitedMembers.invitedMembers,
        groupVals.id ?? ''
      )
      setIsAddingMember(false)
      dispatch(
        setInvitedMembers({
          ...invitedMembers,
          invitedMembers: []
        })
      )
      toast.success(t('main_modal.members_invited_successfully'))
      if (showModalState.skipCongrats) {
        dispatch(
          showModalF({ ...showModalState, showModal: false, modalIndex: 0 })
        )
      } else {
        nextModal(5)
      }
    } else {
      toast.warning(t('groups.please_add_ateast_1_member'))
    }
  }
  const ContactType = (): string => {
    if (contactType === 'email') {
      return 'e.g john@gmail.com'
    } else if (contactType === 'phone-number') {
      return 'e.g +237 6 98 57 78 98'
    } else {
      return ''
    }
  }

  const removeMember = (id: number): void => {
    setInvitedMembersArray((newMembers) =>
      newMembers.filter((val: any) => val.id !== id)
    )
    dispatch(
      setInvitedMembers({
        ...invitedMembers,
        invitedMembers: invitedMembersArray.filter((val: any) => val.id !== id)
      })
    )
  }
  return (
    <>
      <Stack
        direction='column'
        className='w-full justify-center text-center px-4 sm:px-7 lg:px-5 relative'
      >
        <img
          alt=''
          src={InviteGroupMembersIcon}
          className='w-fit h-fit mx-auto'
        />
        <h5 className='mt-1'>{t('main_modal.invite_group_members')}</h5>
        <small className='text-secondary'>
          {t('main_modal.invite_atleast_two_members')}:{' '}
          <span className='text-primary-bold font-extrabold'>
            June 1st, 2023
          </span>
        </small>
        <br />

        <Stack
          direction='column'
          className='items-start !w-full gap-2 bg-light rounded-md px-7 py-4'
        >
          <p className='font-semibold'>{t('main_modal.add_people')}</p>
          <Stack className='w-full text-left'>
            <TextInputField
              onChange={(e: any) => {
                onChangeName(e)
              }}
              addClass={`${nameError ? 'error' : ''}`}
              value={member.name}
              name='name'
              placeholder={t('main_modal.name_of_member')}
            />
            {nameError && (
              <small className='text-danger'>
                {t('main_modal.required_field')}!
              </small>
            )}
          </Stack>
          <Stack
            direction='row'
            className='items-center justify-start gap-7 w-fit'
          >
            <Stack
              direction='row'
              className='gap-2 items-center justify-start w-fit py-0 ml-0 sm:m-auto'
            >
              <input
                type='radio'
                name='contact-type'
                value='email'
                checked={contactType === 'email'}
                onChange={(e) => {
                  onChangeContactType(e)
                }}
              />
              <label
                className={`${
                  contactType !== 'email' ? 'text-black-medium' : ''
                }`}
              >
                {t('main_modal.email')}
              </label>
            </Stack>
            <Stack
              direction='row'
              className='gap-2 items-center justify-start w-fit py-0 ml-0 sm:m-auto'
            >
              <input
                type='radio'
                name='contact-type'
                value='phone-number'
                checked={contactType === 'phone-number'}
                onChange={(e) => {
                  onChangeContactType(e)
                }}
              />
              <label
                className={`${
                  contactType !== 'daily' ? 'text-black-medium' : ''
                }`}
              >
                {t('main_modal.phone_number')}
              </label>
            </Stack>
          </Stack>
          <Stack
            direction='row'
            className='items-start flex-wrap sm:flex-nowrap gap-3 w-full'
          >
            <Stack className='w-full text-left'>
              <TextInputField
                name='name'
                value={member.contact}
                addClass={`${
                  contactError || !phoneEmailValidation ? 'error' : ''
                }`}
                placeholder={`${ContactType()}`}
                type={`${contactType === 'email' ? 'email' : 'text'}`}
                onChange={(e: any) => {
                  onChangeContact(e)
                }}
              />
              {contactError && (
                <small className='text-danger'>
                  {t('main_modal.required_field')}!
                </small>
              )}
              {contactType === 'email' && !phoneEmailValidation && (
                <small className='text-danger'>
                  {t('main_modal.not_a_valid_email')}!
                </small>
              )}
            </Stack>
            <button
              onClick={addMember}
              className='btn ml-auto bg-secondary-xlight text-primary'
            >
              {t('main_modal.add')}
            </button>
          </Stack>
        </Stack>

        <Stack direction='column' className='mt-4 w-full items-start'>
          <Stack className='items-start border-b-w-1half w-full py-1'>
            <p className='text-primary font-semibold'>
              {invitedMembersArray.length} {t('main_modal.members_added')}
            </p>
          </Stack>

          {/* members  */}
          <Stack
            className='w-full h-52 overflow-y-scroll'
            sx={{ minHeight: '208px' }}
          >
            {invitedMembersArray.length > 0 ? (
              <Stack className='w-full overflow-y-scroll'>
                {invitedMembersArray.map(
                  (invitedMember: any, index: number) => {
                    return (
                      <Stack
                        key={invitedMember.id}
                        direction='row'
                        className='items-start w-full py-2'
                      >
                        <Stack className='w-full items-start'>
                          <p className=''>
                            {index + 1} - {invitedMember.name}
                          </p>
                          <small className='text-12 text-black-light'>
                            {invitedMember.contact}
                          </small>
                        </Stack>
                        <Button
                          onClick={() => {
                            removeMember(invitedMember.id)
                          }}
                          variant='text'
                          className='font-semibold text-primary-light ml-auto text-14 !normal-case'
                        >
                          {t('main_modal.remove')}
                        </Button>
                      </Stack>
                    )
                  }
                )}
              </Stack>
            ) : (
              <Stack className='w-full py-10'>
                <h6 className='text-black-light'>
                  {t('main_modal.no_members_added')}
                </h6>
              </Stack>
            )}
          </Stack>
        </Stack>
        <br />
        <br />
        <br />
        <br />
        <br className='block sm:!hidden' />
      </Stack>
      <Stack
        direction='row'
        className='!w-full py-4 items-center box-top-shadow bg-white absolute bottom-0 left-0 rounded-b-2xl'
      >
        <Stack className='!w-full'>
          <ModalNavs
            prevModal={() => {
              nextModal(3)
            }}
            nextModal={() => {
              void inviteMembers()
            }}
            skipOnboarding={skipOnboarding}
            activeModal={4}
            isLoading={isAddingMembers}
            buttonText={t('main_modal.send_invites')}
          />
        </Stack>
      </Stack>
    </>
  )
}
