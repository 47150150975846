import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface NewNjangeeGroup {
  id?: string
  name?: string
  groupType?: string
  contributionPerMember?: string
  contributionFrequency?: string
  membersOrder?: string
  sessionStartDate?: string
}

const today = new Date().toJSON().slice(0, 10)
const initialState: NewNjangeeGroup = {
  id: '',
  name: '',
  groupType: '',
  contributionPerMember: '',
  contributionFrequency: '',
  membersOrder: '',
  sessionStartDate: today
}

export const createGroupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setGroupId: (state, action: PayloadAction<NewNjangeeGroup>) => {
      return { ...state, id: action.payload.id }
    },
    setName: (state, action: PayloadAction<NewNjangeeGroup>) => {
      return { ...state, name: action.payload.name }
    },
    setGroupType: (state, action: PayloadAction<NewNjangeeGroup>) => {
      return { ...state, groupType: action.payload.groupType }
    },
    setMoreAboutGroup: (state, action: PayloadAction<NewNjangeeGroup>) => {
      return {
        ...state,
        contributionPerMember: action.payload.contributionPerMember,
        contributionFrequency: action.payload.contributionFrequency,
        membersOrder: action.payload.membersOrder,
        sessionStartDate: action.payload.sessionStartDate
      }
    },
    clearGroupData: (state) => {
      return {
        ...state,
        id: '',
        name: '',
        groupType: '',
        contributionPerMember: '',
        contributionFrequency: '',
        membersOrder: '',
        sessionStartDate: today
      }
    }
  }
})

export const {
  setGroupId,
  setName,
  setGroupType,
  setMoreAboutGroup,
  clearGroupData
} = createGroupSlice.actions

export const createGroupSelector = (state: RootState): NewNjangeeGroup =>
  state.createGroupReducer
export default createGroupSlice.reducer
