import React, { type ReactElement, forwardRef, useState } from 'react'
import { Stack, Snackbar, Alert, type AlertProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { PasswordField } from '@aws-amplify/ui-react'
import { CircularLoadingProgress } from '../../../registration/fragments/circularProgress'
import { CheckCircle, Info } from '@mui/icons-material'

const SnackbarAlert = forwardRef<HTMLDivElement, AlertProps>(
  function SnackbarAlert (props, ref): ReactElement {
    return (
      <Alert
        elevation={6}
        className='!bg-black !bg-opacity-90 text-th-white w-11/12 sm:w-9/12 md:w-8/12 lg:w-5/12'
        ref={ref}
        icon={<CheckCircle fontSize='small' className='text-success' />}
        {...props}
      />
    )
  }
)

export const ChangePassword: React.FC = () => {
  const [showPasswordMatch, setShowPasswordMatch] = useState(false)
  const [showCircleProgress, setShowCircleProgress] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [isTrue] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation(['dashboard'])
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm()

  const isValidPassword = (pwd: string): boolean => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
      pwd
    )
  }

  const formValidation = {
    old_password: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 8,
        matchPattern: (v: string) => isValidPassword(v)
      }
    },
    new_password: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 8,
        matchPattern: (v: string) => isValidPassword(v)
      }
    },
    confirm_new_password: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 8,
        matchPattern: (v: string) => isValidPassword(v)
      }
    }
  }
  const handleNewPassword = (): void => {
    setShowPasswordMatch(false)
  }

  const handleCloseSuccessMessage = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ): any => {
    if (reason === 'clickaway') {
      return
    }
    setShowSuccessMessage(false)
  }

  const handleChangePassword = async (data: any): Promise<void> => {
    setShowCircleProgress(true)
    if (data.new_password === data.confirm_new_password) {
      setShowPasswordMatch(false)
      setShowErrorMessage(false)
      const oldPwd = data.old_password
      const newPwd = data.new_password
      Auth.currentAuthenticatedUser()
        .then((user) => {
          Auth.changePassword(user, oldPwd, newPwd)
            .then((res) => {
              reset()
              setShowCircleProgress(false)
              setShowSuccessMessage(true)
              console.log(res)
            })
            .catch((error) => {
              setShowCircleProgress(false)
              console.error(error)
              if (error.code === 'NotAuthorizedException') {
                setErrorMessage('Incorrect old password.')
              } else if (error.code === 'LimitExceededException') {
                setErrorMessage(
                  'Attempt limit exceeded, please try after some time.'
                )
              } else {
                setErrorMessage('Network Error. Please try again.')
              }
              setShowErrorMessage(true)
            })
        })
        .catch((error) => {
          setShowCircleProgress(false)
          setErrorMessage('Network Error. Please try again.')
          setShowErrorMessage(true)
          console.error(error)
        })
    } else {
      setShowPasswordMatch(true)
      setShowCircleProgress(false)
    }
  }
  return (
    <Stack className='w-full px-4 md:px-12 my-5'>
      <Stack className='text-black-2'>
        <h6 className='uppercase leading-5 text-th-black'>
          {t('settings.change_password')}
        </h6>
        <p>{t('settings.change_password_caption')}</p>
      </Stack>
      <Stack className='w-full mt-5'>
        <Stack className='border border-secondary-xlight bg-white rounded px-5 md:px-7 py-5 w-full md:w-9/12 lg:w-6/12'>
          <form onSubmit={handleSubmit(handleChangePassword)}>
            <Stack className='w-full gap-4'>
              <Stack className='w-full gap-1'>
                <PasswordField
                  {...register('old_password', formValidation.old_password)}
                  label={t('settings.old_password')}
                  placeholder={t('registration.atleast_8_chars')}
                  className={
                    errors._old_password?.type === 'required'
                      ? 'input-error'
                      : ''
                  }
                  onChange={handleNewPassword}
                />
                {errors.old_password?.type === 'required' && (
                  <small className='text-danger'>
                    {t('registration.password_required')}
                  </small>
                )}
                {errors.old_password?.type === 'minLength' && (
                  <small className='text-danger'>
                    {t('registration.password_has_atleast_8_chars')}
                  </small>
                )}
                {errors.old_password?.type === 'matchPattern' && (
                  <small className='text-danger'>
                    {t('registration.must_be_valid_password')}
                  </small>
                )}
              </Stack>
              <Stack className='w-full gap-1'>
                <PasswordField
                  {...register('new_password', formValidation.new_password)}
                  label={t('settings.new_password')}
                  placeholder={t('registration.atleast_8_chars')}
                  className={
                    errors.new_password?.type === 'required'
                      ? 'input-error'
                      : ''
                  }
                  onChange={handleNewPassword}
                />
                {errors.new_password?.type === 'required' && (
                  <small className='text-danger'>
                    {t('registration.password_required')}
                  </small>
                )}
                {errors.new_password?.type === 'minLength' && (
                  <small className='text-danger'>
                    {t('registration.password_has_atleast_8_chars')}
                  </small>
                )}
                {errors.new_password?.type === 'matchPattern' && (
                  <small className='text-danger'>
                    {t('registration.must_be_valid_password')}
                  </small>
                )}
                {showPasswordMatch && (
                  <small className='text-danger'>
                    {t('registration.passwords_dont_match')}.
                  </small>
                )}
              </Stack>
              <Stack className='w-full gap-1'>
                <PasswordField
                  {...register(
                    'confirm_new_password',
                    formValidation.confirm_new_password
                  )}
                  label={t('settings.repeat_new_password')}
                  placeholder={t('registration.atleast_8_chars')}
                  className={
                    errors.confirm_new_password?.type === 'required'
                      ? 'input-error'
                      : ''
                  }
                  onChange={handleNewPassword}
                />
                {errors.confirm_new_password?.type === 'required' && (
                  <small className='text-danger'>
                    {t('registration.password_required')}
                  </small>
                )}
                {errors.confirm_new_password?.type === 'minLength' && (
                  <small className='text-danger'>
                    {t('registration.password_has_atleast_8_chars')}
                  </small>
                )}
                {errors.confirm_new_password?.type === 'matchPattern' && (
                  <small className='text-danger'>
                    {t('registration.must_be_valid_password')}
                  </small>
                )}
              </Stack>
            </Stack>
            {showErrorMessage && (
              <Stack className='w-full bg-red-500 bg-opacity-30 my-4 p-1'>
                <small className='text-danger mx-auto flex gap-3'>
                  {errorMessage} <Info fontSize='small' />
                </small>
              </Stack>
            )}
            <br />
            <button
              className='btn btn-primary gap-3 flex items-center'
              disabled={showCircleProgress ? isTrue : !isTrue}
            >
              {showCircleProgress && <CircularLoadingProgress />}
              {t('settings.update_password')}
            </button>
          </form>
        </Stack>
      </Stack>
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={3000}
        onClose={handleCloseSuccessMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className='w-full'
      >
        <SnackbarAlert>Password Changed Successfully</SnackbarAlert>
      </Snackbar>
    </Stack>
  )
}
