import React, { type ReactElement, useState } from 'react'
import { Box, FormControl, Menu, MenuItem, Select, type SelectChangeEvent, Stack } from '@mui/material'
import { Add, CheckCircle, ExpandMore, InfoOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import IconVisaPayment from '../../../../images/dashboard_images/icon-payment-visa.png'
import IconMasterCardPayment from '../../../../images/dashboard_images/icon-payment-mastercard.png'
import IconPaypalPayment from '../../../../images/dashboard_images/icon-paypal.png'
import IconMtnMoneyPayment from '../../../../images/dashboard_images/img-payment-mtnmomo.png'
import IconOrangeMoneyPayment from '../../../../images/dashboard_images/img-payment-orangeMoney.png'
import CreditCardIcon from '../../../../images/icon_images/icon-credit-card.svg'
import PaypalIcon from '../../../../images/icon_images/icon-paypal-2.png'
import MTNMoney from '../../../../images/dashboard_images/payment-mtn-logo-4.png'
import { TextInputField } from '../../common/textInputField'
import { AddMobileMoneyDetails } from '../fragments/addMobileMoneyDetails'
import { useAppDispatch, useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { setPaymentMethods, createPaymentMethodsSelector } from '../slices/paymentMethodsSlice'

export const PaymentMethods: React.FC = () => {
    const paymentMethods = useAppSelector(createPaymentMethodsSelector)
    const [mainPaymentMethodsState, setMainPaymentMethodsState] = useState(paymentMethods.paymentCredentials)
    const creditCards = paymentMethods.paymentCredentials.filter((paymentMethod: any) => paymentMethod.paymentType === 'debit-card')
    const paypal = paymentMethods.paymentCredentials.filter((paymentMethod: any) => paymentMethod.paymentType === 'paypal')
    const mobileMoneyNumbers = paymentMethods.paymentCredentials.filter((paymentMethod: any) => paymentMethod.paymentType === 'mobile-money')
    const dispatch = useAppDispatch()

    const [addNewButtonDrop, setAddNewButtonDrop] = useState<null | HTMLElement>(null)
    const [showCreditCardForm, setShowCreditCardForm] = useState(false)
    const [countryDropDown, setCountryDropDown] = useState('')
    const [showMoMoModal, setShowMoMoModal] = useState(false)
    const [newPaymentMethods] = useState<any[]>([])
    const [momoType, setMomoType] = useState('MTN')
    const openAddNew = Boolean(addNewButtonDrop)
    const { t } = useTranslation(['dashboard'])

    const showAddNewDropDown = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAddNewButtonDrop(event.currentTarget)
    }
    const closeAddNewDropDown = (): void => {
        setAddNewButtonDrop(null)
    }
    const handleShowCreditCard = (): void => {
        setShowCreditCardForm(true)
        setAddNewButtonDrop(null)
    }
    const handleCountryDropDown = (event: SelectChangeEvent): void => {
        setCountryDropDown(event.target.value)
    }
    const handleOpenMoMoModal = (mType: string): void => {
        setMomoType(mType)
        setShowMoMoModal(true)
        setAddNewButtonDrop(null)
    }
    const handleCloseMoMoModal = (): void => {
        setShowMoMoModal(false)
    }

    const handleDefaultPayment = (id: string): void => {
        const newArrayPaymentMethods = [...newPaymentMethods]
        const defaultPaymentMethod = paymentMethods.paymentCredentials.filter((filterId) => filterId.id === id)

        paymentMethods.paymentCredentials.forEach((payCred) => {
            if (payCred.id === defaultPaymentMethod[0].id) {
                payCred = { ...payCred, isDefault: true }
            } else {
                payCred = { ...payCred, isDefault: false }
            }
            newArrayPaymentMethods.push(payCred)
        })
        dispatch(setPaymentMethods({ ...paymentMethods, paymentCredentials: newArrayPaymentMethods }))
    }

    const paymentImage = (title: string): string => {
        if (title === 'Visa Card') {
            return IconVisaPayment
        } else if (title === 'Master Card') {
            return IconMasterCardPayment
        } else if (title === 'Paypal') {
            return IconPaypalPayment
        } else if (title === 'Orange Money') {
            return IconOrangeMoneyPayment
        } else {
            return IconMtnMoneyPayment
        }
    }
    const removePayment = (id: string): void => {
        setMainPaymentMethodsState((paymentMethod: any) => paymentMethod.filter((val: any) => val.id !== id))
        dispatch(setPaymentMethods({
            ...paymentMethods,
            paymentCredentials: mainPaymentMethodsState.filter((val: any) => val.id !== id)
        }))
    }
    const paymentMethodTemplate = (id: string, title: string, paymentString: string, isDefault: boolean, date?: string): ReactElement => {
        return (
            <Stack key={id} className={`w-full rounded-md px-5 py-6 bg-white ${isDefault ? 'border border-primary' : ''}`} style={{ boxShadow: '1px 1px 15px var(--secondary-light)' }}>
                <Stack direction='row' className='w-full gap-3 items-center'>
                    <img src={paymentImage(title)} alt='' className='' />
                    <Stack className='gap-3 w-full'>
                        <p className='font-extra'>{title}</p>
                        <Stack direction='row' className='w-full items-center justify-start gap-4'>
                            <p className=''>{paymentString} </p>
                            <p className='ml-auto'>{date}</p>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction='row' className='w-full mt-5'>
                    <button className='!normal-case text-secondary-light border-0 bg-none h-fit w-fit font-semibold' onClick={() => { removePayment(id) }}>{t('general.common.delete')}</button>
                    {isDefault
                        ? <p className='ml-auto font-semibold text-primary flex items-center'> <CheckCircle className='text-primary-light mr-2 !text-sm' /> {t('settings.default_payment')}</p>
                        : <p onClick={() => { handleDefaultPayment(id) }} className='ml-auto font-semibold text-black-2 flex items-center cursor-pointer'> <CheckCircle className='text-primary-light mr-2 !text-sm' />{t('settings.set_as_default')}</p>
                    }
                </Stack>
            </Stack>
        )
    }
    return (
        <Stack className='w-full px-4 md:px-12 my-5'>
            {!showCreditCardForm
                ? <>
                    <Stack direction='row' className='w-full items-center gap-4 flex-wrap'>
                        <Stack className='text-black-2'>
                            <h6 className='uppercase leading-5'>{t('settings.manage_payment_methods')}</h6>
                            <p>{t('settings.view_and_manage_all_payments')}</p>
                        </Stack>
                        <Stack className='ml-auto h-fit w-fit'>
                            <button
                                id='add-new-button'
                                className='border-0 rounded-none p-0 m-0'
                                onClick={showAddNewDropDown}
                                aria-controls={openAddNew ? 'add-new-drop' : undefined}
                                aria-haspopup='true'
                                aria-expanded={openAddNew ? 'true' : undefined}>
                                <span className='btn btn-primary !normal-case'>
                                    <Add className='pr-1' />
                                    {t('settings.add_payment_method')}
                                    <ExpandMore className='ml-3' />
                                </span>
                            </button>
                            <Menu
                                id='add-new-drop'
                                anchorEl={addNewButtonDrop}
                                open={openAddNew}
                                MenuListProps={{
                                    'aria-labelledby': 'add-new-button'
                                }}
                                onClose={closeAddNewDropDown}
                                className='!w-full'
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}

                            >
                                <MenuItem className='small flex items-center gap-4 w-full' onClick={() => { handleShowCreditCard() }}><img src={CreditCardIcon} alt='' /> Credit Card</MenuItem>
                                <MenuItem className='small flex items-center gap-4 w-full'><img src={PaypalIcon} alt='' /> Paypal</MenuItem>
                                <MenuItem className='small flex items-center gap-4 w-full' onClick={() => { handleOpenMoMoModal('MTN') }}><img src={MTNMoney} alt='' className='h-4 w-4' /> MTN Mobile Money</MenuItem>
                                <MenuItem className='small flex items-center gap-4 w-full' onClick={() => { handleOpenMoMoModal('Orange') }}><img src={IconOrangeMoneyPayment} alt='' className='h-4 w-4' /> Orange Money</MenuItem>
                            </Menu>
                        </Stack>
                    </Stack>

                    <Stack direction='row' display='grid' className='w-full grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 py-5'>

                        <Stack className='w-full gap-4 mb-5'>
                            <h6 className='mb-2'>{t('settings.credit_card')}</h6>
                            {
                                creditCards.map((detail: any) => {
                                    return paymentMethodTemplate(detail.id, detail.title, detail.payment_string, detail.isDefault, detail.date)
                                })
                            }
                        </Stack>
                        <Stack className='w-full gap-4 mb-5'>
                            <h6 className='mb-2' >{t('settings.credit_card')}</h6>
                            {
                                paypal.map((detail: any) => {
                                    return paymentMethodTemplate(detail.id, detail.title, detail.payment_string, detail.isDefault)
                                })
                            }
                        </Stack>
                        <Stack className='w-full gap-4 mb-5'>
                            <h6 className='mb-2'>Mobile Money</h6>
                            {
                                mobileMoneyNumbers.map((detail: any) => {
                                    return paymentMethodTemplate(detail.id, detail.title, detail.payment_string, detail.isDefault)
                                })
                            }
                            <AddMobileMoneyDetails momoType={momoType} open={showMoMoModal} handleClose={handleCloseMoMoModal} />
                        </Stack>
                    </Stack>
                </>
                : <>
                    <Stack className='w-full my-5'>
                        <h6 className='uppercase'>{t('settings.add_credit_card')}</h6>
                        <Stack direction='row' display='grid' className='w-full grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
                            <Stack className='w-full border border-primary-2xlight rounded bg-white'>
                                <Stack className='bg-primary-2xlight px-4 md:px-7 py-4'>
                                    <h6 className='text-black-medium'>{t('settings.card_details')}</h6>
                                </Stack>
                                <Stack className='w-full px-4 md:px-7 py-5 gap-4'>
                                    <Stack className='w-full gap-1'>
                                        <p className='font-semibold'>{t('settings.name_on_card')}*</p>
                                        <TextInputField name='name-on-card' type='text' />
                                    </Stack>
                                    <Stack className='w-full gap-1'>
                                        <p className='font-semibold'>{t('settings.card_number')}*</p>
                                        <TextInputField name='card-number' type='text' placeholder='4125 2568 5263 3685' />
                                    </Stack>
                                    <Stack direction='row' display='grid' className='w-full items-center grid-cols-1 md:grid-cols-2 gap-4 md:gap-0'>
                                        <Stack className='w-full gap-1'>
                                            <p className='font-semibold'>{t('settings.expiration_date')}*</p>
                                            <TextInputField name='expiration-date' type='date' addClass='md:!border-r-0 md:!rounded-r-none' />
                                        </Stack>
                                        <Stack className='w-full gap-1'>
                                            <Stack direction='row' className='w-full'>
                                                <p className='font-semibold'>CVC</p>
                                                <InfoOutlined fontSize='small' className='text-primary-medium ml-auto' />
                                            </Stack>
                                            <TextInputField name='cvc' type='text' placeholder='---' />
                                        </Stack>
                                    </Stack>

                                    <Stack direction='row' className='gap-3 items-center'>
                                        <input type='checkbox' name='use-as-default' />
                                        <label htmlFor='use-as-default'>{t('settings.use_as_default_payment')}</label>
                                    </Stack>
                                    <br />
                                    <br />
                                </Stack>
                            </Stack>

                            <Stack className='w-full border border-primary-2xlight rounded bg-white'>
                                <Stack className='bg-primary-2xlight px-4 md:px-7 py-4'>
                                    <h6 className='text-black-medium'>{t('settings.billing_address')}</h6>
                                </Stack>
                                <Stack className='w-full px-4 md:px-7 py-5 gap-4'>
                                    <Stack direction='row' display='grid' className='w-full items-center grid-cols-1 md:grid-cols-2 gap-4'>
                                        <Stack className='w-full gap-1'>
                                            <p className='font-semibold'>{t('general.common.first_name')}*</p>
                                            <TextInputField name='first-name' type='text' />
                                        </Stack>
                                        <Stack className='w-full gap-1'>
                                            <p className='font-semibold'>{t('general.common.last_name')}*</p>
                                            <TextInputField name='last-name' type='text' />
                                        </Stack>
                                    </Stack>
                                    <Stack className='w-full gap-1'>
                                        <p className='font-semibold'>{t('general.common.address')}*</p>
                                        <TextInputField name='address' type='text' placeholder={t('settings.address_line')} />
                                    </Stack>
                                    <Stack direction='row' display='grid' className='w-full items-center grid-cols-1 md:grid-cols-2 gap-4'>
                                        <Stack className='w-full gap-1'>
                                            <p className='font-semibold'>{t('general.common.city')}*</p>
                                            <TextInputField name='city' type='text' />
                                        </Stack>
                                        <Stack className='w-full gap-1'>
                                            <p className='font-semibold'>{t('general.common.state')} / {t('general.common.province')}*</p>
                                            <TextInputField name='state' type='text' />
                                        </Stack>
                                    </Stack>
                                    <Stack direction='row' display='grid' className='w-full items-center grid-cols-1 md:grid-cols-2 gap-4'>
                                        <Stack className='w-full gap-1'>
                                            <p className='font-semibold'>{t('settings.zip_code')}</p>
                                            <TextInputField name='zip-code' type='number' />
                                        </Stack>
                                        <Stack className='w-full gap-1'>
                                            <p className='font-semibold'>{t('general.common.country')}</p>
                                            <Stack direction='row' className='w-full'>
                                                <Box sx={{ width: '100%' }} >
                                                    <FormControl fullWidth>
                                                        {/* <InputLabel id='time-select-label' className='font-semibold text-th-black ' style={{ top: '-7px' }}>Time</InputLabel> */}
                                                        <Select
                                                            // labelId='time-select-label'
                                                            id='country-select'
                                                            value={countryDropDown}
                                                            onChange={handleCountryDropDown}
                                                            style={{ borderRadius: '6px', height: '45px' }}
                                                            className='bg-white '
                                                        >
                                                            <MenuItem value='' selected className=''>{t('settings.select_country')}</MenuItem>
                                                            <MenuItem value='cameroon' className=''>Cameroon</MenuItem>
                                                            <MenuItem value='niger' className=''>Niger</MenuItem>
                                                            <MenuItem value='ghana' className=''>Ghana</MenuItem>
                                                            <MenuItem value='south-africa' className=''>South Africa</MenuItem>
                                                            <MenuItem value='peru' className=''>Peru</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </Stack>

                                    <br />
                                    <br />
                                </Stack>
                            </Stack>
                        </Stack>
                        <br />
                        <Stack className='ml-0 mt-3'>
                            <button onClick={() => { setShowCreditCardForm(false) }} className='btn btn-primary'>{t('general.common.save_changes')}</button>
                        </Stack>
                    </Stack>
                </>
            }
        </Stack>
    )
}
