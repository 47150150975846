import React, { useState } from 'react'
import { Stack } from '@mui/material'
import VerifyEmailIcon from '../../images/icon_images/icon-verifyEmail-snd.svg'
import { ChevronRightOutlined } from '@mui/icons-material'
import { CircularLoadingProgress } from './fragments/circularProgress'
import { Auth } from 'aws-amplify'
import { SignUpSuccess } from './signUpSuccess'
import { useTranslation } from 'react-i18next'
import { RegistrationBox } from './fragments/registrationBox'
import { toast } from 'react-toastify'
import PinInput from 'react-pin-input'

export const ConfirmRegistration: React.FC = () => {
  const [confirmCode, setConfirmCode] = useState('')
  const [showConfirmCircleProgress, setShowConfirmCircleProgress] =
    useState(false)
  const [showWrongConfirmCode, setShowWrongConfirmCode] = useState(false)
  const [showResendingCode, setShowResendingCode] = useState(false)
  const [showSignUpSuccess, setShowSignUpSuccess] = useState(false)
  const { t } = useTranslation(['dashboard'])

  const handleConfirmCode = (e: any): void => {
    setConfirmCode(e)
    setShowWrongConfirmCode(false)
  }

  const handleResendCode = async (): Promise<any> => {
    setShowResendingCode(true)
    try {
      await Auth.resendSignUp(
        `${localStorage.getItem('njangee_username') as string}`
      )
      console.log('code resent successfully')
      toast.success(`${t('registration.code_sent_successfully') as string} !}`)
    } catch (err) {
      console.error('error resending code: ', err)
      toast.error(t('registration.error_sending_code'))
    } finally {
      setShowResendingCode(false)
    }
  }

  const handleConfirmSignUp = (): void => {
    setShowConfirmCircleProgress(true)
    Auth.confirmSignUp(
      `${localStorage.getItem('njangee_username') as string}`,
      `${confirmCode}`
    )
      .then(() => {
        localStorage.removeItem('njangee_confirm')
        localStorage.removeItem('njangee_username')
        localStorage.removeItem('njangee_first_name')
        localStorage.removeItem('njangee_signUpType')
        setShowSignUpSuccess(true)
        setShowConfirmCircleProgress(false)
      })
      .catch((error) => {
        setShowConfirmCircleProgress(false)
        console.log(error)
        if (
          error.code === 'CodeMismatchException' ||
          error.code === 'ExpiredCodeException' ||
          confirmCode === ''
        ) {
          setShowWrongConfirmCode(true)
        } else {
          console.log('Unknown')
        }
      })
  }

  return (
    <RegistrationBox>
      {showSignUpSuccess ? (
        <SignUpSuccess />
      ) : (
        <Stack className='my-auto px-6 sm:px-8 py-8'>
          <img src={VerifyEmailIcon} alt='' />
          <br />
          <Stack>
            <h4>
              {t('registration.verify')}{' '}
              {localStorage.getItem('njangee_signUpType') === 'email'
                ? t('registration.email_address')
                : t('registration.phone_number')}
            </h4>
            {localStorage.getItem('njangee_confirm') ===
            'confirm_from_login' ? (
              <>
                <p>
                  {t('registration.verify_caption')}{' '}
                  {localStorage.getItem('njangee_signUpType') === 'email'
                    ? t('registration.email_address')
                    : t('registration.phone_number')}
                  :
                  <span className='text-secondary font-semibold'>
                    {localStorage.getItem('njangee_username')}
                  </span>
                </p>
              </>
            ) : (
              <>
                <p className='mb-4'>
                  {t('registration.hi')}{' '}
                  {localStorage.getItem('njangee_first_name')},
                </p>
                <p>
                  {t('registration.thanks_for_signup')}{' '}
                  {localStorage.getItem('njangee_signUpType') === 'email'
                    ? 'email:'
                    : 'phone number:'}{' '}
                  <span className='text-secondary font-semibold'>
                    {localStorage.getItem('njangee_username')}
                  </span>
                </p>
              </>
            )}
            <button
              className='h-auto bg-none border-0 p-0 text-button-primary mr-auto mt-1 text-14 flex gap-2'
              disabled={showResendingCode}
              onClick={handleResendCode}
            >
              {showResendingCode && <CircularLoadingProgress />} Resend Code
            </button>
          </Stack>
          <br />
          <Stack className='gap-2'>
            {/* <TextInputField
              type='text'
              name='last_name'
              required={true}
              onChange={(e: any) => {
                handleConfirmCode(e)
              }}
              addClass={`${showWrongConfirmCode ? 'input-error' : ''}`}
            /> */}
            <PinInput
              length={6}
              initialValue=''
              secret={false}
              // secretDelay={100}
              onChange={(value, index) => {
                handleConfirmCode(value)
              }}
              type='numeric'
              inputMode='number'
              style={{ padding: '10px' }}
              inputStyle={{
                borderColor: 'var(--secondary)',
                height: '50px',
                width: '45px'
              }}
              inputFocusStyle={{ borderColor: 'var(--primary)' }}
              onComplete={(value, index) => {}}
              autoSelect={true}
              // {...register('code', formValidation.password)}
            />
            {showWrongConfirmCode && (
              <small className='text-danger'>
                {t('registration.invalid_confirm_code')}.
              </small>
            )}
          </Stack>
          <br />
          <Stack className='w-full'>
            <button
              className='btn btn-primary flex gap-3'
              onClick={handleConfirmSignUp}
            >
              {showConfirmCircleProgress && <CircularLoadingProgress />}
              {t('registration.verify_email')}
              <ChevronRightOutlined className='text-white' />
            </button>
          </Stack>
        </Stack>
      )}
    </RegistrationBox>
  )
}
