import React, { type ReactNode } from 'react'
import { Stack } from '@mui/material'
import '../../../styles/registration_box.css'
import LogoWhite from '../../../images/registration_images/njangee-logo-white.png'
import { Link } from 'react-router-dom'

interface IProps {
    children: ReactNode
    showNeedHelp?: boolean
}
export const RegistrationBox: React.FC<IProps> = ({ children, showNeedHelp }) => {
    return (
        <Stack className='h-full w-full registration-box overflow-y-scroll'>

            <Stack className='m-auto w-full gap-4'>
                <br />
                <img src={LogoWhite} alt='' className='mt-auto mx-auto' />
                <Stack
                    width={{
                        xs: '95%',
                        sm: '70%',
                        md: '50%',
                        lg: '32%'
                    }}
                    className='content-box mx-auto'>
                    {children}
                </Stack>
                {showNeedHelp === true &&
                    <Stack width={{
                        xs: '95%',
                        sm: '70%',
                        md: '50%',
                        lg: '32%'
                    }}
                        className='mx-auto'>
                        <p className='flex text-white'>Need Help? <Link to='/contact' className='ml-1 font-semibold'> Contact Us</Link></p>
                    </Stack>}
                <br />
            </Stack>
        </Stack>
    )
}
