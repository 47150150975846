import React, { useState } from 'react'
import { ClearOutlined, CloseOutlined, DoneOutlined } from '@mui/icons-material'
import { Box, Drawer, IconButton, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Image1 from '../../../images/dashboard_images/dashboard-invitations-userThumb-2@2x.png'
import Image2 from '../../../images/dashboard_images/dashboard-invitations-userThumb-1@2x.png'
import Image3 from '../../../images/dashboard_images/img-payment-mtnmomo.png'
import Image4 from '../../../images/dashboard_images/dashboard-group-profile-img-4@2x.png'
import Image5 from '../../../images/dashboard_images/dashboard-group-profile-img-3@2x.png'
import { Link } from 'react-router-dom'

interface INotificationSider {
    isDrawerOpen: boolean
    handleDrawerClose: () => void
}
export const NotificationSider: React.FC<INotificationSider> = ({ isDrawerOpen, handleDrawerClose }) => {
    const [notifications, setNotifications] = useState('all')
    const { t } = useTranslation(['dashboard'])
    const activeNotification = (notif: string): string => {
        return (notifications === notif ? 'bg-primary-2xlight border border-primary-xlight text-primary' : 'bg-light border border-main text-th-black')
    }

    return (
        <div className='mobile-left-menu'>
            <Drawer anchor='right' className='dash-drawer' open={isDrawerOpen} onClose={handleDrawerClose}>
                <Box width={{
                    xs: '100%',
                    sm: '400px',
                    lg: '560px'
                }} height='100%' className='bg-white h-full !mb-0 p-4 md:p-7' role=''>
                    <Stack direction='row' className='w-full border-b border-main gap-5'>
                        <h4>{t('general.common.notifications')}</h4>
                        <IconButton className='!p-0 !my-0 !mt-0 !ml-auto' onClick={handleDrawerClose}>
                            <CloseOutlined fontSize='medium' className='text-primary-light' />
                        </IconButton>
                    </Stack>

                    <Stack direction='row' className='w-full items-center flex-wrap xl:flex-nowrap mt-3 gap-5'>
                        <Stack direction='row' className={`items-start rounded-full py-1 px-6 cursor-pointer ${activeNotification('all')}`} onClick={() => { setNotifications('all') }}>
                            <p className={`pr-1 ${notifications === 'all' ? 'font-extra' : ''}`}>{t('general.common.all')} </p>
                            <small className={`text-9 ${notifications === 'all' ? 'font-extra text-primary-light' : ''}`}>25</small>
                        </Stack>

                        <Stack direction='row' className={`items-start rounded-full py-1 px-6 cursor-pointer ${activeNotification('requests')}`} onClick={() => { setNotifications('requests') }}>
                            <p className={`pr-1 ${notifications === 'requests' ? 'font-extra' : ''}`}>{t('general.common.requests')} </p>
                            <small className={`text-9 ${notifications === 'requests' ? 'font-extra text-primary-light' : ''}`}>2</small>
                        </Stack>

                        <Stack direction='row' className={`items-start rounded-full py-1 px-6 cursor-pointer ${activeNotification('payments')}`} onClick={() => { setNotifications('payments') }}>
                            <p className={`pr-1 ${notifications === 'payments' ? 'font-extra' : ''}`}>{t('general.common.payments')} </p>
                            <small className={`text-9 ${notifications === 'payments' ? 'font-extra text-primary-light' : ''}`}>3</small>
                        </Stack>

                        <Stack direction='row' className={`items-start rounded-full py-1 px-6 cursor-pointer ${activeNotification('reminder')}`} onClick={() => { setNotifications('reminder') }}>
                            <p className={`pr-1 ${notifications === 'reminder' ? 'font-extra' : ''}`}>{t('general.common.reminder')} </p>
                            <small className={`text-9 ${notifications === 'reminder' ? 'font-extra text-primary-light' : ''}`}>4</small>
                        </Stack>
                    </Stack>
                    <br />
                    <Stack className='w-full py-5 gap-4'>
                        <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
                            <img src={Image1} alt='' className='left-img-1' />
                            <Stack className=''>
                                <p className='leading-5'>
                                    <span className='font-extra'>Woodrow Howell </span>
                                    just contributed XAF 50,000 in
                                    <span className='font-extra'> Buea Deaf Community </span>
                                    njangee group. You are to receive XAF 750,000.
                                </p>
                                <Link to='/' className='text-button-primary'>view payment history</Link>
                                <small className='text-secondary font-semibold mt-1'>May 07, 2023</small>
                            </Stack>
                        </Stack>

                        <Stack direction='row' className='w-full items-start gap-1 flex-wrap'>
                            <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
                                <img src={Image2} alt='' className='left-img-1' />
                                <Stack className=''>
                                    <p className='leading-5'>
                                        <span className='font-extra'>Uriah Nader </span>
                                        invited you to join
                                        <span className='font-extra'> Family Reunion  </span>
                                        njangee group.
                                    </p>
                                    <small className='text-secondary font-semibold mt-1'>2 hours ago</small>
                                </Stack>
                            </Stack>
                            <Stack direction='row' className='items-center gap-3 w-fit ml-auto mb-3'>
                                <button className='btn-accept-invitation btn-primary'><DoneOutlined className='mr-1 text-overlined !w-4 !h-4' /> Accept</button>
                                <button className='btn-reject-invitation'><ClearOutlined className='mr-1 text-overlined !w-4 !h-4' /> Reject</button>
                            </Stack>
                        </Stack>

                        <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
                            <img src={Image3} alt='' className='left-img-1' />
                            <Stack className=''>
                                <p className='leading-5'>
                                    {/* <span className='font-extra'>Woodrow Howell </span> */}
                                    New announcement on
                                    <span className='font-extra'> Buea Deaf Community </span>
                                    njangee group.
                                </p>
                                <small className='text-secondary font-semibold mt-1'>15 hours ago</small>
                            </Stack>
                        </Stack>

                        <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
                            <img src={Image4} alt='' className='left-img-1' />
                            <Stack className=''>
                                <p className='leading-5'>
                                    <span className='font-extra'>Ophelia Miller </span>
                                    Replied to your
                                    <span className='font-extra'> comment </span>
                                    on Buea Deaf Community Njangee group
                                </p>
                                <small className='text-secondary font-semibold mt-1'>15 hours ago</small>
                            </Stack>
                        </Stack>

                        <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
                            <img src={Image5} alt='' className='left-img-1' />
                            <Stack className=''>
                                <p className='leading-5'>
                                    <span className='font-extra'>Bernadine O&apos;Keefe </span>
                                    rejected your request to join the
                                    <span className='font-extra'> Soppo Union Women&apos;s Njangi</span>
                                </p>
                                <small className='text-secondary font-semibold mt-1'>May 30, 2023</small>
                            </Stack>
                        </Stack>

                        <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
                            <img src={Image1} alt='' className='left-img-1' />
                            <Stack className=''>
                                <p className='leading-5'>
                                    <span className='font-extra'>Jade Kuphal  </span>
                                    accepted your request to join the
                                    <span className='font-extra'> Soppo Union Women&apos;s Njangi</span>
                                </p>
                                <small className='text-secondary font-semibold mt-1'>May 29, 2023</small>
                            </Stack>
                        </Stack>

                        <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
                            <img src={Image1} alt='' className='left-img-1' />
                            <Stack className=''>
                                <p className='leading-5'>
                                    <span className='font-extra'>Woodrow Howell </span>
                                    just contributed XAF 50,000 in
                                    <span className='font-extra'> Buea Deaf Community </span>
                                    njangee group. You are to receive XAF 750,000.
                                </p>
                                <small className='text-secondary font-semibold mt-1'>May 07, 2023</small>
                            </Stack>
                        </Stack>

                        <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
                            <img src={Image1} alt='' className='left-img-1' />
                            <Stack className=''>
                                <p className='leading-5'>
                                    <span className='font-extra'>Woodrow Howell </span>
                                    just contributed XAF 50,000 in
                                    <span className='font-extra'> Buea Deaf Community </span>
                                    njangee group. You are to receive XAF 750,000.
                                </p>
                                <small className='text-secondary font-semibold mt-1'>May 07, 2023</small>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Drawer>
        </div>
    )
}
