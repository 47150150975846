import React, { useState } from 'react'
import { IconButton, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { GroupsModalSkeleton } from '../../groups/fragments/groupsModalSkeleton'
import { CloseOutlined } from '@mui/icons-material'
import MtnMobileMoneyImg from '../../../../images/dashboard_images/payment-mtn-logo.png'
import OrangeMobileMoneyImg from '../../../../images/dashboard_images/img-payment-orangeMoney.png'
import { TextInputField } from '../../common/textInputField'
import CameroonFlag from '../../../../images/dashboard_images/icon-flag-camer.png'
import { useAppDispatch, useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { setPaymentMethods, createPaymentMethodsSelector } from '../slices/paymentMethodsSlice'
interface IProps {
    handleClose: () => void
    open: boolean
    momoType: string
}

export const AddMobileMoneyDetails: React.FC<IProps> = ({ handleClose, open, momoType }) => {
    const paymentMethods = useAppSelector(createPaymentMethodsSelector)
    const dispatch = useAppDispatch()
    const [singlePaymentMethod, setSinglePaymentMethod] = useState({
        id: '',
        paymentType: 'mobile-money',
        title: '',
        payment_string: '',
        isDefault: false
    })
    const newPaymentMethods: any[] = []
    const { t } = useTranslation(['dashboard'])
    const onChangeMobileNumber = (e: any): void => {
        setSinglePaymentMethod(
            {
                ...singlePaymentMethod,
                payment_string: e.target.value,
                id: Date.now().toString(),
                title: momoType === 'MTN' ? 'Mtn Mobile Money' : 'Orange Money'
            })
    }
    const handleUseAsDefaultPayment = (): void => {
        setSinglePaymentMethod(
            {
                ...singlePaymentMethod,
                isDefault: !singlePaymentMethod.isDefault
            })
    }
    const addNewPaymentMethod = (): void => {
        const newArrayPaymentMethods = [...newPaymentMethods]
        if (singlePaymentMethod.isDefault) {
            paymentMethods.paymentCredentials.forEach((payCred: any) => {
                payCred = { ...payCred, isDefault: false }
                newArrayPaymentMethods.push(payCred)
            })
            newArrayPaymentMethods.push(singlePaymentMethod)
            dispatch(setPaymentMethods({ ...paymentMethods, paymentCredentials: newArrayPaymentMethods }))
        } else {
            dispatch(setPaymentMethods({ ...paymentMethods, paymentCredentials: [...paymentMethods.paymentCredentials, singlePaymentMethod] }))
        }
        setSinglePaymentMethod({
            ...singlePaymentMethod,
            isDefault: false
        })
        handleClose()
    }
    return (
        <GroupsModalSkeleton open={open} handleClose={handleClose} xsModalWidth='95%' smModalWidth='65%' mdModalWidth='55%' lgModalWidth='40%'>
            <Stack direction='row' className='flex items-center w-full justify-start gap-3 bg-primary text-th-white py-3 px-4 sm:px-7 rounded-t border'>
                <h6>{t('settings.add_payment_method')}</h6>
                <IconButton onClick={handleClose} className='!ml-auto'>
                    <CloseOutlined className='text-primary-light' />
                </IconButton>
            </Stack>

            <Stack className='w-full px-4 sm:px-7 my-6 gap-4'>
                <Stack direction='row' className='w-full items-center rounded-md bg-light p-4 gap-3'>
                    <img src={momoType === 'MTN' ? MtnMobileMoneyImg : OrangeMobileMoneyImg} alt='' className='h-8 w-8' />
                    <p className='font-semibold'>{momoType === 'MTN' ? 'MTN Mobile Money' : 'Orange Money'}</p>
                </Stack>

                <Stack className='w-full gap-1'>
                    <p className='font-semibold'>{momoType === 'MTN' ? 'MTN' : 'Orange'} {t('settings.mobile_money_number')}</p>
                    <Stack direction='row' className='w-full'>
                        <Stack className='border border-secondary-xlight border-r-0 w-fit p-2 rounded rounded-r-none flex items-center justify-center'>
                            <img src={CameroonFlag} alt='' className='h-7 w-12' />
                        </Stack>
                        <Stack className='w-full'>
                            <TextInputField type='text' name='phone-number' placeholder='+237 6789512057' onChange={(e: any) => { onChangeMobileNumber(e) }} addClass='border-l-0 !rounded-l-none' />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction='row' className='gap-3 items-center'>
                    <input type='checkbox' name='use-as-default' onChange={handleUseAsDefaultPayment} />
                    <label htmlFor='use-as-default'>{t('settings.use_as_default_payment')}</label>
                </Stack>
            </Stack>

            <Stack className='mt-auto px-4 sm:px-7'>
                <Stack direction='row' className='w-fit ml-auto items-center gap-5 sm:gap-10'>
                    <button onClick={handleClose} className='border-0 rounded-none w-fit h-fit text-black-2 font-semibold'>{t('general.common.cancel')}</button>
                    <button className='btn btn-primary' onClick={addNewPaymentMethod}>{t('general.common.save_changes')}</button>
                </Stack>
            </Stack>
            <br />
        </GroupsModalSkeleton>
    )
}
