import React, { type ReactNode } from 'react'
import { DashBoardFooter } from './footer'
import { Header } from './header'
import '@aws-amplify/ui-react/styles.css'

interface IDashboardLayout {
    children: ReactNode
}
export const UserDashBoardLayout: React.FC<IDashboardLayout> = ({ children }) => {
    return (
        <div className='user-dashboard-layout'>
            <Header/>
            <div style={{ minHeight: '500px' }}>
                {children}
            </div>
            <DashBoardFooter />
        </div>
    )
}
