import React, { useEffect, useState } from 'react'
import { AppBar, Toolbar, IconButton, Stack } from '@mui/material'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import {
  AccountCircle,
  ArrowRightAltOutlined,
  SearchOutlined
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import ArrowRight from '../../../images/icon_images/icon-arrow-right.svg'
// import { LanguageDropdown } from '../../user/common/fragments/language_dropDown'
import Logo from '../../../images/web_images/logo/njamgee-Logo.png'
import { MobileWebHeader } from './mobileNav'
import { useAppSelector } from '../../../redux/app/create_njangee_store/hooks'
import { Auth } from 'aws-amplify'
import { createUserAuthSelector } from '../../amplify/slices/authSlices'

export const WebHeader: React.FC = () => {
  const { t } = useTranslation(['web'])
  const loggedUser = useAppSelector(createUserAuthSelector).user
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const navigate = useNavigate()

  const handleChooseRegistrationOption = (): void => {
    navigate('/login')
  }
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setIsUserLoggedIn(true)
      })
      .catch(() => {
        setIsUserLoggedIn(false)
      })
  }, [])
  return (
    <div className='web-header'>
      <AppBar
        position='static'
        className='app-bar !shadow-none desktop-nav border-b border-primary-3xlight !hidden md:!flex items-center'
      >
        <Toolbar className='h-full flex flex-wrap md:gap-10 lg:gap-16 items-center'>
          <Stack
            direction='row'
            className='h-full flex md:gap-10 lg:gap-16 items-center'
          >
            <Link to='/'>
              <img alt='' src={Logo} className='' />
            </Link>
            <Stack
              direction='row'
              className='flex items-center nav-menu md:gap-6 lg:gap-8'
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'flex items-center activeNav' : 'flex items-center'
                }
                to='/'
              >
                {t('common.home')}
              </NavLink>

              <NavLink
                className={({ isActive }) =>
                  isActive ? 'flex items-center activeNav' : 'flex items-center'
                }
                to='/features'
              >
                {t('common.features')}
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'flex items-center activeNav' : 'flex items-center'
                }
                to='/about'
              >
                {t('common.about')}
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'flex items-center activeNav' : 'flex items-center'
                }
                to='/how-it-works'
              >
                {t('common.how_it_works')}
              </NavLink>

              <NavLink
                className={({ isActive }) =>
                  isActive ? 'flex items-center activeNav' : 'flex items-center'
                }
                to='/contact'
              >
                {t('common.contact')}
              </NavLink>
            </Stack>
          </Stack>

          <Stack
            direction='row'
            className='h-full gap-3 nav-menu-2 lg:ml-16 items-center !ml-auto'
          >
            {/* <LanguageDropdown textColor='!text-black' /> */}
            <IconButton className='!p-0'>
              <SearchOutlined className='text-primary-light' />
            </IconButton>
            {isUserLoggedIn ? (
              <>
                <Link
                  to='/user'
                  className='flex items-center gap-2 bg-light py-1 px-2'
                >
                  {loggedUser?.photo !== '' ? (
                    <img
                      src={loggedUser?.photo}
                      className='h-6 w-6 rounded-full'
                      alt=''
                    />
                  ) : (
                    <AccountCircle fontSize='small' className='text-primary' />
                  )}
                  <p className='text-black'>{loggedUser?.firstName ?? '-'}</p>
                  <ArrowRightAltOutlined
                    fontSize='small'
                    className='text-primary'
                  />
                </Link>
              </>
            ) : (
              <>
                <Link
                  to='/register'
                  className='flex items-center text-button-primary !font-extrabold'
                >
                  {t('common.sign_up')}{' '}
                  <img alt='' src={ArrowRight} className='pl-2' />
                </Link>
                <p
                  onClick={handleChooseRegistrationOption}
                  className='font-semibold !text-slate-500 cursor-pointer a-text'
                >
                  {t('common.login')}
                </p>
              </>
            )}
          </Stack>
        </Toolbar>
      </AppBar>

      {/* mobile nav  */}
      <MobileWebHeader />
    </div>
  )
}
