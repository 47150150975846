import React, { type ReactNode, useEffect } from 'react'
import { WebHeader } from './header'
import '../../../styles/web.css'
import { Footer } from './footer'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

interface IWebLayout {
    children: ReactNode
}
export const WebLayout: React.FC<IWebLayout> = ({ children }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='home web'>
            <WebHeader />
            {children}
            <TawkMessengerReact
                propertyId="65ef3ee28d261e1b5f6bd96d"
                widgetId="1hon8rap7"
            />
            <Footer />
        </div>
    )
}
