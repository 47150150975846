import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AppBar, Toolbar, IconButton, Stack } from '@mui/material'
import LogoSmall from '../../../../images/logo/njangee-logo-white.png'
import PlayIcon from '../../../../images/icon_images/icon-play.svg'
import { LanguageDropdown } from './language_dropDown'
import { MobileLeftMenu } from './mobileLeftMenu'
import { NotificationAndSettings } from './notifAndSettings'

interface IMobileHeader {
    signOut: any
}
export const MobileHeader: React.FC<IMobileHeader> = ({ signOut }) => {
    const { t } = useTranslation(['dashboard'])
    return (
        <AppBar position='static' className='bg-primary app-bar-mobile sm:!flex lg:!hidden'>
            <Toolbar className='h-full flex flex-wrap md:gap-8 !w-full'>
                <MobileLeftMenu />
                <IconButton size='large' className='text-center' edge='start' color='inherit' aria-label='logo'>
                    <img alt='' src={LogoSmall} height='30px' width='100px' className='' />
                </IconButton>

                <Stack direction='row' className='ml-auto'>

                    <NavLink className={({ isActive }) =>
                        isActive ? 'items-center !hidden md:!flex nav-menu2-child activeNav2' : 'items-center !hidden md:!flex nav-menu2-child'
                    } to='/'><img alt='' src={PlayIcon} className='pr-3' /> {t('general.navigation.play')}</NavLink>
                    <LanguageDropdown textColor='text-light h-3' />
                    <NotificationAndSettings signOut={signOut} />
                </Stack>
            </Toolbar>
        </AppBar>
    )
}
