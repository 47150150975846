import React from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
// import SetReminderIcon from '../../../../images/icon_images/icon-edit-reminder.svg'
import { useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { groupDetailSelector } from '../slices/groupDetail'
import { convertToMonthInWords } from '../../../common/helper/convertToDate'
import { createUserAuthSelector } from '../../../amplify/slices/authSlices'

export const GroupShowOverview: React.FC = () => {
  const { t } = useTranslation(['dashboard'])
  const groupDetail = useAppSelector(groupDetailSelector).group
  const userData = useAppSelector(createUserAuthSelector).user
  const language = localStorage.getItem('i18nextLng')
  return (
    <Stack
      direction='row'
      display='grid'
      className='grid-cols-1 md:grid-cols-2 gap-4 items-start w-full mt-3'
    >
      <Stack className='w-full border border-primary-2xlight rounded-md group-overview-boxes'>
        <Stack direction='row' className='gap-5 md:gap-10 rounded-t-md'>
          <p className='label'>{t('groups.session_duration')}</p>
          <Stack direction='column' className='gap-4 '>
            {/* <p className='font-semibold flex items-center gap-4'>Start Date: <span>January 1st, 2023</span></p> */}
            <p className='font-semibold flex items-center gap-4'>
              {t('groups.start_date')}:{' '}
              <span className='font-normal'>
                {convertToMonthInWords(language ?? '', groupDetail.startDate)}
              </span>
            </p>
            <p className='font-semibold flex items-center gap-4'>
              {t('groups.end_date')}:{' '}
              <span className='font-normal'>March 1st, 2024</span>
            </p>
          </Stack>
        </Stack>

        <Stack direction='row' className='gap-5 md:gap-10'>
          <p className='label'>{t('groups.number_of_sessions')}</p>
          <p className=''>{groupDetail.groupMembers.length ?? '1'}</p>
        </Stack>

        <Stack direction='row' className='gap-5 md:gap-10'>
          <p className='label'>{t('groups.amount')} </p>
          <p className='font-semibold'>
            {groupDetail?.currency} {groupDetail?.amount}{' '}
            <span className='font-normal'>{t('groups.per_person')}</span>
          </p>
        </Stack>

        <Stack direction='row' className='gap-5 md:gap-10'>
          <p className='label'>{t('groups.current_round')} </p>
          <p className=''>{groupDetail.currentRound}</p>
        </Stack>

        <Stack direction='row' className='gap-5 md:gap-10'>
          <p className='label'>{t('groups.group_created_by')}</p>
          <p className=''>
            {userData.firstName} {userData.lastName}
          </p>
        </Stack>

        <Stack direction='row' className='gap-5 md:gap-10'>
          <p className='label'>{t('groups.group_created_on')}</p>
          <p className=''>
            {' '}
            {convertToMonthInWords(language ?? '', groupDetail.createdAt)}
          </p>
        </Stack>

        <Stack direction='row' className='gap-5 md:gap-10 rounded-b-md'>
          <p className='label'>{t('groups.group_desc')}</p>
          <p className='flex items-center gap-3'>{groupDetail?.description}</p>
        </Stack>
      </Stack>

      <Stack className='w-full border border-primary-2xlight rounded-md group-overview-boxes'>
        <Stack direction='row' className='gap-5 md:gap-10 rounded-t-md'>
          <p className='label'>{t('groups.contribution_frequency')}</p>
          <p className=''>{groupDetail.frequency}</p>
        </Stack>

        <Stack direction='row' className='gap-5 md:gap-10'>
          <p className='label'>{t('groups.current_recipient')}</p>
          <p className='font-semibold'>Daren Pagac</p>
        </Stack>

        <Stack direction='row' className='gap-5 md:gap-10'>
          <p className='label'>{t('groups.contribution_rounds')} </p>
          <p className=''>{groupDetail.groupMembers.length}</p>
        </Stack>

        <Stack direction='row' className='gap-5 md:gap-10'>
          <p className='label'>{t('groups.group_type')}</p>
          <p className=''>{groupDetail.type} Group</p>
        </Stack>

        <Stack
          direction='row'
          className='items-center flex-wrap sm:flex-nowrap gap-5 lg:gap-10 '
        >
          <p className='label'>{t('groups.upcoming_contribution')}</p>
          <p className='font-extra text-primary'>September 1st, 2023</p>
          {/* <small className='text-14 ml-auto flex items-center justify-start gap-2'><img alt='' src={SetReminderIcon} /> {t('general.common.set_reminder')}</small> */}
        </Stack>

        <Stack direction='row' className='gap-5 md:gap-10'>
          <p className='label'>{t('groups.next_recipient')}</p>
          <p className=''>December 18, 2022</p>
        </Stack>

        <Stack direction='row' className='gap-5 md:gap-10 rounded-b-md'>
          <p className='label'>{t('groups.next_contribution_date')}</p>
          <p className=''>September 1st, 2023</p>
        </Stack>
      </Stack>
    </Stack>
  )
}
