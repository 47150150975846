import React from 'react'
import { Stack } from '@mui/material'
import { RegistrationBox } from './fragments/registrationBox'
import {
  useAppDispatch,
  useAppSelector
} from '../../redux/app/create_njangee_store/hooks'
import {
  setChooseRegistration,
  createChooseRegistrationSelector
} from './slices/chooseRegistrationSlice'
// import PhoneIcon from '../../images/icon_images/icon-phone-square-rnd.svg'
import EnvelopIcon from '../../images/icon_images/icon-envelop-square-rnd.svg'
import ChevronWhiteIcon from '../../images/icon_images/icon-chevron-right-white.svg'
// import ChevronGrayIcon from '../../images/icon_images/icon-chevron-right-gray.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const ChooseRegistration: React.FC = () => {
  const chooseRegistrationOption = useAppSelector(
    createChooseRegistrationSelector
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['dashboard'])

  const handleChooseRegistrationOption = (option: string): void => {
    dispatch(
      setChooseRegistration({
        ...chooseRegistrationOption,
        sign_up_option: option
      })
    )
    localStorage.setItem('njangee_signUpType', option)
    navigate('/register')
  }
  return (
    <RegistrationBox>
      <Stack className='w-full h-full py-7 px-5 sm:px-10'>
        <Stack className='my-auto'>
          <p className='uppercase text-primary-medium font-semibold'>
            {t('registration.sign_up')}
          </p>
          <h5 className='leading-7'>{t('registration.how_will_you_reg')}?</h5>
          <br />
          <Stack className='gap-3 mt-2'>
            <Stack
              direction='row'
              className='w-full items-center rounded-md bg-primary-light cursor-pointer py-5 px-5 gap-4 shadow-lg'
              onClick={() => {
                handleChooseRegistrationOption('email')
              }}
            >
              <img src={EnvelopIcon} alt='' />
              <p className='text-white'>
                {t('registration.sign_up_with_email')}
              </p>
              <img src={ChevronWhiteIcon} alt='' className='ml-auto' />
            </Stack>
            {/* <Stack direction='row'
                            className='w-full items-center rounded-md bg-white cursor-pointer py-5 px-5 gap-4 shadow-lg'
                            onClick={() => { handleChooseRegistrationOption('phone_number') }}>
                            <img src={PhoneIcon} alt="" />
                            <p className="text-th-black">{t('registration.sign_up_with_phone')}</p>
                            <img src={ChevronGrayIcon} alt="" className='ml-auto' />
                        </Stack> */}
          </Stack>
        </Stack>
      </Stack>
    </RegistrationBox>
  )
}
