import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../redux/app/create_njangee_store/store'
export interface Countries {
    countries: any[]
}

const initialState: Countries = {
    countries: []
}

export const createCountriesSlice = createSlice({
    name: 'change_password',
    initialState,
    reducers: {
        setCountries: (state, action: PayloadAction<Countries>) => {
            return {
                ...state,
                countries: action.payload.countries
            }
        }
    }
})

export const { setCountries } = createCountriesSlice.actions

export const createCountriesSelector = (state: RootState): Countries => state.createCountriesReducer
export default createCountriesSlice.reducer
