import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../redux/app/create_njangee_store/store'
export interface States {
    states: any
}

const initialState: States = {
    states: undefined
}

export const createStatesSlice = createSlice({
    name: 'change_password',
    initialState,
    reducers: {
        setStates: (state, action: PayloadAction<States>) => {
            return {
                ...state,
                states: action.payload.states
            }
        }
    }
})

export const { setStates } = createStatesSlice.actions

export const createStatesSelector = (state: RootState): States => state.createStatesReducer
export default createStatesSlice.reducer
