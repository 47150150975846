import React from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CongratsIcon from '../../../images/icon_images/icon-congrats.svg'

export const Congrats: React.FC = () => {
    const { t } = useTranslation(['dashboard'])
    return (
        <>
            <Stack direction='column' className='items-center w-full !h-full'>
                <img alt='' src={CongratsIcon} className='w-fit' />
                <Stack className='mt-5 text-center h-auto'>
                    <h5>{t('main_modal.congrats')} !</h5>
                    <p className='mx-0 sm:mx-12 lg:mx-24'>{t('main_modal.congrats_caption')}</p>

                    <p className='mt-4 text-primary-bold font-semibold'>Buea Deaf Community Njangee Group</p>
                </Stack>

            </Stack>
            <Stack direction='column' className='w-full mt-auto items-center mb-4'>
                <button onClick={() => { window.location.reload() }} className='btn btn-primary'>{t('main_modal.go_to_dashboard')}</button>
            </Stack>
        </>
    )
}
