import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface ShowModal {
  showModal: boolean
  modalIndex: number
  skipCongrats: boolean
}

const initialState: ShowModal = {
  showModal: false,
  modalIndex: 0,
  skipCongrats: false
}

export const showModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModalF: (state, action: PayloadAction<ShowModal>) => {
      return {
        ...state,
        showModal: action.payload.showModal,
        modalIndex: action.payload.modalIndex,
        skipCongrats: action.payload.skipCongrats
      }
    }
  }
})

export const { showModalF } = showModalSlice.actions

export const showModalSelector = (state: RootState): ShowModal =>
  state.showModalReducer
export default showModalSlice.reducer
