import React, { useState } from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import EditCircleIcon from '../../../images/icon_images/icon-onboarding-name.svg'
import { TextInputField } from '../common/textInputField'
import { ModalNavs } from './fragments/modalNavs'
import { useAppDispatch, useAppSelector } from '../../../redux/app/create_njangee_store/hooks'
import { setName, createGroupSelector } from '../njangeeGroupModals/slices/newGroupSlices'

interface IModalValue {
    nextModal: (arg: number) => void
    skipOnboarding: () => void
}
export const GroupName: React.FC<IModalValue> = ({ nextModal, skipOnboarding }) => {
    const [showError, setShowError] = useState(false)
    const groupVals = useAppSelector(createGroupSelector)
    const dispatch = useAppDispatch()
    const { t } = useTranslation(['dashboard'])
    const changeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setShowError(false)
        dispatch(setName({ name: event.target.value }))
    }

    const handleSubmitName = (): void => {
        if (groupVals.name === '') {
            setShowError(true)
        } else {
            setShowError(false)
            nextModal(2)
        }
    }
    return (
        <>
            <Stack direction='column' className='w-full justify-center text-center px-4 sm:px-10 lg:px-5'>
                <img alt='' src={EditCircleIcon} className='w-fit h-fit mx-auto' />
                <br />
                <h5>{t('main_modal.what_is_the_group_name')}?</h5>
                <br />
                <TextInputField
                    onChange={changeName}
                    value={groupVals.name}
                    name='group-name'
                    addClass={showError ? 'input-error' : ''}
                    placeholder={t('main_modal.enter_name')} />
                {showError && <small className='text-danger text-left'>Please enter Group Name to proceed.</small>}
            </Stack>
            <br /><br />
            <ModalNavs prevModal={() => { nextModal(0) }} nextModal={handleSubmitName} activeModal={1} skipOnboarding={skipOnboarding} buttonText={t('main_modal.continue')} />

        </>
    )
}
