import { Auth } from 'aws-amplify'
import axios from 'axios'
import React, { useEffect, useState, type ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { IsLoading } from '../registration/fragments/isLoading'
import { useAppDispatch } from '../../redux/app/create_njangee_store/hooks'
import { setUserAuth } from './slices/authSlices'

interface IUserAuthentication {
  children: ReactNode
}
export const UserAuthentication: React.FC<IUserAuthentication> = ({
  children
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const api = process.env.REACT_APP_API as string
  useEffect(() => {
    const getAuthUser = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const jwtToken = user.signInUserSession.idToken.jwtToken as string
        const session = await axios.get(`${api}/session`, {
          headers: {
            Authorization: jwtToken
          }
        })
        const userData = session.data.data
        dispatch(setUserAuth({ user: userData }))
      } catch (error) {
        localStorage.removeItem('njangee_lang')
        navigate('/login')
      } finally {
        setIsLoading(false)
      }
    }
    void getAuthUser()
  }, [])
  return isLoading ? <IsLoading /> : <>{children}</>
}
