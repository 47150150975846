import React from 'react'

interface ITextInputField {
    type?: string
    name?: string
    value?: any
    defaultValue?: any
    placeholder?: any
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    required?: boolean
    anyValidation?: any
    addClass?: string
    disabled?: boolean
    maxLength?: number
}
export const TextInputField: React.FC<ITextInputField> = ({ type, name, value, defaultValue, placeholder, onChange, required, anyValidation, addClass, disabled, maxLength }) => {
    // Use state to manage the input value

    return (
        <input
            type={type ?? 'text'}
            name={name}
            value={value}
            maxLength={maxLength ?? 100}
            defaultValue={defaultValue}
            placeholder={placeholder as string}
            onChange={onChange}
            required={required as boolean}
            {...anyValidation}
            className={`!w-full text-input ${addClass as string}`}
            disabled={disabled as boolean} />
    )
}
