import React, { type ReactElement } from 'react'
import { Stack, Box } from '@mui/material'
import { Masonry } from '@mui/lab'
import { WebLayout } from './layouts.tsx/app'
import BgImage from '../../images/web_images/img-features-bg@2x.png'
import CTABgImage from '../../images/web_images/img-features-cta-bg@2x.png'
import HeroImg from '../../images/web_images/img-features-hero@2x.png'
import ManageGroupsIcon from '../../images/icon_images/iccon-manage-groups.svg'
import GlobalIcon from '../../images/icon_images/icon-global.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Features: React.FC = () => {
  const { t } = useTranslation(['web'])
  const masonaryBox = function (
    id: number,
    image: string,
    title: string,
    desc: string,
    extraClass: string
  ): ReactElement {
    return (
      <Stack
        key={id}
        className={`w-40 rounded-md py-11 px-5 md:px-7 lg:px-9 gap-5 ${extraClass}`}
      >
        <img src={image} alt='' />
        <Stack>
          <p className='font-semibold'>{title}</p>
          <small>{desc}</small>
        </Stack>
      </Stack>
    )
  }
  const featuresData = [
    {
      id: 1,
      image: GlobalIcon,
      title: t('features.easy_signup'),
      desc: t('features.easy_signup_desc'),
      extraClass: 'bg-[#fdedf7]'
    },
    {
      id: 2,
      image: ManageGroupsIcon,
      title: t('features.invite_friends'),
      desc: t('features.invite_friends_desc'),
      extraClass: 'bg-[#f6f0e6] sm:!mt-16'
    },
    {
      id: 3,
      image: GlobalIcon,
      title: t('features.manage_groups'),
      desc: t('features.manage_groups_desc'),
      extraClass: 'bg-[#f1f4fc]'
    },
    // {
    //     id: 4,
    //     image: ManageGroupsIcon,
    //     title: 'Play Njangee Globally',
    //     desc: 'Join or create Njangee groups from anywhere in the world. Distance is no longer a barrier.',
    //     extraClass: 'bg-[#f8f8f8]'
    // },
    {
      id: 4,
      image: GlobalIcon,
      title: t('features.setup_reminder'),
      desc: t('feature.setup_reminder_desc'),
      extraClass: 'bg-[#e5eafa]'
    },
    {
      id: 5,
      image: ManageGroupsIcon,
      title: t('features.accept_contributions_through_momo'),
      desc: t('features.accept_contributions_through_momo_desc'),
      extraClass: 'bg-[#ebfffe]'
    },
    {
      id: 6,
      image: GlobalIcon,
      title: t('features.choose_how_you_recieve_notifications'),
      desc: t('features.choose_how_you_recieve_notifications_desc'),
      extraClass: 'bg-[#f6f0e6]'
    },
    {
      id: 7,
      image: ManageGroupsIcon,
      title: t('features.announcements_for_group_admins'),
      desc: t('features.announcements_for_group_admins_caption'),
      extraClass: 'bg-[#f1f4fc]'
    },
    {
      id: 8,
      image: GlobalIcon,
      title: t('features.access_to_analytics'),
      desc: t('features.access_to_analytics_caption'),
      extraClass: 'bg-[#fdedf7]'
    }
  ]

  return (
    <WebLayout>
      <Stack
        className='w-full h-full bg-white'
        style={{
          backgroundImage: `url(${BgImage})`,
          backgroundPosition: 'top left',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          minHeight: '300px'
        }}
      >
        <Stack className='w-full pt-20 px-3'>
          <h2 className='text-center text-[36.62px] md:text-[45.78px] mx-auto w-full sm:w-8/12 lg:w-6/12'>
            {t('features.hero_text')}
          </h2>
        </Stack>

        <Stack
          className='w-full h-full mx-auto'
          sx={{
            minHeight: {
              xs: '300px',
              sm: '450px',
              md: '650px'
            }
          }}
          style={{
            backgroundImage: `url(${HeroImg})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'
          }}
        ></Stack>

        <Stack className='w-full container'>
          <Box
            sx={{ width: '100%', minHeight: '400px' }}
            className='pb-10 px-0 sm:px-10 mx-auto md:px-14 lg:px-20'
          >
            <Masonry
              columns={{
                xs: 1,
                sm: 2,
                lg: 3
              }}
              spacing={{
                xs: 1,
                sm: 4,
                lg: 6,
                xl: 8
              }}
            >
              {featuresData.map((feature, index) => {
                return masonaryBox(
                  feature.id,
                  feature.image,
                  feature.title,
                  feature.desc,
                  feature.extraClass
                )
              })}
            </Masonry>
          </Box>
        </Stack>
      </Stack>

      <Stack
        className='w-full'
        style={{
          backgroundImage: `url(${CTABgImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          minHeight: '400px'
        }}
      >
        <Stack className='container'>
          <Stack className='px-0 sm:px-5 md:px-10 lg:px-20 py-20 gap-8'>
            <h1 className='text-[36.62px] md:text-[57.22px] leading-tight'>
              Ready to Get your <br className='hidden sm:block' />
              <span className='text-primary font-extra text-[36.62px] md:text-[57.22px]'>
                njangee
              </span>{' '}
              group online?
            </h1>
            <Link to='/register' className='btn btn-primary'>
              {t('features.sign_up_today')}
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </WebLayout>
  )
}
