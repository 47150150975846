import React from 'react'
import { Stack } from '@mui/material'
import { WebLayout } from './layouts.tsx/app'
import Bg404 from '../../images/web_images/img-404@2x.png'
import { Link } from 'react-router-dom'

export const Page404: React.FC = () => {
    return (
        <WebLayout>
            <Stack className='w-full' style={{
                backgroundImage: `url(${Bg404})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                minHeight: '300px'
            }}>
            </Stack>
            <Stack className='w-full text-center'>
                <p className='text-lg'>Oops! Looks like you followed a bad link.</p>
                <p className='text-lg'>If you think this is a problem with us, please tell us</p>
                <br />
                <Link to='/' className='btn btn-primary mx-auto'>Back to Home Page</Link>
            </Stack>
            <br />
            <br />
        </WebLayout>
    )
}
