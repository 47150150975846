import { ChevronLeft } from '@mui/icons-material'
import { Stack, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { CircularLoadingProgress } from '../../../registration/fragments/circularProgress'

interface IModalNavs {
  activeModal: number
  prevModal?: any
  nextModal: any
  buttonText: string
  skipOnboarding: any
  isLoading?: boolean
}

export const ModalNavs: React.FC<IModalNavs> = ({
  activeModal,
  prevModal,
  nextModal,
  buttonText,
  skipOnboarding,
  isLoading = false
}) => {
  const { t } = useTranslation(['dashboard'])
  return (
    <>
      {activeModal < 4 && (
        <Stack
          direction='row'
          className='gap-1 !h-full sm:!hidden mx-auto mt-auto items-end justify-center'
        >
          <span
            className={`${
              activeModal === 1 ? 'bg-primary' : 'bg-secondary-xlight'
            } h-1 w-4 rounded-full`}
          ></span>
          <span
            className={`${
              activeModal === 2 ? 'bg-primary' : 'bg-secondary-xlight'
            } h-1 w-4 rounded-full`}
          ></span>
          <span
            className={`${
              activeModal === 3 ? 'bg-primary' : 'bg-secondary-xlight'
            } h-1 w-4 rounded-full`}
          ></span>
          <span
            className={`${
              activeModal === 4 ? 'bg-primary' : 'bg-secondary-xlight'
            } h-1 w-4 rounded-full`}
          ></span>
        </Stack>
      )}
      <Stack
        direction='row'
        className='w-full !h-fit mt-4 sm:mt-auto items-center px-4 sm:px-10 lg:px-5'
      >
        {activeModal < 4 && (
          <Button
            onClick={prevModal}
            variant='text'
            className='text-th-black mr-auto w-fit flex items-center gap-1 !normal-case !font-semibold'
          >
            <ChevronLeft /> {t('main_modal.back')}
          </Button>
        )}
        {activeModal < 4 && (
          <Stack
            direction='row'
            className='gap-1 mx-auto !hidden sm:!flex sm:w-full items-center justify-center'
          >
            <span
              className={`${
                activeModal === 1 ? 'bg-primary' : 'bg-secondary-xlight'
              } h-1 w-4 rounded-full`}
            ></span>
            <span
              className={`${
                activeModal === 2 ? 'bg-primary' : 'bg-secondary-xlight'
              } h-1 w-4 rounded-full`}
            ></span>
            <span
              className={`${
                activeModal === 3 ? 'bg-primary' : 'bg-secondary-xlight'
              } h-1 w-4 rounded-full`}
            ></span>
            <span
              className={`${
                activeModal === 4 ? 'bg-primary' : 'bg-secondary-xlight'
              } h-1 w-4 rounded-full`}
            ></span>
          </Stack>
        )}
        <button
          onClick={nextModal}
          className='btn btn-primary ml-auto w-full flex gap-2'
          disabled={isLoading}
        >
          {isLoading && <CircularLoadingProgress />} {buttonText}
        </button>
      </Stack>

      <Stack
        direction='row'
        className='w-full items-center justify-center gap-3 mx-auto mt-6'
      >
        <button
          className='border-0 bg-none font-semibold hover:underline flex gap-2'
          onClick={skipOnboarding}
        >
          {t('main_modal.skip_to_dashboard')}
        </button>
      </Stack>
    </>
  )
}
