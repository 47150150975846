import React from 'react'
import { Stack } from '@mui/material'
import { WebLayout } from './layouts.tsx/app'
import HeaderBg from '../../images/web_images/img-news-pageHeader-bg@2x.png'

export const TermsOfService: React.FC = () => {
  return (
    <WebLayout>
      <Stack
        className='w-full h-full bg-white terms-of-service'
        style={{
          backgroundImage: `url(${HeaderBg})`,
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          minHeight: '300px'
        }}
      >
        <Stack className='w-full py-20'>
          <Stack className='text-center mx-auto gap-5'>
            <h2 className='text-primary'>Terms of Service</h2>
          </Stack>

          <Stack className='w-full my-20'>
            <Stack className='container w-full'>
              <Stack
                direction='row'
                display='grid'
                className='w-full h-full bg-white grid-cols-1 md:grid-cols-5 lg:grid-cols-4 rounded-md shadow-2xl'
              >
                <Stack className='w-full bg-primary-3xlight rounded-l-md py-10 px-5 gap-2 col-span-1 md:col-span-2 lg:col-span-1'>
                  <Stack className='border-b border-primary py-2'>
                    <p className='text-primary font-semibold'>Interpretation</p>
                  </Stack>
                  <Stack className='border-b border-main py-2'>
                    <p className='text-th-black font-semibold'>Definions</p>
                  </Stack>
                  <Stack className='border-b border-main py-2'>
                    <p className='text-th-black font-semibold'>
                      Acknowledgment
                    </p>
                  </Stack>
                  <Stack className='border-b border-main py-2'>
                    <p className='text-th-black font-semibold'>User Accounts</p>
                  </Stack>
                </Stack>

                <Stack className='w-full col-span-1 md:col-span-3 py-10 px-5 md:px-10 lg:px-24 gap-4'>
                  <Stack className='gap-3'>
                    <p>
                      Effective Date: 1<sup>st</sup> September 2024.
                    </p>
                    <br />
                    <p>
                      Welcome to Njangee! These Terms of Use (&quot;Terms&quot;) govern
                      your access to and use of the Njangee website, mobile
                      applications, and services (collectively, the &quot;Services&quot;).
                      By using our Services, you agree to these Terms. Please
                      read them carefully.
                    </p>
                  </Stack>
                  <Stack className='gap-3'>
                    <h6>1. Acceptance of Terms </h6>
                    <p>
                      By accessing or using the Services, you agree to be bound
                      by these Terms. If you do not agree with any part of these
                      Terms, you may not access or use our Services.
                    </p>
                  </Stack>
                  <Stack className='gap-3'>
                    <h6>2. Eligibility </h6>
                    <p>
                      To use Njangee, you must be at least 18 years old or the
                      age of majority in your jurisdiction. By using the
                      Services, you confirm that you meet this requirement.
                    </p>
                  </Stack>
                  <Stack className='gap-3'>
                    <h6>3. Account Registration</h6>
                    <p>
                      You are required to create an account to access certain
                      features of the Services. You agree to provide accurate
                      and complete information during the registration process
                      and to keep your account information updated. You are
                      responsible for maintaining the confidentiality of your
                      account and password, and you agree to notify us
                      immediately of any unauthorized access to your account.
                    </p>
                  </Stack>
                  <Stack className='gap-3'>
                    <h6>4. User Responsibilities</h6>
                    <p>When using Njangee, you agree to:</p>
                    <ul>
                      <li>
                        <b>Use the Services Lawfully:</b> You will use the
                        Services only for lawful purposes and in accordance with
                        these Terms.
                      </li>
                      <li>
                        <b>Respect Other Users:</b> You will not engage in any
                        behavior that harms, harasses, or violates the rights of
                        other users.
                      </li>
                      <li>
                        <b>Maintain Security:</b> You will not interfere with
                        the security or operation of the Services, including by
                        attempting to hack, disrupt, or bypass any security
                        measures.
                      </li>
                    </ul>
                  </Stack>
                  <Stack className='gap-3'>
                    <h6>5. Payments and Transactions</h6>
                    <p>
                      Njangee enables users to make financial transactions,
                      including contributions to savings groups and receiving
                      payouts. By using these features, you agree to the
                      following:
                    </p>
                    <ul>
                      <li>
                        <b>Fees:</b> Certain transactions may incur fees, which
                        will be clearly disclosed before completing the
                        transaction.
                      </li>
                      <li>
                        <b>Third-Party Payment Processors:</b> Njangee uses
                        third-party services such as mobile money providers
                        (e.g., MTN, Orange) and Stripe or PayPal for payment
                        processing. Your use of these services is subject to
                        their respective terms of service.
                      </li>
                      <li>
                        <b>Accuracy:</b> You are responsible for ensuring that
                        all payment and transaction information is accurate and
                        up to date.
                      </li>
                    </ul>
                  </Stack>
                  <Stack className='gap-3'>
                    <h6>6. Prohibited Activities</h6>
                    <p>You agree not to:</p>
                    <ul>
                      <li>
                        Use the Services in any way that violates any applicable
                        law or regulation.
                      </li>
                      <li>
                        Use the Services for fraudulent purposes or to
                        participate in any illegal activities.
                      </li>
                      <li>
                        Post, upload, or share any content that is unlawful,
                        harmful, defamatory, or obscene.
                      </li>
                      <li>
                        Attempt to gain unauthorized access to our systems,
                        data, or the accounts of other users.
                      </li>
                    </ul>
                  </Stack>
                  <Stack className='gap-3'>
                    <h6>7. Intellectual Property</h6>
                    <p>
                      Njangee owns all rights, title, and interest in the
                      Services, including all content, software, and trademarks.
                      You are granted a limited, non-exclusive, non-transferable
                      license to use the Services for your personal,
                      non-commercial use. You may not copy, modify, distribute,
                      sell, or lease any part of the Services without Njangee’s
                      express written consent.
                    </p>
                  </Stack>
                  <Stack className='gap-3'>
                    <h6>8. Termination</h6>
                    <p>
                      We reserve the right to suspend or terminate your account
                      and access to the Services at our discretion, with or
                      without cause, and without notice. Reasons for termination
                      may include:
                    </p>
                    <ul>
                      <li>Breach of these Terms.</li>
                      <li>Engaging in prohibited activities.</li>
                      <li>Providing false or misleading information.</li>
                    </ul>
                    <p>
                      If your account is terminated, you may lose access to any
                      data or content associated with your account.
                    </p>
                  </Stack>
                  <Stack className='gap-3'>
                    <h6>9. Disclaimers</h6>
                    <ul>
                      <li>
                        <b>No Warranties:</b> Njangee provides the Services &quot;as
                        is&quot; and without any warranty or guarantee of any kind.
                        We do not guarantee that the Services will be
                        error-free, secure, or uninterrupted.
                      </li>

                      <li>
                        <b>Limitation of Liability:</b> Njangee is not liable
                        for any indirect, incidental, special, or consequential
                        damages resulting from your use of the Services,
                        including but not limited to financial loss, data loss,
                        or system failure.
                      </li>
                    </ul>
                  </Stack>
                  <Stack className='gap-3'>
                    <h6>10. Indemnification</h6>
                    <p>
                      You agree to indemnify and hold Njangee harmless from any
                      claims, damages, liabilities, or expenses (including legal
                      fees) arising from your use of the Services, your
                      violation of these Terms, or any actions related to your
                      account.
                    </p>
                  </Stack>
                  <Stack className='gap-3'>
                    <h6>11. Governing Law</h6>
                    <p>
                      These Terms shall be governed by and construed in
                      accordance with the laws of [Insert Jurisdiction]. Any
                      legal disputes arising from these Terms or the use of the
                      Services will be resolved in the courts of [Insert
                      Jurisdiction].
                    </p>
                  </Stack>
                  <Stack className='gap-3'>
                    <h6>12. Changes to These Terms</h6>
                    <p>
                      We reserve the right to modify or update these Terms at
                      any time. If we make significant changes, we will notify
                      you via email or through a notice on our platform. By
                      continuing to use the Services after any changes, you
                      agree to the revised Terms.
                    </p>
                  </Stack>
                  <Stack className='w-full gap-3'>
                    <h6>13. Contact Us</h6>
                    <p>
                      If you have any questions or concerns about these Terms,
                      please contact us at: <br />
                      <b>Njangee</b>
                      <br /> Email: legal@njangee.com <br />
                      Address: UB Junction, Buea, Cameroon
                    </p>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </WebLayout>
  )
}
