import React from 'react'
import { CircularLoadingProgress } from './circularProgress'
import { Stack } from '@mui/material'

export const IsLoading: React.FC = () => {
    return (
        <Stack className='w-full h-full flex items-center justify-center' sx={{ height: '100vh' }}>
            <div className='h-12 w-12 bg-primary flex items-center justify-center'>
                <CircularLoadingProgress />

            </div>
        </Stack>
    )
}
