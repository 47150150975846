import React from 'react'
import { Stack } from '@mui/material'
import { WebLayout } from './layouts.tsx/app'
import HeaderBg from '../../images/web_images/img-news-pageHeader-bg@2x.png'

export const PrivacyPolicy: React.FC = () => {
  return (
    <WebLayout>
      <Stack
        className='w-full h-full bg-white privacy-policy'
        style={{
          backgroundImage: `url(${HeaderBg})`,
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          minHeight: '300px'
        }}
      >
        <Stack className='w-full py-20'>
          <Stack className='text-center mx-auto gap-5'>
            <h2 className='text-primary'>Privacy Policy</h2>
          </Stack>

          <Stack className='w-full container'>
            <Stack className='w-full bg-white shadow-2xl rounded-md my-5 py-10 px-6 sm:px-12 md:px-14 lg:px-20'>
              <Stack className='w-full gap-3'>
                <p className='font-semibold'>
                  Effective Date: 1, September 2024
                </p>
                <p>
                  Njangee (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) is committed to protecting your
                  privacy. This Privacy Policy explains how we collect, use,
                  disclose, and safeguard your information when you use our
                  website, mobile applications, and services (collectively, the
                  &quot;Services&quot;).
                </p>{' '}
                <p>
                  {' '}
                  Please read this policy carefully to understand our views and
                  practices regarding your personal data and how we handle it.
                </p>
              </Stack>
              <br />
              <br />
              <Stack className='w-full gap-3'>
                <h6>1. Information We Collect</h6>
                <p>We may collect and process the following data:</p>
                <ul>
                  <li>
                    <b> Personal Information:</b> Name, email address, phone
                    number, date of birth, and other identifying details when
                    you create an account or use our Services.
                  </li>
                  <li>
                    <b>Financial Information:</b> Bank account details, mobile
                    money details, and transaction history when you perform
                    transactions through Njangee.
                  </li>
                  <li>
                    <b>Usage Information:</b> Data on how you interact with our
                    app and website, including device type, IP address, and app
                    usage statistics.
                  </li>
                  <li>
                    <b> Location Data:</b> Information about your location, if
                    you allow us to access location services.
                  </li>
                </ul>
              </Stack>
              <br />
              <br />
              <Stack className='w-full'>
                <h6>2. How We Use Your Information</h6>
                <p>We use the information we collect in the following ways:</p>
                <ul>
                  <li>
                    <b> With Service Providers:</b> We may share your
                    information with trusted third-party service providers to
                    assist in delivering our Services, such as payment
                    processing partners (e.g., mobile money providers).
                  </li>
                  <li>
                    <b>Legal Requirements:</b> We may disclose your information
                    to comply with applicable laws, legal processes, or
                    government requests.
                  </li>
                  <li>
                    <b>Business Transfers:</b> In the event of a merger,
                    acquisition, or sale of assets, your information may be
                    transferred as part of that transaction.
                  </li>
                </ul>
              </Stack>
              <br />
              <br />
              <Stack className='w-full gap-3'>
                <h6>3. Sharing Your Information</h6>
                <p>
                  We do not sell or share your personal information with third
                  parties, except in the following circumstances:
                </p>
                <ul>
                  <li>
                    <b> With Service Providers:</b> Including processing
                    transactions, managing Njangee groups, and ensuring secure
                    and efficient use of our platform.
                  </li>
                  <li>
                    <b> To Communicate with You:</b> Sending notifications,
                    updates, and responding to inquiries or requests.
                  </li>
                  <li>
                    <b>For Analytics:</b> Monitoring usage to improve our
                    platform and develop new features.
                  </li>
                  <li>
                    <b> For Security:</b> Detecting and preventing fraud,
                    enforcing our terms and policies, and ensuring compliance
                    with legal obligations.
                  </li>
                </ul>
              </Stack>
              <br />
              <br />
              <Stack className='w-full gap-3'>
                <h6>4. Security of Your Information</h6>
                <p>
                  We take the security of your personal information seriously.
                  We use industry-standard encryption technologies and security
                  practices to protect your data. However, no method of
                  transmission over the internet or method of electronic storage
                  is completely secure. While we strive to use reasonable
                  efforts to protect your information, we cannot guarantee its
                  absolute security.
                </p>
              </Stack>
              <br />
              <br />
              <Stack className='w-full gap-3'>
                <h6>5. Your Rights and Choices</h6>
                <p>You have the right to:</p>
                <ul>
                  <li>
                    <b>Access Your Data:</b> You can request to view the
                    personal information we hold about you.
                  </li>
                  <li>
                    <b>Update or Delete Data:</b> You can update your personal
                    information or request its deletion from our system.
                  </li>
                  <li>
                    <b>Opt-Out of Communications:</b> ou can opt-out of
                    receiving promotional emails or notifications through your
                    account settings.
                  </li>
                  <li>
                    <b> For Security:</b> Detecting and preventing fraud,
                    enforcing our terms and policies, and ensuring compliance
                    with legal obligations.
                  </li>
                </ul>
              </Stack>
              <br />
              <br />
              <Stack className='w-full gap-3'>
                <h6>6. Cookies and Tracking Technologies</h6>
                <p>
                  We may use cookies and similar tracking technologies to
                  improve your experience on our platform. Cookies help us
                  remember your preferences, understand how you use our
                  Services, and enhance site performance.
                </p>
              </Stack>
              <br />
              <br />
              <Stack className='w-full gap-3'>
                <h6>7. Third-Party Links</h6>
                <p>
                  Our platform may contain links to third-party websites or
                  services. We are not responsible for the privacy practices of
                  these third parties. We recommend reading their privacy
                  policies before sharing any personal information.
                </p>
              </Stack>
              <br />
              <br />
              <Stack className='w-full gap-3'>
                <h6>8. Children&apos;s Privacy</h6>
                <p>
                  Our Services are not intended for use by individuals under the
                  age of 18. We do not knowingly collect personal information
                  from children. If you are under 18, please do not use our
                  Services or provide any information.
                </p>
              </Stack>
              <br />
              <br />
              <Stack className='w-full gap-3'>
                <h6>9. Changes to This Privacy Policy</h6>
                <p>
                  We may update this Privacy Policy from time to time. If we
                  make significant changes, we will notify you by email or by
                  posting a notice on our platform. We encourage you to review
                  this policy periodically for any updates.
                </p>
              </Stack>
              <br />
              <br />
              <Stack className='w-full gap-3'>
                <h6>10. Contact Us</h6>
                <p>
                  If you have any questions about this Privacy Policy or how we
                  handle your personal information, please contact us at: <br />
                  <b>Njangee</b>
                  <br /> Email: legal@njangee.com <br />
                  Address: UB Junction, Buea, Cameroon
                </p>
              </Stack>

              <br />
              <br />
              <br />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </WebLayout>
  )
}
