import React, { useState } from 'react'
import {
  Stack,
  FormControl,
  Box,
  Select,
  MenuItem,
  type SelectChangeEvent
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TotalGroupsIcon from '../../../../images/icon_images/icon-groups-total-groups1.svg'
import TotalContributionsIcon from '../../../../images/icon_images/icon-group-total-contribution.svg'
import SendIcon from '../../../../images/icon_images/icon-send.svg'
import BgTotalContributions from '../../../../images/dashboard_images/dashboard-bg-1.png'
import ArrowRightIcon from '../../../../images/icon_images/icon-arrow-right.svg'
import ReminderCalendarColorIcon from '../../../../images/icon_images/icon-reminder-calender-color.svg'
import PlayNjangeeIcon from '../../../../images/icon_images/icon-play.svg'
import { useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { createGetGroupsSelector } from '../slices/getGroupSlice'
import { convertToMonthInWords } from '../../../common/helper/convertToDate'

export const GroupsTopBoxes: React.FC = () => {
  const [contributionsSelect, setContributionsSelect] = useState('all')
  const { t } = useTranslation(['dashboard'])
  const allGroups = useAppSelector(createGetGroupsSelector)
  const language = localStorage.getItem('i18nextLng')

  const handleTotalContribution = (event: SelectChangeEvent): void => {
    setContributionsSelect(event.target.value)
  }
  return (
    <Stack
      direction='row'
      display='grid'
      className='grid-col-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-5 w-full'
    >
      <Stack direction='column' className='w-full top-box bg-pink-xlight'>
        <Stack
          direction='row'
          className='justify-start items-center gap-4 w-full h-full px-4 py-4'
        >
          <img alt='' src={TotalGroupsIcon} className='w-fit h-fit left-img' />
          <Stack>
            <h4>{allGroups.meta.totalGroups ?? '0'}</h4>
            <p className='font-semibold'>{t('groups.total_groups')}</p>
          </Stack>
        </Stack>
        <Stack
          direction='row'
          className='justify-start px-4 py-2 w-full rounded-b-lg mt-auto'
          style={{ backgroundColor: '#f3eadbb4' }}
        >
          <Link
            to='/'
            className='font-extrabold flex text-brown text-13 hover:opacity-70'
          >
            {t('general.common.invite_friends')}{' '}
            <img alt='' src={SendIcon} className='pl-2 w-fit h-fit' />
          </Link>
        </Stack>
      </Stack>

      <Stack
        direction='column'
        className='w-full top-box bg-primary-2xlight'
        style={{ backgroundImage: `url(${BgTotalContributions})` }}
      >
        <Stack
          direction='row'
          className='justify-start items-center gap-4 w-full h-full px-4 py-4'
        >
          <img
            alt=''
            src={TotalContributionsIcon}
            className='w-fit h-fit left-img'
          />
          <Stack>
            <h4>XAF {allGroups.meta.totalContributions ?? '0'}</h4>
            <p className='font-semibold'>{t('groups.total_contributions')}</p>
          </Stack>
        </Stack>
        <Stack
          direction='row'
          className='justify-start gap-2 flex-wrap px-4 py-2 w-full rounded-b-lg mt-auto'
          style={{ backgroundColor: '#d6ddf8' }}
        >
          <Link
            to='/user-payments'
            className='font-extrabold flex items-center text-primary text-13 hover:opacity-70'
          >
            {t('groups.view_payment_history')}{' '}
            <img alt='' src={ArrowRightIcon} className='pl-2 w-fit h-fit' />
          </Link>
          <Stack className='ml-auto'>
            <Box sx={{ minWidth: 85 }} borderRadius='9999px'>
              <FormControl
                fullWidth
                style={{ borderRadius: '9999px' }}
                className='!border-0'
              >
                <Select
                  id='date-sort-select'
                  value={contributionsSelect}
                  label=''
                  onChange={handleTotalContribution}
                  style={{ borderRadius: '9999px', height: '25px' }}
                  className='bg-primary-3xlight !border-0 small'
                >
                  <MenuItem value='all' className='small'>
                    All
                  </MenuItem>
                  <MenuItem value='this-year' className='small'>
                    This Year
                  </MenuItem>
                  <MenuItem value='last-year' className='small'>
                    Last Year
                  </MenuItem>
                  <MenuItem value='this-month' className='small'>
                    This Month
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </Stack>
      </Stack>

      {allGroups?.meta?.upcomingContribution !== null &&
        !allGroups?.isLoading && (
          <Stack direction='row' className='w-full top-box bg-white shadow-2xl'>
            <Stack
              direction='column'
              className='justify-start items-center gap-4 w-auto py-4 px-3 md:px-6 bg-primary-3xlight rounded-l-lg'
            >
              <Stack
                direction='column'
                className='justify-center items-center gap-2'
              >
                {convertToMonthInWords(
                  language ?? '',
                  allGroups?.meta?.upcomingContribution?.nextContribution?.date
                ).toLowerCase() === 'today' ? (
                  <p className='text-danger font-semibold'>
                    {t('groups.due')} {t('general.common.today')}!
                  </p>
                ) : (
                  <p></p>
                )}
                -
                <img
                  alt=''
                  src={ReminderCalendarColorIcon}
                  className='w-fit h-fit'
                />
                <p className='font-semibold text-secondary-light uppercase text-13'>
                  {convertToMonthInWords(
                    language ?? '',
                    allGroups?.meta?.upcomingContribution?.nextContribution
                      ?.date
                  )}
                </p>
              </Stack>
            </Stack>
            <Stack
              direction='column'
              className='justify-start px-4 py-3 rounded-r-lg'
            >
              {/* <Link to='/' className='font-extrabold flex text-brown text-13 hover:opacity-70'>Invite Friends <img alt='' src={SendIcon} className='pl-2 w-fit h-fit' /></Link> */}
              <p className='text-primary-bold font-bold'>
                {t('groups.upcoming_contributions')}
              </p>
              <Stack className='py-4'>
                <h4 className='text-primary leading-6 mb-1'>
                  {allGroups?.meta?.upcomingContribution?.currency}{' '}
                  {
                    allGroups?.meta?.upcomingContribution?.nextContribution
                      ?.amount
                  }
                </h4>
                <small className='text-primary-bold font-semibold'>
                  {allGroups?.meta?.upcomingContribution?.groupName}
                </small>
              </Stack>
              <Link
                to='/user-groups/play-njangee'
                className='btn-with-icon btn-primary flex items-center !py-1 mt-auto'
              >
                <img
                  alt=''
                  src={PlayNjangeeIcon}
                  className='w-fit h-fit pr-2'
                />{' '}
                {t('general.common.play_njangee')}
              </Link>
            </Stack>
          </Stack>
        )}
    </Stack>
  )
}
