import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { SentInvitations } from './sentInvitations'
import { RequestedInvitations } from './requestedInvitations'
import { appGetRequest } from '../../../common/helper/httpRequests'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import { createUserAuthSelector } from '../../../amplify/slices/authSlices'
import {
  createGetInvitationsSelector,
  setGetInvitations
} from '../../invitations/slices/getInvitationsSlice'
import {
  createGetSentInvitationsSelector,
  setGetSentInvitations
} from '../../invitations/slices/getSentInvitationsSlice'

export const Invitations: React.FC = () => {
  const { t } = useTranslation(['dashboard'])
  const [showSentInvitation, setShowSentInvitation] = useState<boolean>(true)
  const userData = useAppSelector(createUserAuthSelector).user
  const dispatch = useAppDispatch()
  const invitations = useAppSelector(createGetInvitationsSelector)
  const sentInvitations = useAppSelector(createGetSentInvitationsSelector)

  const onShowSentInvitation = (): void => {
    setShowSentInvitation(true)
  }
  const onShowRequestedInvitation = (): void => {
    setShowSentInvitation(false)
  }

  const getInvitations = async (): Promise<void> => {
    try {
      const invData = await appGetRequest(
        `/users/${userData?.id as string}/invitations/received-by-email`
      )
      dispatch(
        setGetInvitations({
          ...invitations,
          invitations: invData.data.data,
          meta: invData.data.meta
        })
      )
    } catch (error) {
      console.error('Error: ', error)
    }
  }
  const getSentInvitations = async (): Promise<void> => {
    try {
      const invData = await appGetRequest(
        `/users/${userData?.id as string}/invitations/sent`
      )
      dispatch(
        setGetSentInvitations({
          ...sentInvitations,
          sentInvitations: invData.data.data,
          meta: invData.data.meta
        })
      )
    } catch (error) {
      console.error('Error: ', error)
    }
  }

  useEffect(() => {
    void getInvitations()
    void getSentInvitations()
  }, [])
  return (
    <div className='section-box mt-4'>
      <Stack
        direction='row'
        className='border-0 border-b-w-1half border-main py-3 px-5'
      >
        <h6 className=''>{t('dashboard.invitations')}</h6>
        <Link
          to='/user-groups/5/show'
          className='ml-auto font-semibold text-black-medium'
        >
          {t('general.common.see_all')}
        </Link>
      </Stack>

      <Stack direction='column' className='py-4 px-4'>
        <Stack direction='row' className='items-center flex-wrap gap-4'>
          <Stack
            direction='row'
            spacing={1}
            className='group-option-buttons bg-primary-2xlight !border-0'
          >
            <button
              onClick={onShowSentInvitation}
              className={`btn-option ${
                showSentInvitation
                  ? 'btn-option btn-option-primary'
                  : 'btn-option btn-option-white'
              }`}
            >
              {t('dashboard.sent')}
              {showSentInvitation && (
                <span className='text-primary-xlight font-semibold pl-2'>
                  {sentInvitations.sentInvitations.length}
                </span>
              )}
            </button>
            <button
              onClick={onShowRequestedInvitation}
              className={`btn-option ${
                !showSentInvitation
                  ? 'btn-option btn-option-primary'
                  : 'btn-option btn-option-white'
              }`}
            >
              {t('dashboard.requested')}
              {!showSentInvitation && (
                <span className='text-primary-xlight font-semibold pl-2'>
                  {invitations.invitations.length}
                </span>
              )}
            </button>
          </Stack>
        </Stack>

        <div className=''>
          {showSentInvitation && <SentInvitations />}
          {!showSentInvitation && <RequestedInvitations />}
        </div>
      </Stack>
    </div>
  )
}
