import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer, Box, IconButton, Stack } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { NavLink } from 'react-router-dom'

export const MobileWebLeftMenu: React.FC = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const { t } = useTranslation(['web'])
    return (
        <div className='mobile-left-menu'>
            <IconButton
                size='large'
                edge='start'
                arai-label='logo'
                onClick={() => { setIsDrawerOpen(true) }}>
                <MenuIcon
                    className='text-th-black' />
            </IconButton>
            <Drawer anchor='left' className='drawer' open={isDrawerOpen} onClose={() => { setIsDrawerOpen(false) }}>
                <Box width='180px' className='drawer-box h-full bg-light px-4' role='presentation'>
                    <Stack direction='column' spacing={2} className='!h-fit'>
                        <h6 className='border-3 border-x-0 border-t-0 border-primary-2xlight py-3 mb-0'>Menu</h6>
                        <NavLink className={({ isActive }) =>
                            isActive ? 'flex items-center text-primary font-extra' : 'flex items-center'
                        } to='/'>{t('common.home')}</NavLink>

                        <NavLink className={({ isActive }) =>
                            isActive ? 'flex items-center text-primary font-extra' : 'flex items-center'
                        } to='/features'>{t('common.features')}</NavLink>

                        <NavLink className={({ isActive }) =>
                            isActive ? 'flex items-center activeNav text-primary font-extra' : 'flex items-center'
                        } to='/how-it-works'>{t('common.how_it_works')}</NavLink>

                        <NavLink className={({ isActive }) =>
                            isActive ? 'flex items-center text-primary font-extra' : 'flex items-center'
                        } to='/contact'>{t('common.contact')}</NavLink>
                    </Stack>

                </Box>
            </Drawer>
        </div>
    )
}
