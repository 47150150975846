import React, { type ReactElement, forwardRef } from 'react'
import { Snackbar, Alert, type AlertProps } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'

interface IToastMessageSuccess {
    showSuccessMessage: boolean
    successMessage: string
}
const SnackbarAlert = forwardRef<HTMLDivElement, AlertProps>(
    function SnackbarAlert (props, ref): ReactElement {
        return <Alert
            elevation={6}
            className='!bg-black !bg-opacity-90 text-th-white w-11/12 sm:w-9/12 md:w-8/12 lg:w-5/12'
            ref={ref}
            icon={<CheckCircle fontSize='small' className='text-success' />}
            {...props} />
    }
)

export const ToastMessageSuccess: React.FC<IToastMessageSuccess> = ({ showSuccessMessage, successMessage }) => {
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string): any => {
        if (reason === 'clickaway') {
            return
        }
        showSuccessMessage = false
    }
    return (
        <Snackbar open={showSuccessMessage} autoHideDuration={3000} onClose={handleClose} className='w-full'>
            <SnackbarAlert>
                {successMessage}
            </SnackbarAlert>
        </Snackbar>
    )
}
