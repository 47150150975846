import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface ChangePassword {
    id: number
    old_password: string
    new_password: string
    repeat_new_password: string
}

const initialState: ChangePassword = {
    id: 0,
    old_password: '',
    new_password: '',
    repeat_new_password: ''
}

export const createChangePasswordSlice = createSlice({
    name: 'change_password',
    initialState,
    reducers: {
        setChangePassword: (state, action: PayloadAction<ChangePassword>) => {
            return {
                ...state,
                id: action.payload.id,
                old_password: action.payload.old_password,
                new_password: action.payload.new_password,
                repeat_new_password: action.payload.repeat_new_password
            }
        }
    }
})

export const { setChangePassword } = createChangePasswordSlice.actions

export const createChangePasswordSelector = (state: RootState): ChangePassword => state.createChangePasswordReducer
export default createChangePasswordSlice.reducer
