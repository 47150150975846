import React from 'react'
import { Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FaceBookIcon from '../../../images/icon_images/icon-footer-social-fb-1.svg'
import LinkedInIcon from '../../../images/icon_images/icon-footer-social-linkedin.svg'
import TwitterIcon from '../../../images/icon_images/icon-social-x.svg'
import YoutubeIcon from '../../../images/icon_images/icon-footer-social-youtube.svg'
import LogoLarge from '../../../images/logo/njangee-logo-white@2x.png'

export const Footer: React.FC = () => {
  const { t } = useTranslation(['web'])
  //   const { t, i18n } = useTranslation(['web'])÷\
  //   const [lang, setLang] = useState<string>(i18n.language)
  //   const onClickLanguageChange = (e: any): void => {
  //     const language = e
  //     setLang(e)
  //     i18n
  //       .changeLanguage(language)
  //       .then((res) => {
  //         console.log(res)
  //       })
  //       .catch((err) => {
  //         console.error(err)
  //       })
  //   }
  return (
    <footer className='static bottom-0 w-full footer'>
      <Stack direction='column' className='w-full'>
        <Stack className='bg-primary-light w-full text-white'>
          <Stack
            direction='row'
            alignItems='center'
            className='container gap-6 md:gap-16 py-5 w-full flex-wrap'
          >
            <p className='uppercase font-semibold'>
              {t('footer.connect_with_us')}:
            </p>
            <Stack direction='row' className='items-center gap-4 social-icons'>
              <Link to='https://www.facebook.com/njangee' target='blank'>
                {' '}
                <img alt='' src={FaceBookIcon} />{' '}
              </Link>
              <Link to='https://www.linkedin.com/company/njangee' target='blank'>
                <span className='icon-style'>
                  <img alt='' src={LinkedInIcon} />
                </span>
              </Link>
              <Link to='https://x.com/Njangee182086' target='blank'>
              <span className='icon-style'>
                <img alt='' className='!w-5' src={TwitterIcon} /></span>
              </Link>
              <Link to='https://www.youtube.com/@njangee' target='blank'>
                {' '}
                <img alt='' src={YoutubeIcon} />{' '}
              </Link>
            </Stack>
            {/* <Stack direction='row' className='items-center ml-auto'>
              <p
                className={`uppercase cursor-pointer ${
                  lang === 'en' ? 'footer-lang-active' : 'footer-lang'
                }`}
                onClick={() => {
                  onClickLanguageChange('en')
                }}
              >
                English
              </p>
              <p className='px-3'>|</p>
              <p
                className={`uppercase cursor-pointer ${
                  lang === 'fr' ? 'footer-lang-active' : 'footer-lang'
                }`}
                onClick={() => {
                  onClickLanguageChange('fr')
                }}
              >
                Francais
              </p>
            </Stack> */}
          </Stack>
        </Stack>
        <Stack direction='column' className='bg-primary-bold'>
          <Stack className='container text-primary-xlight'>
            <Stack
              display='grid'
              alignContent='center'
              className='grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 sm:gap-16 sm:pt-20 sm:pb-14 lg:pt-32 lg:pb-16'
            >
              <Stack>
                <img alt='' src={LogoLarge} className='!w-fit !h-fit' />
                <small className='a-text'>
                {t('footer.desc')}
                </small>
              </Stack>
              <Stack className='mt-8 md:mt-16'>
                <h5 className='text-secondary-light mb-2'>
                  {t('footer.the_company')}
                </h5>
                <Link to='/about'>{t('footer.about_njangee')}</Link>
                <Link to='/team'>{t('footer.the_team')}</Link>
                <Link to='/contact'>{t('common.contact')}</Link>
              </Stack>
              <Stack className='mt-8 md:mt-16'>
                <h5 className='text-secondary-light mb-2'>
                  {t('footer.useful_links')}
                </h5>
                <Link to='/'>{t('common.features')}</Link>
                <Link to='/privacy-policy'>{t('footer.privacy_policy')}</Link>
                <Link to='/terms-of-service'>
                  {t('footer.terms_of_service')}
                </Link>
              </Stack>
              <Stack className='mt-8 md:mt-16'>
                <h5 className='text-secondary-light mb-2'>
                  {t('common.help_center')}
                </h5>
                <Link to='/contact'>{t('footer.contact_us')}</Link>
                <Link to='/'>{t('footer.faqs')}</Link>
              </Stack>
            </Stack>
            <hr
              style={{
                borderWidth: '0.12rem',
                borderColor: 'var(--primary-medium)'
              }}
              className='mt-5 mb-2'
            />
            <p className='a-text mb-6 text-secondary'>
              Copyright. &copy; 2023 njangee {t('footer.copyright')}
            </p>
          </Stack>
        </Stack>
      </Stack>
    </footer>
  )
}
