import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../redux/app/create_njangee_store/store'

export interface ChooseRegistration {
    id: number
    sign_up_option: string
    username_taken: string
    choose_forgot_password_type: string
    forgot_password_verification_code: string
    code_sent: boolean
}

const initialState: ChooseRegistration = {
    id: 0,
    sign_up_option: '',
    username_taken: '',
    choose_forgot_password_type: '',
    forgot_password_verification_code: '',
    code_sent: false
}

export const createChooseRegistrationSlice = createSlice({
    name: 'choose_registration',
    initialState,
    reducers: {
        setChooseRegistration: (state, action: PayloadAction<ChooseRegistration>) => {
            return {
                ...state,
                id: action.payload.id,
                sign_up_option: action.payload.sign_up_option,
                username_taken: action.payload.username_taken,
                choose_forgot_password_type: action.payload.choose_forgot_password_type,
                forgot_password_verification_code: action.payload.forgot_password_verification_code,
                code_sent: action.payload.code_sent
            }
        }
    }
})

export const { setChooseRegistration } = createChooseRegistrationSlice.actions

export const createChooseRegistrationSelector = (state: RootState): ChooseRegistration => state.createChooseRegistrationReducer
export default createChooseRegistrationSlice.reducer
