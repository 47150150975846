import React, { type ReactElement, useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material'
import { useTranslation } from 'react-i18next'
// import NavLeft from '../../../../images/icon_images/icon-nav-left-inactive.svg'
// import NavRight from '../../../../images/icon_images/icon-nav-right-active.svg'
import RecipientIcon from '../../../../images/icon_images/icon-recipient.svg'
import PaidIcon from '../../../../images/icon_images/icon-check-green.svg'
import PendingIcon from '../../../../images/icon_images/icon-hourglass-time-info.svg'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import {
  groupDetailSelector,
  setContriutionFilters,
  setGroupDetail
} from '../slices/groupDetail'
import {
  convertStringToISODate,
  formatToDateAndTimeString
} from '../../../common/helper/convertToDate'
import { appGetRequest } from '../../../common/helper/httpRequests'
import { useParams } from 'react-router-dom'
import { IsLoading } from '../../../registration/fragments/isLoading'

interface IFilterParams {
  from: string
  to: string
  status: string
}
export const GroupShowAllMyContributions: React.FC = () => {
  const [fromDateFilter, setFromDateFilter] = useState('')
  const [toDateFilter, setToDateFilter] = useState('')
  const [contributonStatusFilter, setContributonStatusFilter] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()

  const { t } = useTranslation(['dashboard'])
  const groupDetail = useAppSelector(groupDetailSelector)
  const [filterParams, setFilterParams] = useState<IFilterParams>({
    ...groupDetail.contributionFilters,
    from: fromDateFilter,
    to: toDateFilter,
    status: contributonStatusFilter
  })
  const params = useParams()

  const handleFromDateFilter = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setFromDateFilter(event.target.value)
    setFilterParams({
      ...filterParams,
      from: event.target.value
    })
    // apiDebounce(async () => {
    await getGroupContributions({
      ...filterParams,
      from: event.target.value
    })
    // })
  }
  const handlToDateFilter = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setToDateFilter(event.target.value)
    // apiDebounce(async () => {
    setFilterParams({
      ...filterParams,
      to: event.target.value
    })
    await getGroupContributions({
      ...filterParams,
      to: event.target.value
    })
    // })
  }
  const handleContributionStatusFilter = async (
    event: SelectChangeEvent
  ): Promise<void> => {
    setContributonStatusFilter(event.target.value)
    // apiDebounce(async () => {
    setFilterParams({
      ...filterParams,
      status: event.target.value
    })
    await getGroupContributions({
      ...filterParams,
      status: event.target.value
    })
    // })
  }

  const memberStatus = (
    isAdmin: boolean,
    isRecipient: boolean
  ): ReactElement => {
    if (isAdmin) {
      return (
        <Stack className='bg-light w-fit border border-secondary-xlight h-fit px-3 rounded-full text-black-2 font-semibold uppercase'>
          <p>Admin</p>
        </Stack>
      )
    }
    if (isRecipient) {
      return (
        <Stack
          direction='row'
          className='bg-primary-2xlight h-fit border border-primary-xlight px-2 sm:px-3 rounded-full font-semibold text-primary'
        >
          <span className='flex items-center gap-2'>
            <img alt='' src={RecipientIcon} /> {t('groups.recipient')}
          </span>
        </Stack>
      )
    } else {
      return <span></span>
    }
  }

  const tableData = (
    key: number,
    isAdmin: boolean,
    isRecipient: boolean,
    member: string,
    date: string,
    contributionStatus: string,
    amount: string
  ): React.ReactElement => {
    return (
      <React.Fragment key={key}>
        <TableRow key={`${key}-top-space`} className='!h-1'></TableRow>
        <TableRow
          key={`${key}-data`}
          className='!my-3 bg-white border border-secondary-xlight'
        >
          <TableCell className='w-5/12'>
            <Stack
              direction='row'
              className='items-center flex-wrap gap-4 font-extra'
            >
              {member}
              {memberStatus(isAdmin, isRecipient)}
            </Stack>
          </TableCell>
          <TableCell className='p text-base font-semibold'>{date}</TableCell>
          <TableCell className=''>
            {contributionStatus.toLocaleLowerCase() === 'paid' ? (
              <p className='flex items-center gap-2 font-semibold'>
                <img alt='' src={PaidIcon} />
                {contributionStatus}
              </p>
            ) : (
              <p className='flex items-center gap-2 font-semibold'>
                <img alt='' src={PendingIcon} />
                {contributionStatus}
              </p>
            )}
          </TableCell>
          <TableCell className='p font-semibold !text-base'>{amount}</TableCell>
        </TableRow>
        <TableRow key={`${key}-bottom-space`} className='!h-1'></TableRow>
      </React.Fragment>
    )
  }

  const getQueryParams = (
    paramsData: IFilterParams
  ): Record<string, string> => {
    const params: Record<string, string> = { ...paramsData }
    if (paramsData.from !== '') {
      params.from = convertStringToISODate(paramsData.from)
    } else {
      params.from = paramsData.from
    }

    if (paramsData.to !== '') {
      params.to = convertStringToISODate(paramsData.to)
    } else {
      params.to = paramsData.to
    }
    params.status = paramsData.status

    const filteredParams = Object.keys(params)
      .filter((key) => params[key as keyof IFilterParams] !== '')
      .reduce<Record<string, string>>((obj, key) => {
        obj[key] = params[key as keyof IFilterParams]
        return obj
      }, {})
    dispatch(
      setContriutionFilters({
        ...groupDetail,
        contributionFilters: filteredParams
      })
    )
    return filteredParams
  }

  const getGroupContributions = async (
    allParams: IFilterParams
  ): Promise<void> => {
    setIsLoading(true)
    const paramsData = getQueryParams(allParams)
    const searchParams = new URLSearchParams(paramsData)
    try {
      const response = await appGetRequest(
        `/groups/all-my-contributions/${
          params?.id as string
        }?${searchParams.toString()}`
      )
      dispatch(
        setGroupDetail({
          ...groupDetail,
          allMyContributions: response.data.data
        })
      )
      console.log('contribution: ', response)
    } catch (error) {
      console.error('Error: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    void getGroupContributions({ ...filterParams })
  }, [])
  return (
    <>
      <Stack
        direction='row'
        display='grid'
        className='w-full grid-cols-1 lg:grid-cols-3 gap-3 flex-wrap'
      >
        {/* <Stack direction='row' className='w-full items-center col-span-1'>
          <h6 className='text-black-medium'>
            {t('groups.contribution_deadline')}:{' '}
            <span className='text-primary h6'>August 1, 2023</span>
          </h6>
        </Stack> */}
        <Stack
          direction='row'
          className='w-fit items-center gap-3 col-span-2 mt-3 font-semibold flex-wrap md:mx-auto'
        >
          <p>{t('groups.filter')}</p>

          <Stack className=''>
            <Box sx={{ minWidth: 150 }}>
              <small>From:</small>
              <FormControl fullWidth>
                <input
                  type='date'
                  value={fromDateFilter}
                  placeholder='From'
                  onChange={handleFromDateFilter}
                  style={{ borderRadius: '6px', height: '45px' }}
                  className='bg-white'
                />
              </FormControl>
            </Box>
          </Stack>

          <Stack className=''>
            <Box sx={{ minWidth: 150 }}>
              <small>To:</small>
              <FormControl fullWidth>
                <input
                  type='date'
                  value={toDateFilter}
                  placeholder='To'
                  onChange={handlToDateFilter}
                  style={{ borderRadius: '6px', height: '45px' }}
                  className='bg-white'
                />
              </FormControl>
            </Box>
          </Stack>

          <Stack className=''>
            <Box sx={{ minWidth: 195 }}>
              <FormControl fullWidth>
                <InputLabel
                  id='contribution-status-select-label'
                  className='font-semibold text-th-black '
                  style={{ top: '-7px' }}
                >
                  {t('groups.contribution_status')}
                </InputLabel>
                <Select
                  labelId='contribution-status-select-label'
                  id='contribution-status-select'
                  value={contributonStatusFilter}
                  label='Contribution Status'
                  onChange={handleContributionStatusFilter}
                  style={{ borderRadius: '6px', height: '45px' }}
                  className='bg-white'
                >
                  <MenuItem value='' className='small'>
                    --
                  </MenuItem>
                  <MenuItem value='PAID' className=''>
                    PAID
                  </MenuItem>
                  <MenuItem value='PENDING' className=''>
                    PENDING
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          {/* <Stack direction='row' className='ml-auto items-center gap-5'>
            <p className='font-medium'>1 - 25 of 200</p>
            <Stack direction='row' className='gap-3'>
              <IconButton className='!p-0 !m-0'>
                <img alt='' src={NavLeft} />
              </IconButton>
              <IconButton className='!p-0 !m-0'>
                <img alt='' src={NavRight} />
              </IconButton>
            </Stack>
          </Stack> */}
        </Stack>
      </Stack>

      <br />
      {isLoading ? (
        <IsLoading />
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: '510px' }}>
            <TableHead
              sx={{
                border: '2px solid var(--primary-xlight)',
                backgroundColor: 'var(--primary-2xlight)'
              }}
            >
              <TableRow key='table-row-id' className='uppercase'>
                <TableCell
                  sx={{ borderRight: '2px solid var(--primary-xlight)' }}
                  className='w-5/12 font-extra'
                >
                  {t('groups.member')}
                </TableCell>
                <TableCell
                  sx={{ borderRight: '2px solid var(--primary-xlight)' }}
                  className='font-extra border-r-2'
                >
                  {t('groups.date')}
                </TableCell>
                <TableCell
                  sx={{ borderRight: '2px solid var(--primary-xlight)' }}
                  className='font-extra border-r-2'
                >
                  {t('groups.contribution_status')}
                </TableCell>
                <TableCell className='font-extra'>
                  {t('groups.amount')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className='!h-1'></TableRow>

              {groupDetail.allMyContributions.map(
                (contribution: any, index: number) =>
                  tableData(
                    index,
                    false,
                    false,
                    contribution.groupName,
                    formatToDateAndTimeString(contribution.date),
                    // toTitleCase(contribution.status),
                    contribution?.status,
                    `${groupDetail.group.currency as string} ${
                      contribution.amount as string
                    }`
                  )
              )}
              {/* {tableData(false, true, 'Eyong Jacob', 'Nov 8, 2023, 23:16:08', 'Paid', 'XAF 50,000')}
                        {tableData(false, false, 'Ngwa Pretuce', 'Jan 21, 2023, 14:10:34', 'Pending', 'XAF 50,000')}
                        {tableData(false, false, 'Tse Kan-Lamar', 'Oct 12, 2023, 08:09:03', 'Paid', 'XAF 50,000')}
                        {tableData(false, false, 'Ashu Lanner', 'Aug 1, 2023, 13:13:22', 'Paid', 'XAF 50,000')}
                        {tableData(false, false, 'Eyong Jacob', 'Nov 8, 2023, 23:16:08', 'Paid', 'XAF 50,000')}
                        {tableData(false, false, 'Ngwa Pretuce', 'Jan 21, 2023, 14:10:34', 'Pending', 'XAF 50,000')}
                        {tableData(false, false, 'Tse Kan-Lamar', 'Oct 12, 2023, 08:09:03', 'Paid', 'XAF 50,000')}
                        {tableData(true, false, 'Ashu Lanner', 'Aug 1, 2023, 13:13:22', 'Paid', 'XAF 50,000')}
                        {tableData(false, false, 'Eyong Jacob', 'Nov 8, 2023, 23:16:08', 'Pending', 'XAF 50,000')}
                        {tableData(false, false, 'Ngwa Pretuce', 'Jan 21, 2023, 14:10:34', 'Paid', 'XAF 50,000')}
                        {tableData(false, false, 'Tse Kan-Lamar', 'Oct 12, 2023, 08:09:03', 'Paid', 'XAF 50,000')} */}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
