import { Stack } from '@mui/material'
import '../../../styles/registration_box.css'
import LogoWhite from '../../../images/registration_images/njangee-logo-white.png'
import React, { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useAppDispatch, useAppSelector } from '../../../redux/app/create_njangee_store/hooks'
import { createLoggedInUserSelector, setLoggedInUser } from '../../amplify/slices/loggedInUserInfo'

interface IProps {
    children: any
}
export const OnboardingSetupBox: React.FC<IProps> = ({ children }) => {
    const loggedInUser = useAppSelector(createLoggedInUserSelector)
    const dispatch = useAppDispatch()
    useEffect(() => {
        Auth.currentAuthenticatedUser()
          .then(res => {
            const attributes = res.attributes
            dispatch(setLoggedInUser({
              ...loggedInUser,
              id: attributes.sub,
              firstName: attributes.given_name,
              lastName: attributes.family_name,
              email: attributes.email
            }))
            localStorage.setItem('njangee_jwt_token', res.signInUserSession.idToken.jwtToken)
          }).catch(() => {
            localStorage.removeItem('njangee_jwt_token')
          })
      }, [])
    return (
        <Stack className='h-full w-full registration-box overflow-y-scroll'>

            <Stack className='m-auto w-full gap-4'>
                <br />
                <img src={LogoWhite} alt='' className='mt-auto mx-auto' />
                <Stack
                    width={{
                        xs: '95%',
                        sm: '80%',
                        md: '70%',
                        lg: '60%'
                    }}
                    className='onboarding-content-box mx-auto'>
                    {children}
                </Stack>
            </Stack>
            <br />
        </Stack>
    )
}
