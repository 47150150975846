import React from 'react'
import { Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import '../../../styles/user_dashboard.css'
import ColoredLogo from '../../../images/logo/njangee-Logo-icon-coloured.png'
import FaceBookIcon from '../../../images/icon_images/icon-footer-social-fb.svg'
import TwitterIcon from '../../../images/icon_images/icon-social-x.svg'
import YoutubeIcon from '../../../images/icon_images/icon-footer-social-youtube.svg'
import LinkedInIcon from '../../../images/icon_images/icon-footer-social-linkedin.svg'
export const DashBoardFooter: React.FC = () => {
    const { t } = useTranslation(['dashboard'])
    return (
        <div className='static bottom-0 w-full main-footer mt-2'>
            <Stack direction='column' className='px-4 md:px-12 py-6 text-secondary'>
                <Stack direction={{
                    xs: 'column-reverse',
                    md: 'row'
                }}
                    alignItems='center'
                    className='py-6 flex-wrap gap-4'>
                    <Stack direction='row' className='gap-6 flex-wrap !items-center justify-center'>
                        <Link to='/contact'>{t('footer.faqs')}</Link>
                        <Link to='/contact'>{t('footer.help_n_support')}</Link>
                        <Link to='/contact'>{t('footer.contact_us')}</Link>
                        <Link to='/privacy-policy'>{t('footer.privacy_notice')}</Link>
                        <Link to='/terms-of-service'>{t('footer.terms_n_conditions')} </Link>
                    </Stack>
                    <Stack direction='row' ml={{
                        xs: '0px',
                        md: 'auto'
                    }}
                        pb={{
                            md: '0px',
                            xs: '20px'
                        }}
                        alignItems={{
                            xs: 'center',
                            md: 'end'
                        }}
                        className='!items-center gap-3'>
                        <p>{t('footer.follow_us')}:</p>
                        <Stack direction='row' className='gap-2 md:gap-4 social-icons'>
                            <Link to='https://www.facebook.com/njangee' target='blank'><img alt='' src={FaceBookIcon} /></Link>
                            <Link to='https://x.com/Njangee182086' target='blank'><span className='icon-style'><img alt='' className='w-5' src={TwitterIcon} /></span></Link>
                            <Link to='https://www.youtube.com/@njangee' target='blank'><img alt='' src={YoutubeIcon} /></Link>
                            <Link to='https://www.linkedin.com/company/njangee' target='blank'><span className='icon-style'><img alt='' src={LinkedInIcon} /></span></Link>
                        </Stack>
                    </Stack>
                </Stack>

                <hr style={{ borderWidth: '0.12rem', borderColor: 'var(--secondary-xlight)' }} />
                <Stack direction='row' justifyContent={{ xs: 'center', md: 'normal' }} className='py-6 items-center text-th-black gap-4 flex-wrap'>
                    <Stack direction='row' className='gap-3 mg:gap-8 items-center'>
                        <img alt='' src={ColoredLogo} />
                        <small>{t('footer.footer_conclusion')}</small>
                    </Stack>
                    <Stack
                        ml={{ xs: '0px', md: 'auto' }}
                        mt={{ xs: '20px', md: '0' }}
                        className=''>
                        <small>Copyright. &copy; 2023 {t('footer.copyright')}</small>
                    </Stack>
                </Stack>
            </Stack>
        </div>
    )
}
