import React, { type ReactElement } from 'react'
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CompletePaymentIcon from '../../../../images/icon_images/icon-check-green.svg'
import FailedPaymentIcon from '../../../../images/icon_images/icon-failed-circle.svg'
import PendingPaymentIcon from '../../../../images/icon_images/icon-hourglass-time-info.svg'
import NoNgangeeImg from '../../../../images/dashboard_images/no_njangee_img.png'

import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { createGetPaymentsSelector } from '../slices/paymentsSlice'
import { formatToDateAndTimeString } from '../../../common/helper/convertToDate'
import { getPaymentMethod } from '../function/functions'

export const InOutGoingPayments: React.FC = () => {
  const { t } = useTranslation(['dashboard'])
  const paymentsData = useAppSelector(createGetPaymentsSelector)
  const navigate = useNavigate()

  const showPaymentDetails = (id: string): void => {
    navigate(`/user-payments/${id}/show`)
  }

  const paymentStatusFunc = (paymentStatus: string): ReactElement => {
    if (paymentStatus.toLowerCase() === 'successful') {
      return (
        <Stack
          direction='row'
          className='w-fit flex items-center justify-center py-1 px-4 rounded-full border border-success bg-primary-3xlight text-success gap-2'
        >
          <img src={CompletePaymentIcon} alt='' />
          <p>{t('payments.complete')}</p>
        </Stack>
      )
    } else if (paymentStatus.toLowerCase() === 'failed') {
      return (
        <Stack
          direction='row'
          className='w-fit flex items-center justify-center py-1 px-4 rounded-full border border-danger bg-pink-xlight text-danger gap-2'
        >
          <img src={FailedPaymentIcon} alt='' />
          <p>{t('payments.failed')}</p>
        </Stack>
      )
    } else {
      return (
        <Stack
          direction='row'
          className='w-fit flex items-center justify-center py-1 px-4 rounded-full border border-info bg-pink-xlight text-info gap-2'
        >
          <img src={PendingPaymentIcon} alt='' />
          <p>{t('payments.pending')}</p>
        </Stack>
      )
    }
  }

  const paymentData = (
    id: string,
    amount: string,
    group: string,
    date: string,
    paymentMethod: string,
    metaData: string,
    paymentStatus: string
  ): ReactElement => {
    return (
      <React.Fragment key={id}>
        <TableRow key={`${id}-top-space`} className='!h-1'></TableRow>
        <TableRow
          key={`${id}-data`}
          className='!my-3 bg-white border border-secondary-xlight cursor-pointer'
          onClick={() => {
            showPaymentDetails(id)
          }}
        >
          <TableCell className='!text-base font-semibold'>{amount}</TableCell>
          <TableCell className='!text-base font-semibold'>{group}</TableCell>
          <TableCell className='!text-base '>{date} </TableCell>
          <TableCell>
            <Stack
              direction='row'
              className='font-semibold !text-base flex items-center gap-3'
            >
              {getPaymentMethod(paymentMethod, metaData)}
            </Stack>
          </TableCell>
          <TableCell className='font-semibold !text-base'>
            {paymentStatusFunc(paymentStatus)}
          </TableCell>
        </TableRow>
        <TableRow key={`${id}-bottom-space`} className='!h-1'></TableRow>
      </React.Fragment>
    )
  }

  return (
    <>
      {paymentsData.payments.length > 0 ? (
        <TableContainer>
          <Table sx={{ minWidth: '510px' }}>
            <TableHead
              key='payment-head-id'
              sx={{ backgroundColor: 'var(--primary-2xlight)' }}
            >
              <TableRow key='payment-header-row-id' className='uppercase'>
                <TableCell key='1' className='font-extra'>
                  {t('general.common.amount')}
                </TableCell>
                <TableCell key='2' className='font-extra'>
                  {t('general.common.njangee_group')}
                </TableCell>
                <TableCell key='3' className='font-extra'>
                  {t('general.common.date')}
                </TableCell>
                <TableCell key='4' className='font-extra'>
                  {t('general.common.payment_methods')}
                </TableCell>
                <TableCell key='5' className='font-extra'>
                  {t('payments.payment_status')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody key='payment-body-id'>
              <TableRow key='first-data-row-id' className='h-1'></TableRow>
              {paymentsData.payments.map((payment: any) => {
                return paymentData(
                  payment.id,
                  `${payment.group.currency as string} ${
                    payment.amount as string
                  }`,
                  payment.group.name as string,
                  formatToDateAndTimeString(payment.createdAt),
                  payment.paymentGateway.name,
                  payment.transactionMetaData,
                  payment.status
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Stack
          direction='column'
          className='min-h-[50vh] items-center justify-center w-full bg-primary-5xlight py-10'
        >
          <Stack>
            <img
              src={NoNgangeeImg}
              className='w-32 md:w-56 lg:w-72 mx-auto mb-6'
              alt=''
            />
            <h4 className='text-center'>
              {t('groups.no_njangee_payment_made')}
            </h4>
          </Stack>
          <Link
            to='/user-groups/play-njangee'
            // onClick={onClickShowModal}
            className='btn btn-primary mt-8 bg-primary-2xlight'
          >
            {t('general.common.play_njangee')}
          </Link>
        </Stack>
      )}
    </>
  )
}
