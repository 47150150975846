import React, { useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { WebLayout } from './layouts.tsx/app'
import Masonry from '@mui/lab/Masonry'
import HeaderBg from '../../images/web_images/img-news-pageHeader-bg@2x.png'
import SignUpImg from '../../images/web_images/img-howItWorks-screen-1@2x.png'
import SetUpGroupImg from '../../images/web_images/img-howItWorks-screen-2@2x.png'
import InvitePeopleImg from '../../images/web_images/img-howItWorks-screen-3@2x.png'
import PlayAndChopImg from '../../images/web_images/img-howItWorks-screen-4@2x.png'
import PhoneIcon from '../../images/icon_images/icon-phone-2.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const HowItWorks: React.FC = () => {
  const { t } = useTranslation(['web'])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <WebLayout>
      <Stack
        className='w-full pt-10 bg-white'
        style={{
          backgroundImage: `url(${HeaderBg})`,
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          minHeight: '400px'
        }}
      >
        <Stack className='text-center mx-auto px-5 gap-5'>
          <h2 className='text-primary'>{t('how_it_works.title')}</h2>
          <br />
          <br className='hidden sm:block' />
          <h3 className='leading-tight max-w-[500px]'>
            {t('how_it_works.sub_title')}
          </h3>
          <Link to='/register' className='btn btn-primary mx-auto'>
            {t('home.get_started')}
          </Link>
          <br />
          <br />
        </Stack>

        <Stack className='w-full'>
          <Box
            sx={{ width: '100%', minHeight: '400px' }}
            className='pb-10 bg-primary-2xlight border-b border-primary-light'
          >
            <Masonry
              columns={{
                xs: 1,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2
              }}
              spacing={0}
              rev=''
            >
              <Stack
                className='w-full bg-primary-2xlight '
                sx={{ minHeight: '400px' }}
              >
                <Stack className='w-full py-20 px-5 md:px-20 lg:px-32'>
                  <h1 className='text-[130px] md:text-[250px] leading-none text-primary-xlight'>
                    1
                  </h1>
                  <h1 className='-mt-5 leading-tight text-[47px] md:text-[57.22px]'>
                    {t('how_it_works.create_your_njangee_account')}
                  </h1>
                </Stack>
              </Stack>

              <Stack className='w-full bg-primary' sx={{ minHeight: '500px' }}>
                <Stack
                  className=''
                  sx={{ maxHeight: '500px', maxWidth: '1000px' }}
                >
                  <img
                    src={SignUpImg}
                    alt=''
                    className='sm:-ml-40 !h-full !w-full'
                  />
                </Stack>
                <Stack className='w-full pb-10 px-5 md:px-20 mt-auto gap-4'>
                  <p className='text-white text-lg'>
                    {t('how_it_works.create_your_njangee_account_caption')}
                  </p>
                  <Link to='/' className='btn btn-secondary'>
                    Create your account
                  </Link>
                </Stack>
              </Stack>

              <Stack className='w-full bg-primary ' sx={{ minHeight: '400px' }}>
                <Stack className='w-full py-20 px-5 md:px-20 lg:px-32'>
                  <h1 className='text-[130px] md:text-[250px] leading-none text-primary-xlight'>
                    2
                  </h1>
                  <h1 className='-mt-5 leading-tight text-[47px] md:text-[57.22px] text-primary-2xlight'>
                    {t('how_it_works.create_and_setup_group')}
                  </h1>
                </Stack>
              </Stack>

              <Stack
                className='w-full bg-primary-2xlight'
                sx={{ minHeight: '500px' }}
              >
                <Stack
                  className=''
                  sx={{ maxHeight: '500px', maxWidth: '1000px' }}
                >
                  <img
                    src={SetUpGroupImg}
                    alt=''
                    className='sm:-ml-40 !h-full !w-full'
                  />
                </Stack>
                <Stack className='w-full pb-10 px-5 md:px-20 mt-auto gap-4'>
                  <p className='text-black text-lg'>
                    {t('how_it_works.create_and_setup_group_caption')}
                  </p>
                </Stack>
              </Stack>

              <Stack
                className='w-full bg-primary-2xlight '
                sx={{ minHeight: '400px' }}
              >
                <Stack className='w-full py-20 px-5 md:px-20 lg:px-32'>
                  <h1 className='text-[130px] md:text-[250px] leading-none text-primary-xlight'>
                    3
                  </h1>
                  <h1 className='-mt-5 leading-tight text-[47px] md:text-[57.22px]'>
                    {t('how_it_works.invite_people')}
                  </h1>
                </Stack>
              </Stack>

              <Stack className='w-full bg-primary' sx={{ minHeight: '500px' }}>
                <Stack
                  className=''
                  sx={{ maxHeight: '500px', maxWidth: '1000px' }}
                >
                  <img
                    src={InvitePeopleImg}
                    alt=''
                    className='sm:-ml-40 !h-full !w-full'
                  />
                </Stack>
                <Stack className='w-full pb-10 px-5 md:px-20 mt-auto gap-4'>
                  <p className='text-white text-lg'>
                    {t('how_it_works.invite_people_caption')}
                  </p>
                </Stack>
              </Stack>

              <Stack className='w-full bg-primary ' sx={{ minHeight: '400px' }}>
                <Stack className='w-full py-20 px-5 md:px-20 lg:px-32'>
                  <h1 className='text-[130px] md:text-[250px] leading-none text-primary-xlight'>
                    4
                  </h1>
                  <h1 className='-mt-5 leading-tight text-[47px] md:text-[57.22px] text-primary-2xlight'>
                    {t('how_it_works.take_turns')}
                  </h1>
                </Stack>
              </Stack>

              <Stack
                className='w-full bg-primary-2xlight'
                sx={{ minHeight: '500px' }}
              >
                <Stack
                  className=''
                  sx={{ maxHeight: '500px', maxWidth: '1000px' }}
                >
                  <img
                    src={PlayAndChopImg}
                    alt=''
                    className='sm:-ml-40 !h-full !w-full'
                  />
                </Stack>
                <Stack className='w-full pb-10 px-5 md:px-20 mt-auto gap-4'>
                  <p className='text-black text-lg'>
                    {t('how_it_works.take_turns_caption')}
                  </p>
                </Stack>
              </Stack>
            </Masonry>
          </Box>
        </Stack>

        <Stack className='w-full bg-primary-2xlight'>
          <Stack className='container'>
            <Stack
              direction='row'
              className='w-full flex-wrap items-center py-16 gap-5 px-5 md:px-16 lg:px-28'
            >
              <Stack className='gap-3'>
                <h1 className='text-primary-bold'>
                  {' '}
                  {t('how_it_works.questions')} ?
                </h1>
                <p className='max-w-[500px]'>
                  {t('how_it_works.questions_caption')}
                </p>
                <h5 className='text-primary mt-2 flex items-center gap-3'>
                  <img src={PhoneIcon} alt='' /> +237 6 77 54 59 07
                </h5>
              </Stack>

              <Link to='/contact' className='btn btn-secondary !h-fit ml-auto'>
                {t('how_it_works.contact_us_today')}
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </WebLayout>
  )
}
