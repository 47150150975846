import React, { type ReactElement } from 'react'

export const getFileTypeAndDisplay = (
  fileUrl: string,
  file: Blob | MediaSource,
  docType: string
): ReactElement => {
  const fileExtension =
    file instanceof Blob ? file.name.split('.').pop()?.toLowerCase() : ''
  console.log(fileExtension)
  const classes =
    docType.toUpperCase() === 'PASSPORT'
      ? '!w-28 !h-44 sm:!w-full sm:!h-full'
      : '!w-44 !h-28 sm:w-full sm:h-full'
  if (fileExtension === 'pdf') {
    return (
      <embed
        src={fileUrl}
        type='application/pdf'
        width='100%'
        height='100%'
        className={classes}
      />
    )
  } else {
    return <img src={fileUrl} alt='ID image' className={classes} />
  }
}

const extractFileExtension = (url: string): string | undefined => {
  const path = url.split('?')[0] // Remove query parameters
  const parts = path.split('/')
  const fileName = parts[parts.length - 1]
  return fileName.split('.').pop()?.toLowerCase()
}
export const getFileUrlTypeAndDisplay = (
  fileUrl: string,
  docType: string
): ReactElement => {
  const fileExtension = extractFileExtension(fileUrl)
  const classes =
    docType.toUpperCase() === 'PASSPORT'
      ? 'sm:!w-full sm:!h-full'
      : 'sm:w-full sm:h-full'
  if (fileExtension === 'pdf') {
    return (
      <embed
        src={fileUrl}
        type='application/pdf'
        width='100%'
        height='100%'
        className={classes}
      />
    )
  } else {
    return <img src={fileUrl} alt='ID image' className={classes} />
  }
}

export function isNullOrEmpty (value: string | null | undefined): boolean {
  return value === null || value === undefined || value.trim() === ''
}
