import { Auth } from 'aws-amplify'
import axios from 'axios'

const api = process.env.REACT_APP_API as string
export const inviteGroupMembersFunc = async (
  members: any[],
  groupId: string
): Promise<any> => {
  console.log(groupId)
  const newMembers = members.map((member) => {
    return {
      name: member?.name,
      email: member?.type === 'email' ? member?.contact : '',
      mobile: member?.type !== 'email' ? member?.contact : '',
      type: 'GROUP',
      groupId
    }
  })
  const encodedMembers = JSON.stringify(newMembers)
  try {
    const user = await Auth.currentAuthenticatedUser()
    const jwtToken = user.signInUserSession.idToken.jwtToken
    const session = await axios.get(`${api}/session`, {
      headers: {
        Authorization: jwtToken
      }
    })
    const userId = session.data.data.id
    await axios.post(
      `${api}/users/${userId as string}/invitations`,
      { invitations: encodedMembers },
      {
        headers: {
          Authorization: jwtToken
        }
      }
    )
  } catch (error) {
    console.error('Error inviting user: ', error)
  }
}
