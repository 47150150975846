import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Stack } from '@mui/material'
import '../../../styles/user_dashboard.css'
import { useTranslation } from 'react-i18next'
import { TopRowBoxes } from './dashboard_components/topRowBoxes'
import { NjangiGroups } from './dashboard_components/njangiGroups'
import ReminderCalenderWhiteIcon from '../../../images/icon_images/icon-reminder-calender.svg'
import { Invitations } from './dashboard_components/invitations'
import { UserDashBoardLayout } from '../common/dashboardUiLayout'
import {
  showModalF,
  showModalSelector
} from '../njangeeGroupModals/slices/showModalSlice'
import { Info } from '@mui/icons-material'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import { createLoggedInUserSelector } from '../../amplify/slices/loggedInUserInfo'
import { appGetRequest } from '../../common/helper/httpRequests'
import { createUserAuthSelector } from '../../amplify/slices/authSlices'
import { convertToMonthInWords } from '../../common/helper/convertToDate'
import { createGetCreatedGroupsSelector } from './slices/createdGroupsSlice'

export const Dashboard: React.FC = () => {
  const [kycDone, setKycDone] = useState(true)
  const userData = useAppSelector(createUserAuthSelector).user
  const { t } = useTranslation(['dashboard'])
  const loggedInUser = useAppSelector(createLoggedInUserSelector)
  const showModalState = useAppSelector(showModalSelector)
  const createdGroupsData = useAppSelector(createGetCreatedGroupsSelector)

  const language = localStorage.getItem('i18nextLng')

  const checkKYC = async (): Promise<void> => {
    const kyc = await appGetRequest(
      `/kyc-records/user/${userData.id as string}`
    )
    if (kyc.status === 200) {
      setKycDone(true)
    } else {
      setKycDone(false)
    }
  }

  const dispatchModal = useAppDispatch()
  const onClickShowModal = (): void => {
    dispatchModal(showModalF({ ...showModalState, showModal: true }))
  }

  useEffect(() => {
    void checkKYC()
  }, [])
  return (
    <div className='user-dashboard'>
      <UserDashBoardLayout>
        <Stack
          direction='column'
          width='100%'
          className='px-4 md:px-12 py-4 w-full'
        >
          <Stack
            direction='row'
            className='w-full flex-wrap md:flex-nowrap gap-4 md:gap-20'
            alignItems='center'
          >
            <h4 className='border-0 border-b-w-1half pb-2 w-full border-main'>
              {t('dashboard.welcome')} {loggedInUser.firstName}!
            </h4>
            <button
              onClick={onClickShowModal}
              className='btn btn-primary w-full mx-auto md:ml-auto '
            >
              {t('dashboard.create_new_njangi_group')}
            </button>
          </Stack>

          <TopRowBoxes />

          <div className='grid grid-cols-1 lg:grid-cols-7 gap-4 mt-4 pt-5 md:pt-0'>
            <Stack className='col-span-4 gap-4'>
              {!kycDone && (
                <Stack
                  direction='column'
                  className='border border-primary-2xlight bg-primary-3xlight rounded-lg w-full py-4 px-6'
                >
                  <h6 className='flex items-center gap-3'>
                    <Info className='text-primary' />{' '}
                    {t('dashboard.identity_not_verified')}
                  </h6>
                  <Stack
                    direction='row'
                    className='items-center gap-4 flex-wrap sm:flex-nowrap'
                  >
                    <Stack className='w-full sm:w-9/12 lg:w-8/12 xl:w-9/12'>
                      <p>{t('dashboard.identity_not_verified_caption')}</p>
                    </Stack>
                    <Stack className='ml-auto'>
                      <Link
                        to='/user-settings?identity-verification'
                        className='btn btn-primary ml-auto whitespace-nowrap'
                      >
                        {t('dashboard.verify_now')}
                      </Link>
                    </Stack>
                  </Stack>
                </Stack>
              )}
              <NjangiGroups />
            </Stack>

            <Stack direction='column' className='col-span-3 pt-5 md:pt-0'>
              {(createdGroupsData?.meta?.upcomingContribution !== null &&
                !createdGroupsData?.isLoading) && (
                <Stack direction='row' className='reminder-box'>
                  <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                    className='h-full w-3/12 px-3 text-center bg-white bg-opacity-20'
                  >
                    <img alt='' src={ReminderCalenderWhiteIcon} />
                    <small className='font-semibold'>
                      {convertToMonthInWords(
                        language ?? '',
                        createdGroupsData?.meta?.upcomingContribution
                          ?.nextContribution?.date
                      )}
                    </small>
                  </Stack>
                  <Stack
                    direction='row'
                    alignItems='start'
                    className='flex-wrap w-full px-3 py-2'
                  >
                    <Stack direction='column' className=''>
                      <Stack
                        direction='row'
                        className='w-full flex-wrap text-pink-xlight font-medium'
                      >
                        <p className='uppercase'>
                          {t('groups.upcoming_contribution')}
                        </p>
                      </Stack>
                      <Stack className='mt-8 mb-2'>
                        <h4 className='text-th-white leading-4 mb-2'>
                          {
                            createdGroupsData?.meta?.upcomingContribution
                              ?.currency
                          }{' '}
                          {
                            createdGroupsData?.meta?.upcomingContribution
                              ?.nextContribution?.amount
                          }
                        </h4>
                        <small className='text-pink-xlight mt-0'>
                          {
                            createdGroupsData?.meta?.upcomingContribution
                              ?.groupName
                          }
                        </small>
                      </Stack>
                    </Stack>
                    {/* <button onClick={handleOpenReminder} className='ml-auto w-auto flex border-0 bg-none items-center gap-2 text-13 letter-spacing-1 hover:opacity-80'><img alt='' src={EditIcon} className='h-3 w-3' /> {t('general.common.edit_reminder')}</button>
                                    <EditReminder open={openReminder} handleClose={handleCloseReminder} /> */}
                  </Stack>
                </Stack>
              )}

              <Stack className='Invitations'>
                <Invitations />
              </Stack>
            </Stack>
          </div>
        </Stack>
      </UserDashBoardLayout>
    </div>
  )
}
