import React from 'react'
import { Stack } from '@mui/material'
import Logo from '../../../images/logo/njamgee-Logo.png'
import { useTranslation } from 'react-i18next'
interface IModalValue {
    nextModal: (arg: number) => void
}

export const WelcomeModal: React.FC<IModalValue> = ({ nextModal }) => {
    const { t } = useTranslation(['dashboard'])
    return (
        <div>
            <Stack className='w-full justify-center px-4'>
                <Stack direction='row' className='w-full justify-center mt-5'>
                    <img alt='' src={Logo} className='w-fit h-fit' />
                </Stack>
                <Stack direction='column' className='w-full items-center px-5 sm:px-10 md:px-16 lg:px-20 text-center'>
                    <h5 className=''>{t('main_modal.welcome_to_account')}</h5>
                    <p className=''>{t('main_modal.we_will_assist_you')}</p>
                    <button onClick={() => { nextModal(1) }} className='btn btn-primary mt-12'>
                        {t('main_modal.get_started')}
                    </button>
                    <small className='text-black-2 mt-24'>
                        {t('main_modal.get_started_bottom_caption')}
                    </small>
                </Stack>
            </Stack>
        </div>
    )
}
