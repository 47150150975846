import React from 'react'
import { Stack } from '@mui/material'
import { WebLayout } from './layouts.tsx/app'
import HeaderBg from '../../images/web_images/img-news-pageHeader-bg@2x.png'
import Quincy from '../../images/web_images/quincy.jpeg'
import Soliter from '../../images/web_images/soliter.jpeg'
import Joyce from '../../images/web_images/joyce.jpeg'
import Jamin from '../../images/web_images/jamin.jpeg'
import Patrick from '../../images/web_images/patrick.jpeg'
import CTABg from '../../images/web_images/img-team-cta-bg@2x.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Team: React.FC = () => {
  const { t } = useTranslation(['web'])
  return (
    <WebLayout>
      <Stack className='w-full'>
        <Stack
          className='w-full h-full bg-white'
          style={{
            backgroundImage: `url(${HeaderBg})`,
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            minHeight: '300px'
          }}
        >
          <Stack className='w-full px-5 py-20'>
            <Stack className='text-center mx-auto gap-5'>
              <h2 className='text-primary'>{t('team.our_team')}</h2>
            </Stack>
          </Stack>

          <Stack className='container'>
            <Stack
              direction='row'
              display='grid'
              className='w-full grid-col-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-10 px-0 lg:px-20 xl:px-40'
            >
              <Stack className='bg-white p-5 shadow-2xl shadow-[--primary-xlight] rounded-md gap-5'>
                <Stack
                  className='w-full h-80 md:h-72 rounded-md'
                  style={{
                    backgroundImage: `url(${Quincy})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                ></Stack>
                <Stack className='text-center'>
                  <h6>Quincy Kwende</h6>
                  <p className='text-secondary'>
                    {t('team.ceo_interim_head_of_engineering')}
                  </p>
                </Stack>
              </Stack>
              <Stack className='bg-white p-5 shadow-2xl shadow-[--primary-xlight] rounded-md gap-5'>
                <Stack
                  className='w-full h-80 md:h-72 rounded-md'
                  style={{
                    backgroundImage: `url(${Soliter})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                ></Stack>
                <Stack className='text-center'>
                  <h6>Soliter Melonde</h6>
                  <p className='text-secondary'>
                    {' '}
                    {t('team.chief_communication_officer')}
                  </p>
                </Stack>
              </Stack>
              <Stack className='bg-white p-5 shadow-2xl shadow-[--primary-xlight] rounded-md gap-5'>
                <Stack
                  className='w-full h-80 md:h-72 rounded-md'
                  style={{
                    backgroundImage: `url(${Joyce})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                ></Stack>
                <Stack className='text-center'>
                  <h6>Margrete Jamin Forchi</h6>
                  <p className='text-secondary'>
                    {' '}
                    {t('team.chief_community_manager')}
                  </p>
                </Stack>
              </Stack>
              <Stack className='bg-white p-5 shadow-2xl shadow-[--primary-xlight] rounded-md gap-5'>
                <Stack
                  className='w-full h-80 md:h-72 rounded-md'
                  style={{
                    backgroundImage: `url(${Jamin})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                ></Stack>
                <Stack className='text-center'>
                  <h6>Joyce Mukwelle BONYEKI</h6>
                  <p className='text-secondary'>
                    {t('team.coo_interim_head_of_finance')}
                  </p>
                </Stack>
              </Stack>
              <Stack className='bg-white p-5 shadow-2xl shadow-[--primary-xlight] rounded-md gap-5'>
                <Stack
                  className='w-full h-80 md:h-72 rounded-md'
                  style={{
                    backgroundImage: `url(${Patrick})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                ></Stack>
                <Stack className='text-center'>
                  <h6>Patrick Oluwaseun Akinde</h6>
                  <p className='text-secondary'>
                    {t('team.chief_production_officer')}
                  </p>
                </Stack>
              </Stack>
              {/* <Stack className='bg-white p-5 shadow-2xl shadow-[--primary-xlight] rounded-md gap-5'>
                                <Stack className='w-full h-80 md:h-72 rounded-md' style={{
                                    backgroundImage: `url(${TeamMember6})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}></Stack>
                                <Stack className='text-center'>
                                    <h6>Gerda Jakubowski PhD</h6>
                                    <p className='text-secondary'>Internal Markets Manager</p>
                                </Stack>
                            </Stack> */}
            </Stack>
          </Stack>

          <Stack
            className='w-full mt-20'
            style={{
              backgroundImage: `url(${CTABg})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              minHeight: '350px'
            }}
          >
            <Stack
              direction='row'
              className='items-center flex-wrap my-auto mx-auto px-5 gap-5 md:gap-8 lg:gap-14'
            >
              <h1 className='text-primary-bold text-[36.62px] md:text-[57.22px]'>
                {t('team.want_to_talk_to_an_expert')}?
              </h1>
              <Link to='/contact' className='btn btn-secondary !h-fit'>
                {t('team.get_in_touch')}
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </WebLayout>
  )
}
