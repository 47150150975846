import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface IdVerification {
    id: number
    document_type: string
    front_id: Blob | MediaSource
    back_id: Blob | MediaSource
    passport: Blob | MediaSource
    frontIdUrl: string
    idNumber: string
    backIdUrl: string
    expiration_date: string
    issued_date: string
    status: string
    submitted: boolean
    reviews: []
}

const initialState: IdVerification = {
    id: 0,
    document_type: 'ID',
    front_id: new Blob(),
    back_id: new Blob(),
    passport: new Blob(),
    frontIdUrl: '',
    backIdUrl: '',
    idNumber: '',
    expiration_date: '',
    issued_date: '',
    status: '',
    submitted: false,
    reviews: []
}

export const createIdVerificationSlice = createSlice({
    name: 'id_verification',
    initialState,
    reducers: {
        setIdVerification: (state, action: PayloadAction<IdVerification>) => {
            return {
                ...state,
                id: action.payload.id,
                document_type: action.payload.document_type,
                front_id: action.payload.front_id,
                back_id: action.payload.back_id,
                passport: action.payload.passport,
                frontIdUrl: action.payload.frontIdUrl,
                backIdUrl: action.payload.backIdUrl,
                idNumber: action.payload.idNumber,
                expiration_date: action.payload.expiration_date,
                issued_date: action.payload.issued_date,
                status: action.payload.status,
                submitted: action.payload.submitted,
                reviews: action.payload.reviews
            }
        }
    }
})

export const { setIdVerification } = createIdVerificationSlice.actions

export const createIdVerificationSelector = (state: RootState): IdVerification => state.createIdVerificationReducer
export default createIdVerificationSlice.reducer
