import { I18n } from 'aws-amplify'
import { translations } from '@aws-amplify/ui-react'
I18n.putVocabularies(translations)

I18n.putVocabulariesForLanguage('en', {
    'Create Account': 'Sign Up',
    'Sign In': 'Log In'

  })

I18n.putVocabularies({
  fr: {
    'Sign In': 'Se connecter',
    'Sign Up': "S'inscrire"
  }
})

export default I18n
