import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../redux/app/create_njangee_store/store'
export interface LoggedInUser {
    id: string
    firstName: string
    lastName: string
    email: string
}

const initialState: LoggedInUser = {
    id: '',
    firstName: '',
    lastName: '',
    email: ''
}

export const createLoggedInUserSlice = createSlice({
    name: 'change_password',
    initialState,
    reducers: {
        setLoggedInUser: (state, action: PayloadAction<LoggedInUser>) => {
            return {
                ...state,
                id: action.payload.id,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email
            }
        }
    }
})

export const { setLoggedInUser } = createLoggedInUserSlice.actions

export const createLoggedInUserSelector = (state: RootState): LoggedInUser => state.createLoggedInUserReducer
export default createLoggedInUserSlice.reducer
