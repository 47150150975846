import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DocumentSetup } from './documentSetup'
import { HomeAddressSetUp } from './homeAddressSetUp'
import { useAppDispatch, useAppSelector } from '../../../redux/app/create_njangee_store/hooks'
import { showModalF, showModalSelector } from '../../user/njangeeGroupModals/slices/showModalSlice'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { IsLoading } from '../fragments/isLoading'

export const OnboardingSteps: React.FC = () => {
    const [onboardingStep, setOnboardingStep] = useState(1)
    const showModalState = useAppSelector(showModalSelector)
    const [loadingKYC, setLoadingKYC] = useState(true)
    const nextStep = (onboardingNumber: number): void => {
        setOnboardingStep(onboardingNumber)
    }
    const navigate = useNavigate()
    const dispatchModal = useAppDispatch()

    const onClickShowModal = (): void => {
        dispatchModal(showModalF({ ...showModalState, showModal: true }))
    }
    const skipOnboarding = (): void => {
        onClickShowModal()
        navigate('/user')
    }

    useEffect(() => {
        setLoadingKYC(true)
        const getUserData = async (): Promise<void> => {
            try {
                const user = await Auth.currentAuthenticatedUser()
                const api = process.env.REACT_APP_API as string
                const jwtToken = user.signInUserSession.idToken.jwtToken as string

                const session = await axios.get(`${api}/session`, {
                    headers: {
                        Authorization: jwtToken
                    }
                })
                const userId = session.data.data.id

                await axios.get(`${api}/kyc-records/user/${userId as string}`, {
                    headers: {
                        Authorization: jwtToken
                    }
                })
                navigate('/user')
            } catch (error) {
                console.error('Error fetching user data:', error)
            } finally {
                setLoadingKYC(false)
            }
        }

        void getUserData()
    }, [])
    return (
        loadingKYC
            ? <IsLoading />
            : <>
                {onboardingStep === 1 && <DocumentSetup skipOnboarding={skipOnboarding} nextStep={nextStep} />}
                {onboardingStep === 2 && <HomeAddressSetUp skipOnboarding={skipOnboarding} nextStep={nextStep} />}
            </>

    )
}
