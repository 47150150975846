import React, { type ReactNode } from 'react'
import { Box, Modal } from '@mui/material'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}
interface IProps {
  noBackdrop: boolean
  children: ReactNode
  showModal: boolean
}

export const AppModalSkeleton: React.FC<IProps> = ({
  noBackdrop,
  children,
  showModal
}) => {
  return (
    <Modal
      open={showModal ?? false}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className={`${
        noBackdrop ? '' : 'backdrop-blur'
      } white-backdrop-bg h-full overflow-y-scroll`}
    >
      <Box
        width={{
          xs: '90%',
          sm: '70%',
          md: '50%',
          lg: '35%'
        }}
        sx={style}
        className='modal-box py-8 my-14 px-3 sm:px-5 !border-white'
      >
        {children}
      </Box>
    </Modal>
  )
}
