import React from 'react'
import { GroupsModalSkeleton } from './groupsModalSkeleton'
import { IconButton, Stack } from '@mui/material'
import { CloseOutlined, Circle } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import PlayNjangeeLogoImage from '../../../../images/dashboard_images/icon-playNjagee-logo@2x.png'
import MasterCardLogo from '../../../../images/dashboard_images/icon-playNjagee-mastercard.png'
import VisaCardLogo from '../../../../images/dashboard_images/icon-playNjagee-visa.png'
import MTNMoMo from '../../../../images/dashboard_images/icon-playNjagee-mtnmomo.png'
import OrangeMoney from '../../../../images/dashboard_images/icon-playNjagee-orangeMoney.png'

interface IPlayNjangeeConfirmPayment {
    open: boolean
    handleClose: () => void
    paymentMethod: string
}
export const PlayNjangeeConfirmPayment: React.FC<IPlayNjangeeConfirmPayment> = ({ open, handleClose, paymentMethod }) => {
    const { t } = useTranslation(['dashboard'])
    return (
        <GroupsModalSkeleton xsModalWidth='95%' smModalWidth='70%' mdModalWidth='60%' lgModalWidth='45%' open={open} handleClose={handleClose}>
            <Stack className='w-full'>
                <Stack direction='row' className='flex items-center w-full justify-start gap-3 bg-primary text-th-white py-2 px-5 sm:px-5 rounded-t border'>
                    <h6>{t('groups.confirm_payment')}</h6>
                    <IconButton onClick={handleClose} className='!ml-auto'>
                        <CloseOutlined className='text-primary-light' />
                    </IconButton>
                </Stack>

                <Stack className='w-full py-2 px-5 sm:px-5 lg:px-6'>
                    <Stack direction='row' className='items-center gap-3 border-b border-black border-dashed pt-4 pb-6'>
                        <img alt='' src={PlayNjangeeLogoImage} className='w-20 h-20 rounded' />
                        <h6>Buea Deaf Community Njangee</h6>
                    </Stack>

                    <Stack className='w-full py-5 mb-5 border-b border-black border-dashed'>
                        <Stack direction='row' className='items-center gap-3 sm:gap-5 py-2 w-full'>
                            <Stack className='w-20'>
                                <p>{t('groups.pay_with')}</p>
                            </Stack>
                            <Stack direction='row' className='items-center w-full justify-start gap-3 px-5 bg-light border border-secondary-xlight rounded-md'>
                                {paymentMethod === 'mastercard' && <img alt='' src={MasterCardLogo} className='h-fit w-fit' />}
                                {paymentMethod === 'visacard' && <img alt='' src={VisaCardLogo} className='h-fit w-fit' />}
                                {paymentMethod === 'mtnmomo' && <img alt='' src={MTNMoMo} className='h-10 w-10 my-2' />}
                                {paymentMethod === 'orangemoney' && <img alt='' src={OrangeMoney} className='h-fit w-fit' />}
                                <p className='font-semibold my-2'>Sandra Miss Leonidis **** 3685</p>
                            </Stack>
                        </Stack>

                        <Stack direction='row' className='items-center gap-3 sm:gap-5 py-2'>
                            <Stack className='w-20'>
                                <p>{t('groups.amount')}</p>
                            </Stack>
                            <Stack className='w-full'>
                                <p className='font-extra'>XAF 50,000</p>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack direction='row' className='w-full mt-1 items-center gap-3 sm:gap-5'>
                        <p className='w-20'>{t('groups.paid_by')}</p>
                        <Stack direction='row' className='w-full'>
                            <p className='flex flex-wrap items-center gap-2 font-semibold'>
                                <span className='text-black-2 font-extra'>Sandra Miss Leonidis</span>
                                <Circle className='text-9 text-primary-xlight' /> +237 678598566</p>
                        </Stack>
                    </Stack>

                </Stack>

                <Stack className='w-full px-5 sm:px-5 lg:px-6 my-8'>
                    <Stack direction='row' className='ml-auto items-center gap-5 md:gap-10'>
                        <button onClick={handleClose} className='border-0 bg-none text-secondary font-semibold'>Cancel</button>
                        <button className='btn btn-primary'>{t('groups.confirm_payment')}</button>
                    </Stack>
                </Stack>
            </Stack>
        </GroupsModalSkeleton>
    )
}
