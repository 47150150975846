import React, { type ReactNode } from 'react'
import { Box, Modal } from '@mui/material'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}

interface IGroupsModalSkeleton {
    open: boolean
    handleClose: () => void
    modalClass?: string
    xsModalWidth?: string
    smModalWidth?: string
    mdModalWidth?: string
    lgModalWidth?: string
    boxClass?: string
    children: ReactNode
}
export const GroupsModalSkeleton: React.FC<IGroupsModalSkeleton> = ({ open, handleClose, modalClass, xsModalWidth, smModalWidth, mdModalWidth, lgModalWidth, boxClass, children }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`h-full overflow-y-scroll ${modalClass ?? ''}`}
        >
            <Box width={{
                xs: xsModalWidth,
                sm: smModalWidth,
                md: mdModalWidth,
                lg: lgModalWidth
            }} sx={style} className={`modal-box !rounded my-14 !border-white ${boxClass ?? ''}`}>
                {children}
            </Box>
        </Modal>
    )
}
