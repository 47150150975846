import React from 'react'
import { Stack } from '@mui/material'
import SignUpSuccessIcon from '../../images/icon_images/icon-signup-success.svg'
import { Link } from 'react-router-dom'
import { ChevronRightOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

export const SignUpSuccess: React.FC = () => {
    const { t } = useTranslation(['dashboard'])
    localStorage.setItem('njangee_signUpType', 'email')
    return (
        <Stack className='h-full px-6 sm:px-8 py-8'>
            <br />
            <img src={SignUpSuccessIcon} alt='' />
            <br />
            <Stack>
                <h4>{t('registration.signup_success')}!</h4>
                <p>{t('registration.signup_success_caption')}</p>
            </Stack>
            <br />
            <Stack className='mt-auto'>
                <Link to='/login' className='btn btn-primary flex gap-2' >
                    {t('registration.continue')}
                    <ChevronRightOutlined className='text-white' />
                </Link>
            </Stack>
        </Stack>
    )
}
