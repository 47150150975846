import React from 'react'
import { Stack } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EditProfileIcon from '../../../images/icon_images/icon-settings-editProfile.svg'
import PaymentIcon from '../../../images/icon_images/icon-settings-paymentMethod.svg'
import { LockOutlined, BadgeOutlined } from '@mui/icons-material'
import NotificationIcon from '../../../images/icon_images/icon-notification-outline.svg'
import { UserDashBoardLayout } from '../common/dashboardUiLayout'
import { EditProfile } from './settings_components/editProfile'
import { PaymentMethods } from './settings_components/paymentMethods'
import { ChangePassword } from './settings_components/changePassword'
import { IdentityVerification } from './settings_components/identityVerification'
import { NotificationSettings } from './settings_components/notificationSettings'

export const UserSettings: React.FC = () => {
    const { search } = useLocation()
    const paramsOption = search
    const navigate = useNavigate()
    const { t } = useTranslation(['dashboard'])

    const handleOption = (param: string): void => {
        navigate(`/user-settings?${param}`)
    }
    return (
        <UserDashBoardLayout>
            <Stack className='w-full settings'>
                <Stack direction='column' className='w-full bg-white px-4 md:px-12'>

                    <Stack direction='row' className='w-full items-center my-3 py-2 gap-4 flex-wrap border-b border-main'>
                        <h4 className='w-auto'>{t('settings.settings')}</h4>
                    </Stack>

                    <Stack direction='row' className='justify-start gap-2 mt-2 g-inline-nav overflow-y-hidden'>
                        <Stack className={`inline-nav-section ${(paramsOption === '?edit-profile' || paramsOption === '') ? 'inline-active-nav' : ''}`} onClick={() => { handleOption('edit-profile') }}>
                            <p className='flex items-center justify-center'>
                                {(paramsOption === '?edit-profile' || paramsOption === '') && <img alt='' src={EditProfileIcon} className='w-fit h-fit pr-2' />}
                                {t('general.common.edit_profile')}
                            </p>
                        </Stack>
                        <Stack className={`inline-nav-section ${paramsOption === '?payment-methods' ? 'inline-active-nav' : ''}`} onClick={() => { handleOption('payment-methods') }}>
                            <p className='flex items-center justify-center'>
                                {paramsOption === '?payment-methods' && <img alt='' src={PaymentIcon} className='w-fit h-fit pr-2' />}
                                {t('general.common.payment_methods')}
                            </p>
                        </Stack>
                        <Stack className={`inline-nav-section ${paramsOption === '?change-password' ? 'inline-active-nav' : ''}`} onClick={() => { handleOption('change-password') }}>
                            <p className='flex items-center justify-center'>
                                {paramsOption === '?change-password' && <LockOutlined className='text-primary-light pr-2' />}
                                {t('settings.change_password')}
                            </p>
                        </Stack>
                        <Stack className={`inline-nav-section ${paramsOption === '?notifications' ? 'inline-active-nav' : ''}`} onClick={() => { handleOption('notifications') }}>
                            <p className='flex items-center justify-center'>
                                {paramsOption === '?notifications' && <img alt='' src={NotificationIcon} className='w-fit h-fit pr-2' />}
                                {t('general.common.notifications')}
                            </p>
                        </Stack>
                        <Stack className={`inline-nav-section ${paramsOption === '?identity-verification' ? 'inline-active-nav' : ''}`} onClick={() => { handleOption('identity-verification') }}>
                            <p className='flex items-center justify-center'>
                                {paramsOption === '?identity-verification' && <BadgeOutlined className='text-primary-light pr-2' />}
                                {t('settings.identity_verification')}
                            </p>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack className='w-full py-4'>
                    {(paramsOption === '?edit-profile' || paramsOption === '') && <EditProfile />}
                    {paramsOption === '?payment-methods' && <PaymentMethods />}
                    {paramsOption === '?change-password' && <ChangePassword />}
                    {paramsOption === '?notifications' && <NotificationSettings />}
                    {paramsOption === '?identity-verification' && <IdentityVerification />}
                </Stack>
            </Stack>
        </UserDashBoardLayout>
    )
}
