import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../redux/app/create_njangee_store/store'
export interface Cities {
    cities: any
}

const initialState: Cities = {
    cities: undefined
}

export const createCitiesSlice = createSlice({
    name: 'change_password',
    initialState,
    reducers: {
        setCities: (state, action: PayloadAction<Cities>) => {
            return {
                ...state,
                cities: action.payload.cities
            }
        }
    }
})

export const { setCities } = createCitiesSlice.actions

export const createCitiesSelector = (state: RootState): Cities => state.createCitiesReducer
export default createCitiesSlice.reducer
