interface DaySuffixesLanguage {
  [day: number]: string
  default: string
}

const monthsEnglish = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const monthsGerman = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
]

const monthsFrench = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
]

const daySuffixes: Record<string, DaySuffixesLanguage> = {
  en: {
    1: 'st',
    2: 'nd',
    3: 'rd',
    default: 'th'
  },
  de: {
    1: 'er',
    21: 'er',
    31: 'er',
    default: ''
  },
  fr: {
    1: 'er',
    21: 'ème',
    31: 'ème',
    default: 'ème'
  }
}
export const convertStringToISODate = (date: string): string => {
  const dateObject = new Date(date)
  const isoDateString = dateObject.toISOString()
  return isoDateString
}

export const convertDateToString = (date: string): string => {
  const formattedDate = date.slice(0, 10)
  return formattedDate
}

export const convertToMonthInWords = (
  language: string,
  dateString: string
): string => {
  const date = new Date(dateString)
  const today = new Date()

  // Compare the year, month, and date to check if they are the same
  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()

  // If the date is today, return "Today"
  if (isToday) {
    return 'Today'
  }

  const year = date.getFullYear()
  const monthIndex = date.getMonth()
  const day = date.getDate()

  const months =
    language === 'de'
      ? monthsGerman
      : language === 'fr'
      ? monthsFrench
      : monthsEnglish

  const suffixes = daySuffixes[language]
  const suffix = suffixes[day] !== undefined ? suffixes[day] : suffixes.default

  // Format the date with month in words
  const formattedDate = `${months[monthIndex]} ${day}${suffix}, ${year}`

  return formattedDate
}

export const isExpiryDateGreater = (selectedDate: string): boolean => {
  const selected = new Date(selectedDate)
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  if (selected >= today) {
    return true
  } else {
    return false
  }
}

export const getTodayDate = (): string => {
  const today = new Date()
  const yyyy = today.getFullYear()
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const dd = String(today.getDate()).padStart(2, '0')
  return `${yyyy}-${mm}-${dd}`
}

export const formatToReadableDate = (
  dateString: string,
  separator: string = '/'
): string => {
  const date = new Date(dateString)
  const today = new Date()

  // Compare the year, month, and date to check if they are the same
  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()

  // If the date is today, return "Today"
  if (isToday) {
    return 'Today'
  }

  // Otherwise, format the date
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
  const year = date.getFullYear()

  return `${day}${separator}${month}${separator}${year}`
}

export const formatToDateAndTimeString = (
  dateString: string,
  seperator?: string
): string => {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }

  // Format the date
  if (seperator !== null) {
    const formattedDate = date.toLocaleString('en-US', options)
    const lastCommaIndex = formattedDate.lastIndexOf(',')

    if (lastCommaIndex !== -1) {
      // if (seperator) {
      return (
        formattedDate.substring(0, lastCommaIndex) +
        ` ${seperator ?? ','} ` +
        formattedDate.substring(lastCommaIndex + 1)
      )
      // }
    }
  }
  return date.toLocaleString('en-US', options)
}
