import React, { useState } from 'react'
import { Stack } from '@mui/material'
import { WebLayout } from './layouts.tsx/app'
import HeaderBg from '../../images/web_images/img-news-pageHeader-bg@2x.png'
import { TextInputField } from '../user/common/textInputField'
import { SelectCountryInput } from '../user/common/selectCountryInput'
import PhoneIcon from '../../images/icon_images/icon-contact-phone.svg'
import EmailIcon from '../../images/icon_images/icon-contact-email.svg'
import LocationIcon from '../../images/icon_images/icon-contact-location.svg'
import MapBg from '../../images/web_images/img-contact-map@2x.png'
import { useForm } from 'react-hook-form'
import { CircularLoadingProgress } from '../registration/fragments/circularProgress'
import axios from 'axios'
import { isValidEmail } from '../user/common/helper/validCreds'
import { useTranslation } from 'react-i18next'

export const Contact: React.FC = () => {
  const [countryValue] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showCircleProgress, setShowCircleProrgress] = useState(false)
  const [isTrue] = useState(true)
  const { t } = useTranslation(['web'])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm()

  const formValidation = {
    name: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 3
      }
    },
    email: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 5,
        matchPattern: (v: string) => isValidEmail(v)
      }
    },
    country: {
      required: true
    },
    subject: {
      required: true
    }
  }

  const handleContactForm = (data: any): void => {
    console.log(data)
    setShowCircleProrgress(true)
    axios
      .post(
        `${process.env.REACT_APP_API as string}/contacts`,
        {
          name: data.name,
          email: data.email,
          mobile: data.phone_number,
          country: data.country,
          subject: data.subject,
          message: data.message
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((res) => {
        setShowCircleProrgress(false)
        setShowErrorMessage(false)
        setShowSuccessMessage(true)
        console.log(res)
        reset()
      })
      .catch((error) => {
        console.error(error)
        setShowCircleProrgress(false)
        setErrorMessage('Network Error. Please try again.')
        setShowSuccessMessage(false)
        setShowErrorMessage(true)
      })
  }
  return (
    <WebLayout>
      <Stack
        className='w-full h-full bg-white'
        style={{
          backgroundImage: `url(${HeaderBg})`,
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          minHeight: '300px'
        }}
      >
        <Stack className='w-full px-5 py-20'>
          <Stack className='text-center mx-auto gap-5'>
            <h2 className='text-primary'>{t('common.contact_us')}</h2>
          </Stack>
        </Stack>

        <Stack className='w-full container my-5'>
          <Stack
            direction='row'
            display='grid'
            className='grid-cols-1 md:grid-cols-2 shadow-2xl rounded-md'
          >
            <Stack className='w-full py-5 md:py-24 px-5 sm:px-10 md:px-7 lg:pl-10 lg:pr-32'>
              <form
                onSubmit={handleSubmit((data) => {
                  handleContactForm(data)
                })}
              >
                <Stack className='w-full gap-4'>
                  <Stack className='gap-1'>
                    <p className='font-semibold'>{t('common.your_name')} </p>
                    <TextInputField
                      name='name'
                      anyValidation={register('name', formValidation.name)}
                      type='text'
                    />
                    {errors.name?.type === 'required' && (
                      <small className='text-danger'>
                        {t('home.name_is_required')}
                      </small>
                    )}
                    {errors.name?.type === 'minLength' && (
                      <small className='text-danger'>
                        {t('home.name_should_have_atleast_3_characters')}
                      </small>
                    )}
                  </Stack>
                  <Stack className='gap-1'>
                    <p className='font-semibold'>{t('common.email')}</p>
                    <TextInputField
                      name='email'
                      anyValidation={register('email', formValidation.email)}
                      type='email'
                    />
                    {errors.email?.type === 'required' && (
                      <small className='text-danger'>
                        {t('home.email_is_required')}
                      </small>
                    )}
                    {errors.email?.type === 'minLength' && (
                      <small className='text-danger'>
                        {t('home.email_should_have_atleast_5_characters')}
                      </small>
                    )}
                    {errors.email?.type === 'matchPattern' && (
                      <small className='text-danger'>
                        {t('home.email_must_be_valid')}
                      </small>
                    )}
                  </Stack>
                  <Stack className='gap-1'>
                    <p className='font-semibold'>{t('common.phone_number')}</p>
                    <TextInputField
                      anyValidation={register('phone_number')}
                      type='text'
                    />
                  </Stack>
                  <Stack className='w-full'>
                    <SelectCountryInput
                      label={t('contact.country')}
                      anyValidation={register(
                        'country',
                        formValidation.country
                      )}
                      countryValue={countryValue}
                    />
                    {errors.country?.type === 'required' && (
                      <small className='text-danger'>
                        {t('contact.country_is_required')}
                      </small>
                    )}
                  </Stack>
                  <Stack className='gap-1'>
                    <p className='font-semibold'>{t('contact.subject')}</p>
                    <TextInputField
                      anyValidation={register(
                        'subject',
                        formValidation.subject
                      )}
                      type='text'
                    />
                    {errors.subject?.type === 'required' && (
                      <small className='text-danger'>
                        {t('contact.subject_is_required')}
                      </small>
                    )}
                  </Stack>
                  <Stack className='gap-1'>
                    <p className='font-semibold'>{t('common.message')}</p>
                    <textarea
                      rows={5}
                      maxLength={1000}
                      {...register('message')}
                      className='w-full'
                    ></textarea>
                  </Stack>
                  <br />
                  <Stack className='w-full'>
                    <button
                      className='w-full px-7 py-3 text-white text-center bg-primary a-text rounded font-semibold tracking-wider flex gap-2 justify-center'
                      disabled={showCircleProgress ? isTrue : !isTrue}
                    >
                      {showCircleProgress && <CircularLoadingProgress />}
                      {t('common.send_message')}
                    </button>
                  </Stack>
                </Stack>
              </form>
              {showSuccessMessage && (
                <Stack className='mt-5'>
                  <small className='text-success'>
                    {t('home.thank_you_for_contacting_us')}
                  </small>
                </Stack>
              )}
              {showErrorMessage && (
                <Stack className='mt-5'>
                  <small className='text-danger'>{errorMessage}</small>
                </Stack>
              )}
            </Stack>

            <Stack className='w-full bg-primary-3xlight md:rounded-r-md'>
              <Stack className='w-full p-6'>
                <Stack className='w-full px-0 lg:px-5'>
                  <Stack>
                    <h4>{t('contact.we_will_love_to_hear_from_you')}</h4>
                    <p>{t('contact.send_us_a_message')}</p>
                  </Stack>
                  <br />
                  <Stack className='w-full sm:px-5 lg:px-10 gap-4'>
                    <Stack
                      direction='row'
                      className='item-center flex-wrap md:flex-nowrap justify-start w-full bg-white rounded-md py-10 px-5 md:px-10 gap-5 lg:gap-14'
                    >
                      <img
                        src={PhoneIcon}
                        alt=''
                        className='-ml-10 md:-ml-16 lg:-ml-20'
                      />
                      <Stack className='gap-4'>
                        <Stack>
                          <p className='font-semibold'>
                            {t('contact.cameroon_office')}
                          </p>
                          <p className='text-base'>+237 6 77 54 59 07</p>
                        </Stack>
                        <Stack>
                          <p className='font-semibold'>
                            {t('contact.europe_office')}
                          </p>
                          <p className='text-base'>+352 621153998</p>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack
                      direction='row'
                      className='item-center flex-wrap md:flex-nowrap justify-start w-full bg-white rounded-md py-10 px-5 md:px-10 gap-5 lg:gap-14'
                    >
                      <img
                        src={LocationIcon}
                        alt=''
                        className='-ml-10 md:-ml-16 lg:-ml-20'
                      />
                      <Stack className='gap-4'>
                        <Stack>
                          <p className='font-semibold'>Molyko Buea</p>
                          <p className='text-base'>South West Region</p>
                          <p className=''>Cameroon</p>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack
                      direction='row'
                      className='item-center flex-wrap md:flex-nowrap justify-start w-full bg-white rounded-md py-10 px-5 md:px-10 gap-5 lg:gap-14'
                    >
                      <img
                        src={EmailIcon}
                        alt=''
                        className='-ml-10 md:-ml-16 lg:-ml-20'
                      />
                      <Stack className='gap-4'>
                        <Stack>
                          <p className='font-semibold'>
                            {t('contact.write_to_us')}
                          </p>
                          <p className='text-base'>contact@njangee.com</p>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Stack
                className='w-full mt-auto md:rounded-br-md rounded-b-md'
                style={{
                  backgroundImage: `url(${MapBg})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  minHeight: '300px'
                }}
              ></Stack>
            </Stack>
          </Stack>
        </Stack>
        <br />
        <br />
      </Stack>
    </WebLayout>
  )
}
