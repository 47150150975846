import React, { type ReactElement, useState } from 'react'
import { Stack } from '@mui/material'
import { RegistrationBox } from './fragments/registrationBox'
import {
  useAppDispatch,
  useAppSelector
} from '../../redux/app/create_njangee_store/hooks'
import {
  createChooseRegistrationSelector,
  setChooseRegistration
} from './slices/chooseRegistrationSlice'
import { ChooseForgotPassword } from './chooseForgotPassOption'
import { TextInputField } from '../user/common/textInputField'
import { PhoneNumberField } from '@aws-amplify/ui-react'
import { useForm } from 'react-hook-form'
import { CircularLoadingProgress } from './fragments/circularProgress'
import { Auth } from 'aws-amplify'
import { ForgotPasswordCodeVerification } from './forgotPasswordVerificationCode'
import { useTranslation } from 'react-i18next'
import {
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber
} from '../user/common/helper/validCreds'

export const ForgotPassword: React.FC = () => {
  const [dialCode, setDialCode] = useState('+1')
  const [showCircleProgress, setShowCircleProgress] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isTrue] = useState(true)
  const { t } = useTranslation(['dashboard'])
  const userRegistration = useAppSelector(createChooseRegistrationSelector)
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const formValidation = {
    username: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 5,
        matchPattern: (v: string) => {
          if (userRegistration.choose_forgot_password_type === 'email') {
            return isValidEmail(v)
          } else {
            return isValidPhoneNumber(v)
          }
        }
      }
    },
    password: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 8,
        matchPattern: (v: string) => isValidPassword(v)
      }
    }
  }
  const handleDialCode = (e: any): void => {
    setDialCode(e.target.value)
  }

  const handleSubmitForgotPassword = (data: any): void => {
    setShowCircleProgress(true)
    setShowErrorMessage(false)
    let username = ''
    if (userRegistration.choose_forgot_password_type === 'email') {
      username = data.username
    } else {
      username = `${dialCode.concat(data.username)}`
    }
    Auth.forgotPassword(username)
      .then((res) => {
        setShowCircleProgress(false)
        localStorage.setItem('njangee_fgt_pass_uname', username)
        localStorage.setItem('njangee_username', username)
        dispatch(
          setChooseRegistration({ ...userRegistration, code_sent: true })
        )
        console.log(res)
      })
      .catch((error) => {
        setShowCircleProgress(false)
        setShowErrorMessage(true)
        console.log(error.code)
        if (error.code === 'CodeMismatchException') {
          setErrorMessage(`${String(t('registration.invalid_code'))}`)
        } else if (error.code === 'ExpiredCodeException') {
          setErrorMessage(`${String(t('registration.expired_code'))}`)
        } else if (error.code === 'LimitExceededException') {
          setErrorMessage(`${String(t('registration.attempt_limit'))}`)
        } else {
          setErrorMessage(`${String(t('registration.network_error'))}`)
        }
      })
  }

  const handleEnterCode = (): void => {
    dispatch(setChooseRegistration({ ...userRegistration, code_sent: true }))
  }

  const forgotPasswordForm = (): ReactElement => {
    return (
      <Stack className='px-6 sm:px-8 py-8'>
        <h4>{t('registration.forgot_password')}</h4>
        <p>{t('registration.forgot_password_caption')}</p>
        <br />
        <form onSubmit={handleSubmit(handleSubmitForgotPassword)}>
          {userRegistration.choose_forgot_password_type === 'email' ? (
            <Stack className='gap-2 mb-4'>
              <p className='font-semibold mb-2'>{t('registration.email')}</p>
              <TextInputField
                type='text'
                anyValidation={register('username', formValidation.username)}
                addClass={
                  errors.username?.type === 'required' ? 'input-error' : ''
                }
              />
              {errors.username?.type === 'required' && (
                <small className='text-danger'>
                  {t('registration.email_required')}
                </small>
              )}
              {errors.username?.type === 'minLength' && (
                <small className='text-danger'>
                  {t('registration.email_has_atleast_5_characters')}
                </small>
              )}
              {errors.username?.type === 'matchPattern' && (
                <small className='text-danger'>
                  {t('registration.must_be_valid_email')}
                </small>
              )}
              {showErrorMessage && (
                <small className='text-danger'>{errorMessage}</small>
              )}
            </Stack>
          ) : (
            <Stack className='gap-2 mb-4'>
              <PhoneNumberField
                label={t('registration.phone_number')}
                maxLength={100}
                defaultDialCode='+1'
                onDialCodeChange={(e: any) => {
                  handleDialCode(e)
                }}
                {...register('username', formValidation.username)}
                className={
                  errors.username?.type === 'required' ? 'input-error' : ''
                }
              />
              {errors.username?.type === 'required' && (
                <small className='text-danger'>
                  {t('registration.phone_number_required')}
                </small>
              )}
              {errors.username?.type === 'minLength' && (
                <small className='text-danger'>
                  {t('registration.phone_number_has_atleast_5_chars')}
                </small>
              )}
              {errors.username?.type === 'matchPattern' && (
                <small className='text-danger'>
                  {t('registration.mst_be_valid_phone_number')}
                </small>
              )}
              {showErrorMessage && (
                <small className='text-danger'>{errorMessage}</small>
              )}
            </Stack>
          )}
          <Stack className='mt-6'>
            <button
              className='btn-full btn-primary flex gap-3 justify-center'
              type='submit'
              disabled={showCircleProgress ? isTrue : !isTrue}
            >
              {showCircleProgress && <CircularLoadingProgress />}
              {t('registration.submit')}
            </button>
          </Stack>
        </form>
        <Stack direction='row' justifyContent='center' mt={1}>
          <small>{t('registration.received_code')}? </small>
          <button
            type='button'
            onClick={handleEnterCode}
            className='small font-bold text-primary ml-1 cursor-pointer'
          >
            {' '}
            {t('registration.enter_code')}.{' '}
          </button>
        </Stack>
      </Stack>
    )
  }

  const componentToDisplay = (): any => {
    if (userRegistration.choose_forgot_password_type !== '') {
      if (userRegistration.code_sent) {
        return (
          <RegistrationBox showNeedHelp={true}>
            {' '}
            <ForgotPasswordCodeVerification />
          </RegistrationBox>
        )
      } else {
        return (
          <RegistrationBox showNeedHelp={true}>
            {' '}
            {forgotPasswordForm()}{' '}
          </RegistrationBox>
        )
      }
    } else {
      return <ChooseForgotPassword />
    }
  }

  return <>{componentToDisplay()}</>
}
