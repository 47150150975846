export const isValidEmail = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const isValidPassword = (pwd: string): boolean => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(pwd)
}

export const isValidPhoneNumber = (num: string): boolean => {
    return /^\d+$/.test(num)
}

export const isValidCode = (num: string): boolean => {
    return /^\d+$/.test(num)
}
