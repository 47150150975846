import React, { type ReactElement, useEffect, useState } from 'react'
import {
  IconButton,
  Stack,
  FormControl,
  Box,
  Select,
  MenuItem,
  type SelectChangeEvent,
  InputLabel,
  Pagination,
  Checkbox,
  ListItemText
} from '@mui/material'
import { Edit, SearchOutlined } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ViewIcon from '../../../../images/icon_images/icon-view-1.svg'
import PlayNjangeeIcon from '../../../../images/icon_images/icon-play-njangee.svg'
import axios from 'axios'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import {
  createGetGroupsSelector,
  type IFilterGroupParams,
  setGroupFilters,
  setGroups
} from '../slices/getGroupSlice'
import { IsLoading } from '../../../registration/fragments/isLoading'
import { Auth } from 'aws-amplify'
import { getPageNumber } from '../../common/helper/routeFunctions'
import { useSelector } from 'react-redux'
import { createUserAuthSelector } from '../../../amplify/slices/authSlices'
import {
  createPlayNjangeeSelector,
  setPlayNjangeeData
} from '../slices/playNjangeeSlice'
import {
  showModalF,
  showModalSelector
} from '../../njangeeGroupModals/slices/showModalSlice'
import { apiDebounce } from '../../../common/helper/debounce'
import { formatToReadableDate } from '../../../common/helper/convertToDate'

const sortingOptions = [
  { value: 'name', label: 'Name' },
  { value: 'amount', label: 'Amount' },
  { value: 'createdAt', label: 'Created At' }
]

export const DisplayAllGroups: React.FC = () => {
  const [frequencyFilter, setFrequencyFilter] = useState('')
  const [groupTypeFilter, setGroupTypeFilter] = useState('')
  const [contributionTypeFilter, setContributionTypeFilter] = useState('')
  const [sortingOrderFilter, setSortingOrderFilter] = useState<string[]>([])
  // const [isGettingGroups, setIsGettingGroups] = useState(true)

  const [currentPage, setCurrentPage] = useState(getPageNumber())
  const allGroups = useAppSelector(createGetGroupsSelector)
  const userData = useSelector(createUserAuthSelector).user
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const playNjangeeState = useAppSelector(createPlayNjangeeSelector)
  const showModalState = useAppSelector(showModalSelector)

  const { t } = useTranslation(['dashboard'])

  const handleFrequencyFilter = apiDebounce(
    (event: SelectChangeEvent): void => {
      setFrequencyFilter(event.target.value)
      void getGroups({
        ...allGroups.filters,
        frequency: event.target.value
      })
    }
  )
  const handleGroupTypeFilter = apiDebounce(
    (event: SelectChangeEvent): void => {
      setGroupTypeFilter(event.target.value)
      void getGroups({
        ...allGroups.filters,
        type: event.target.value
      })
    }
  )
  const handleContributionTypeFilter = apiDebounce(
    (event: SelectChangeEvent): void => {
      setContributionTypeFilter(event.target.value)
      void getGroups({
        ...allGroups.filters,
        orderType: event.target.value
      })
    }
  )
  const handleSearchTypeFilter = apiDebounce(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      void getGroups({
        ...allGroups.filters,
        search: event.target.value
      })
    }
  )

  const onClickShowModal = (): void => {
    dispatch(showModalF({ ...showModalState, showModal: true }))
  }

  const handleSortingOrderFilter = apiDebounce(
    (event: SelectChangeEvent<string[]>): void => {
      const {
        target: { value }
      } = event

      const newValue = typeof value === 'string' ? value.split(',') : value
      const currentSelection = [...sortingOrderFilter]

      newValue.forEach((val) => {
        if (currentSelection.includes(val)) {
          const index = currentSelection.indexOf(val)
          currentSelection.splice(index, 1)
        } else {
          currentSelection.push(val)
        }
      })
      const newSortString = currentSelection.join(',')
      setSortingOrderFilter(currentSelection)
      void getGroups({
        ...allGroups.filters,
        sort: newSortString
      })
    }
  )

  const getQueryParams = (
    paramsData: IFilterGroupParams
  ): Record<string, string> => {
    const params: Record<string, string> = { ...paramsData }
    params.page = paramsData.page?.toString() ?? getPageNumber().toString()
    const filteredParams = Object.keys(params)
      .filter((key) => params[key as keyof IFilterGroupParams] !== '')
      .reduce<Record<string, string>>((obj, key) => {
        obj[key] = params[key as keyof IFilterGroupParams]
        return obj
      }, {})
    dispatch(setGroupFilters({ ...allGroups, filters: filteredParams }))
    return filteredParams
  }
  const getGroups = async (allParams: IFilterGroupParams): Promise<void> => {
    const paramsData = getQueryParams(allParams)
    const searchParams = new URLSearchParams(paramsData)

    const apiUrl = `${
      process.env.REACT_APP_API as string
    }/groups/?${searchParams.toString()}`
    dispatch(
      setGroups({
        ...allGroups,
        // filter: params.toString(),
        isLoading: true
      })
    )
    try {
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      const groups = await axios.get(apiUrl, {
        headers: {
          Authorization: token
        }
      })

      dispatch(
        setGroups({
          ...allGroups,
          groups: groups.data.data,
          meta: groups.data.meta,
          isLoading: false
        })
      )
      navigate(`?page=${allParams.page as string}`)
    } catch (error) {
      // console.error('Error getting groups: ', error)
      dispatch(
        setGroups({
          ...allGroups,
          isLoading: false
        })
      )
    }
  }
  const handlePlayNjangee = (group: any): void => {
    dispatch(setPlayNjangeeData({ ...playNjangeeState, groupData: group }))
    navigate('/user-groups/play-njangee')
  }
  useEffect(() => {
    void getGroups(allGroups.filters)
  }, [])
  const displayGroups = (): ReactElement => {
    return allGroups.isLoading ? (
      <IsLoading />
    ) : allGroups.groups.length > 0 ? (
      allGroups.groups?.map((group: any) => {
        return (
          <Stack
            key={group.id}
            direction='column'
            className='rounded-lg border border-main bg-white mb-5'
          >
            <Stack direction='column' className='p-4'>
              {userData.id === group.userId && (
                <Link
                  to={`/user-groups/${String(group.id)}/edit`}
                  className='ml-auto'
                >
                  <Edit fontSize='small' className='text-primary-light' />
                </Link>
              )}
              <Stack direction='row' className='stacked-images mt-5 mb-2'>
                {group.groupMembers.slice(0, 6).map((userPhoto: any) =>
                  userPhoto.user?.photo === null ? (
                    <Stack
                      key={userPhoto?.id}
                      className='bg-primary-xlight font-extrabold text-center'
                    >
                      <h5 className='my-auto text-primary'>
                        {userPhoto.user?.firstName.slice(0, 2).toUpperCase() ??
                          'NA'}
                      </h5>
                    </Stack>
                  ) : (
                    <img
                      key={userPhoto.user?.photo}
                      alt=''
                      src={userPhoto.user?.photo}
                      className='user-photo'
                    />
                  )
                )}

                {group.groupMembers.length > 6 && (
                  <Stack className='bg-secondary font-extrabold text-center'>
                    <small className='my-auto text-secondary'>
                      +{group.groupMembers.length - 6}
                    </small>
                  </Stack>
                )}
              </Stack>
              <Stack className='mt-3'>
                <h5 className='text-primary-light'>{group.name}</h5>
                <Stack direction='row' className='gap-5'>
                  <small className='text-black-xlight'>
                    <span className='font-extrabold'>
                      {group.currency ?? ''} {group.amount ?? 0}
                    </span>{' '}
                    <span className='!capitalize'> - {group.frequency}</span>
                  </small>
                  <small className='text-black-xlight'>
                    {t('groups.total_members')}:
                    <span className='font-semibold'>
                      {' '}
                      {group.groupMembers.length}
                    </span>
                  </small>
                </Stack>

                <Stack className='w-full mt-5'>
                  <span className='!w-full rounded-full bg-secondary-xlight h-1'>
                    <div
                      className='rounded-full bg-primary h-1'
                      style={{
                        width: `${
                          ((group?.currentRound - 1) /
                            group?.groupMembers?.length) *
                          100
                        }%`
                      }}
                    ></div>
                  </span>
                  <Stack direction='row'>
                    <small className='text-secondary-light text-overline font-semibold'>
                      {group?.currentRound - 1}
                    </small>
                    <small className='text-secondary-light text-overline font-semibold ml-auto'>
                      {group?.groupMembers?.length}
                    </small>
                  </Stack>
                </Stack>

                {group.nextContributionAndRecipient?.nextContribution !==
                null ? (
                  <Stack className='mt-4'>
                    <small>
                      {t('groups.next_contributions')}:{' '}
                      <span className='text-success font-semibold'>
                        {/* Today{' '} */}
                        {formatToReadableDate(
                          group.nextContributionAndRecipient?.nextContribution
                            ?.date,
                          '-'
                        )}
                      </span>
                    </small>
                  </Stack>
                ) : (
                  <small>-</small>
                )}

                <Stack direction='row' className='flex-wrap gap-3'>
                  <p className='font-extra'>
                    {group?.currency} {group?.totalContributions}/
                    <span className='text-secondary-light font-extrabold'>
                    {group?.currency} {group?.totalContributionsToBeMade}
                    </span>
                  </p>
                  {group.nextContributionAndRecipient?.nextRecipient !==
                  null ? (
                    <small className='flex flex-wrap items-center ml-auto font-semibold'>
                      <span className='text-black-2 capitalize'>
                        {t('groups.recipient')}:
                      </span>

                      {group.nextContributionAndRecipient?.nextRecipient?.user
                        .photo === null ||
                      group.nextContributionAndRecipient?.nextRecipient?.user
                        .photo === undefined ? (
                        <Stack
                          key={
                            group.nextContributionAndRecipient?.nextRecipient
                              .user?.photo
                          }
                          className='group-x-sm-img mx-1 bg-primary-xlight font-extrabold text-center'
                        >
                          <small className='my-auto text-primary font-extra text-9'>
                            {group.nextContributionAndRecipient?.nextRecipient.user?.firstName
                              .slice(0, 2)
                              .toUpperCase() ?? 'NA'}
                          </small>
                        </Stack>
                      ) : (
                        <img
                          key={
                            group.nextContributionAndRecipient?.nextRecipient
                              .user?.photo
                          }
                          alt=''
                          src={
                            group.nextContributionAndRecipient?.nextRecipient
                              .user?.photo
                          }
                          className='group-x-sm-img mx-1'
                        />
                      )}
                      {/* <img
                        alt=''
                        src={StackedImage1}
                        className='group-x-sm-img mx-1'
                      /> */}
                      {group.nextContributionAndRecipient?.nextRecipient.name}
                    </small>
                  ) : (
                    <small>-</small>
                  )}
                </Stack>
              </Stack>
            </Stack>

            <div className='h-1'></div>
            <Stack
              direction='row'
              style={{ minHeight: '73px' }}
              className='w-full items-center flex-wrap gap-6 rounded-b-lg bg-primary-3xlight p-3 mt-auto border-t border-primary-2xlight'
            >
              <Link
                to={`/user-groups/${String(group.id)}/show`}
                className='flex items-center text-13 font-extrabold text-primary hover:opacity-70 tracking-wider w-fit'
              >
                <img alt='' src={ViewIcon} className='w-fit h-fit pr-2' />
                {t('general.common.view')}
              </Link>
              {group?.nextContributionAndRecipient?.nextContribution !==
              null ? (
                <p
                  role='button'
                  onClick={() => {
                    handlePlayNjangee(group)
                  }}
                  className='btn-with-icon btn-secondary flex items-center mx-auto sm:ml-auto sm:mr-0 mt-1 sm:mt-0'
                >
                  <img
                    alt=''
                    src={PlayNjangeeIcon}
                    className='w-fit h-fit pr-2'
                  />{' '}
                  {t('general.common.play_njangee')}
                </p>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>
        )
      })
    ) : (
      <Stack
        direction='column'
        className='w-full min-h-[50vh] col-span-3 items-center justify-center'
      >
        {allGroups.activeNav === '' && (
          <Stack>
            <h4 className='text-center'>
              {' '}
              {t('groups.you_are_not_part_of_any_group')}.
            </h4>
            <p className='text-black2 text-center'>
              {t('groups.you_are_not_part_of_any_group_caption')}.
            </p>
          </Stack>
        )}
        {allGroups.activeNav === 'createdByMe' && (
          <Stack>
            <h4 className='text-center'>
              {' '}
              {t('groups.you_havent_created_any_group')}.
            </h4>
            <p className='text-black2 text-center'>
              {t('groups.you_havent_created_any_group_caption')}.
            </p>
          </Stack>
        )}
        {allGroups.activeNav === 'joinedByMe' && (
          <Stack>
            <h4 className='text-center'>
              {' '}
              {t('groups.you_havent_joint_any_group')}.
            </h4>
            <p className='text-black2 text-center'>
              {t('groups.you_havent_joint_any_group_caption')}.
            </p>
          </Stack>
        )}
        {(allGroups.activeNav === '' ||
          allGroups.activeNav === 'createdByMe') && (
          <button
            onClick={onClickShowModal}
            className='btn btn-secondary mt-4 bg-primary-2xlight'
          >
            {t('dashboard.create_new_njangi_group')}
          </button>
        )}
      </Stack>
    )
  }
  return (
    <>
      <div className='h-6 sm:h-0'></div>
      <Stack
        direction='row'
        display='grid'
        className='w-full grid-cols-1 lg:grid-cols-3 gap-3 flex-wrap'
      >
        <Stack
          direction='row'
          className='w-full items-center col-span-1 !hidden lg:!flex'
        >
          <input
            type='search'
            placeholder={(t('groups.search_here') as string) + '...'}
            className='!border-0 w-full bg-light'
            // value={searchQuery}
            onChange={handleSearchTypeFilter}
          />
          <IconButton className='ml-auto'>
            <SearchOutlined />
          </IconButton>
        </Stack>
        <Stack
          direction='row'
          className='w-full items-center gap-3 col-span-2 mt-3 font-semibold flex-wrap lg:pl-9'
        >
          <p>{t('groups.filter')}</p>

          <Stack className=''>
            <Box sx={{ minWidth: 130 }}>
              <FormControl fullWidth>
                <InputLabel
                  id='frequency-select-label'
                  className='font-semibold text-th-black '
                  style={{ top: '-7px' }}
                >
                  {t('groups.frequency')}
                </InputLabel>
                <Select
                  labelId='frequency-select-label'
                  id='frequency-select'
                  value={frequencyFilter}
                  label='Frequency'
                  onChange={handleFrequencyFilter}
                  style={{ borderRadius: '6px', height: '45px' }}
                  className='bg-white'
                >
                  <MenuItem value='' className=''>
                    --
                  </MenuItem>
                  <MenuItem value='DAILY' className=''>
                    Daily
                  </MenuItem>
                  <MenuItem value='WEEKLY' className=''>
                    Weekly
                  </MenuItem>
                  <MenuItem value='BI_WEEKLY' className=''>
                    Bi Weekly
                  </MenuItem>
                  <MenuItem value='MONTHLY' className=''>
                    Monthly
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack className=''>
            <Box sx={{ minWidth: 130 }}>
              <FormControl fullWidth>
                <InputLabel
                  id='group-type-select-label'
                  className='font-semibold text-th-black '
                  style={{ top: '-7px' }}
                >
                  {t('groups.group_type')}
                </InputLabel>
                <Select
                  labelId='group-type-select-label'
                  id='group-type-select'
                  value={groupTypeFilter}
                  label='Group Type'
                  onChange={handleGroupTypeFilter}
                  style={{ borderRadius: '6px', height: '45px' }}
                  className='bg-white'
                >
                  <MenuItem value='' className=''>
                    --
                  </MenuItem>
                  <MenuItem value='OPEN' className=''>
                    Open
                  </MenuItem>
                  <MenuItem value='CLOSED' className=''>
                    Closed
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack className=''>
            <Box sx={{ minWidth: 180 }}>
              <FormControl fullWidth>
                <InputLabel
                  id='contribution-type-select-label'
                  className='font-semibold text-th-black '
                  style={{ top: '-7px' }}
                >
                  {t('groups.contribution_type')}
                </InputLabel>
                <Select
                  labelId='contribution-type-select-label'
                  id='contribution-type-select'
                  value={contributionTypeFilter}
                  label='Contribution Type'
                  onChange={handleContributionTypeFilter}
                  style={{ borderRadius: '6px', height: '45px' }}
                  className='bg-white'
                >
                  <MenuItem value='' className=''>
                    --
                  </MenuItem>
                  <MenuItem value='FIFO' className=''>
                    First In First Out
                  </MenuItem>
                  <MenuItem value='LIFO' className=''>
                    Last In First Out
                  </MenuItem>
                  <MenuItem value='RANDOM' className=''>
                    Random
                  </MenuItem>
                  <MenuItem value='CUSTOM' className=''>
                    Custom
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack direction='row' className='lg:ml-auto items-center'>
            <Stack
              className='bg-white pl-3 pr-1 border-main border border-r-0 rounded-md !rounded-r-none'
              style={{ height: '45px' }}
            >
              <p className='my-auto'>{t('groups.sort')}:</p>
            </Stack>
            <Box sx={{ minWidth: 180 }}>
              <FormControl fullWidth className='!border-l-0 relative'>
                <InputLabel
                  id='sort-order-select-label'
                  className='font-semibold text-th-black '
                  style={{ top: '-7px' }}
                >
                  {sortingOrderFilter.join(' - ')}
                </InputLabel>
                <Select
                  labelId='sort-order-select-label'
                  id='sort-order-select'
                  value=''
                  // label={`Sort: ${sortingOrderFilter.join(' - ')}`}
                  onChange={handleSortingOrderFilter}
                  style={{ height: '45px' }}
                  className='bg-white !border-l-none rounded-md !rounded-l-none'
                >
                  {sortingOptions.map((variant) => (
                    <MenuItem key={variant.value} value={variant.value}>
                      <Checkbox
                        checked={sortingOrderFilter.includes(variant.value)}
                      />
                      <ListItemText primary={variant.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </Stack>

        <Stack
          direction='row'
          className='w-full items-center col-span-1 !flex lg:!hidden mt-4'
        >
          <input
            type='text'
            placeholder='Search here...'
            className='!border-0 w-full bg-light'
          />
          <IconButton className='ml-auto'>
            <SearchOutlined />
          </IconButton>
        </Stack>
      </Stack>

      <div className='h-2 sm:h-3'></div>

      {/* groups row */}
      <Stack
        direction='row'
        display='grid'
        className='w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5 groups-row'
      >
        {/* 1  */}
        {displayGroups()}
      </Stack>
      <Stack spacing={2}>
        <Pagination
          count={allGroups.meta?.totalPages}
          color='standard'
          page={currentPage}
          onChange={(data, value) => {
            setCurrentPage(value)
            void getGroups({ ...allGroups.filters, page: value.toString() })
          }}
        />
      </Stack>
    </>
  )
}
