import React, { type ReactElement, useState } from 'react'
import { Stack } from '@mui/material'
import { Info, InfoOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import GroupTypeIcon from '../../../images/icon_images/icon-onboarding--group-type.svg'
import ClosedGroupActiveIcon from '../../../images/icon_images/icon-closed-group.svg'
import ClosedGroupIcon from '../../../images/icon_images/icon-closed-group-1.svg'
import OpenGroupIcon from '../../../images/icon_images/icon-open-group.svg'
import CheckCicleIcon from '../../../images/icon_images/icon-check-circle.svg'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  setGroupType,
  createGroupSelector
} from '../njangeeGroupModals/slices/newGroupSlices'

import { ModalNavs } from './fragments/modalNavs'
interface IModalValue {
  nextModal: (arg: number) => void
  skipOnboarding: () => void
}

export const GroupType: React.FC<IModalValue> = ({
  nextModal,
  skipOnboarding
}) => {
  const [showHelpInfo1, setShowHelpInfo1] = useState(false)
  const [showError, setShowError] = useState(false)
//   const [openGroupActive, setOpenGroupActive] = useState(false)
  const { t } = useTranslation(['dashboard'])

  const groupVals = useAppSelector(createGroupSelector)
  const dispatch = useAppDispatch()

  const onChangeGroup = (value: string): void => {
    dispatch(setGroupType({ groupType: value }))
    console.log(value)
  }

//   const activeOpenGroup = (): void => {
//     setOpenGroupActive(!openGroupActive)
//   }
  const iconDesc = (): ReactElement => {
    return (
      <div className='absolute right-0 text-left p-3 bg-black bg-opacity-80 rounded-lg h-auto text-secondary-xlight z-50 leading-3 w-48'>
        <small className=''>{t('main_modal.with_a_closed_group')}</small>
        <br />
        <br />
        <small>{t('main_modal.open_groups_will_be_visible')}</small>
      </div>
    )
  }

//   const openGroupInfo = (): ReactElement => {
//     return (
//       <div
//         style={{ bottom: '-30px' }}
//         className='absolute text-left p-3 bg-black bg-opacity-80 rounded-lg h-auto text-secondary-xlight z-50 leading-3 w-inherit'
//       >
//         <small className=''>
//           {t('main_modal.sorry_feature_not_available')}
//         </small>
//       </div>
//     )
//   }

  const handleSubmitType = (): void => {
    if (groupVals.groupType === '') {
      setShowError(true)
    } else {
      setShowError(false)
      nextModal(3)
    }
  }
  return (
    <div>
      <Stack
        direction='column'
        className='w-full justify-center text-center px-4 sm:px-10 lg:px-5'
      >
        <img alt='' src={GroupTypeIcon} className='w-fit h-fit mx-auto' />
        <br />
        <h5 className='relative'>
          {t('main_modal.njangee_group_type')}?
          {showHelpInfo1 ? (
            <Info
              onClick={() => {
                setShowHelpInfo1(!showHelpInfo1)
              }}
              className='text-primary-light !text-lg !font-medium ml-2'
            />
          ) : (
            <InfoOutlined
              onClick={() => {
                setShowHelpInfo1(!showHelpInfo1)
              }}
              className='!text-lg !font-medium ml-2'
            />
          )}
          {showHelpInfo1 && iconDesc()}
        </h5>
        <br />

        {showError && (
          <Stack>
            <small className='text-danger text-left'>
              Please choose Group Type to proceed.
            </small>
          </Stack>
        )}
        <Stack
          direction='row'
          className='flex-wrap w-full items-center justify-center gap-3 pt-8 pb-16'
        >
          <Stack
            direction='column'
            className={`inactive-group-type ${
              groupVals.groupType === 'closed' ? 'active-group-type' : ''
            }`}
            onClick={() => {
              onChangeGroup('closed')
            }}
          >
            {groupVals.groupType === 'closed' ? (
              <img alt='' src={ClosedGroupActiveIcon} className='w-fit h-fit' />
            ) : (
              <img alt='' src={ClosedGroupIcon} className='w-fit h-fit' />
            )}
            <p className='mt-4'>{t('main_modal.closed_group')}</p>
            <small className='text-9 tracking-wider font-semibold text-black-2'>
              {t('main_modal.invites_only')}
            </small>
            {groupVals.groupType === 'closed' && (
              <img
                alt=''
                src={CheckCicleIcon}
                className='w-fit h-fit absolute'
                style={{ bottom: '-15px' }}
              />
            )}
          </Stack>

          <Stack
            direction='column'
            className={`inactive-group-type ${
              groupVals.groupType === 'open' ? 'active-group-type' : ''
            }`}
            onClick={() => {
              onChangeGroup('open')
            }}
          >
            <img alt='' src={OpenGroupIcon} className='w-fit h-fit' />
            <p className='mt-4'>{t('main_modal.open_group')}</p>
            {/* {openGroupActive && openGroupInfo()} */}
            {groupVals.groupType === 'open' && (
              <img
                alt=''
                src={CheckCicleIcon}
                className='w-fit h-fit absolute'
                style={{ bottom: '-15px' }}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      <ModalNavs
        prevModal={() => {
          nextModal(1)
        }}
        skipOnboarding={skipOnboarding}
        nextModal={handleSubmitType}
        activeModal={2}
        buttonText={t('main_modal.continue')}
      />
    </div>
  )
}
