import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  type SelectChangeEvent
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UserDashBoardLayout } from '../common/dashboardUiLayout'
import TotalPaymentIcon from '../../../images/icon_images/icon-payment-totalPay.svg'
import IncomingPaymentIcon from '../../../images/icon_images/icon-payment-incoming.svg'
import OutgoingPaymentIcon from '../../../images/icon_images/icon-payment-outgoing.svg'
import PendingPaymentIcon from '../../../images/icon_images/icon-payment-pendingpayment.svg'
import { InOutGoingPayments } from './payments_components/inOutGoingPayments'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  createGetPaymentsSelector,
  setFilters,
  setPayments
} from './slices/paymentsSlice'
import NavLeftInactive from '../../../images/icon_images/icon-nav-left-inactive.svg'
import NavLeftActive from '../../../images/icon_images/icon-nav-left-active.svg'
import NavRightActive from '../../../images/icon_images/icon-nav-right-active.svg'
import NavRightInactive from '../../../images/icon_images/icon-nav-right-inactive.svg'
import { appGetRequest } from '../../common/helper/httpRequests'
import { createUserAuthSelector } from '../../amplify/slices/authSlices'
import { IsLoading } from '../../registration/fragments/isLoading'
import { getPageNumber } from '../common/helper/routeFunctions'
import { SearchOutlined } from '@mui/icons-material'
import { apiDebounce } from '../../common/helper/debounce'
import { convertStringToISODate } from '../../common/helper/convertToDate'

interface IFilterParams {
  from: string
  to: string
  status: string
  page?: string
  type: string
  limit?: string
  search?: string
}
export const UserPayments: React.FC = () => {
  const [showPayment, setShowPayment] = useState('')
  const [totalPaymentsSelect, setTotalPaymentsSelect] = useState('all')
  const [outgoingPaymentsSelect, setOutgoingPaymentsSelect] = useState('all')
  const [incomingPaymentsSelect, setIncomingPaymentsSelect] = useState('today')
  const [fromDateFilter, setFromDateFilter] = useState('')
  const [toDateFilter, setToDateFilter] = useState('')
  const [paymentStatusFilter, setPaymentStatusFilter] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(getPageNumber())
  const paymentsData = useAppSelector(createGetPaymentsSelector)
  const userData = useAppSelector(createUserAuthSelector).user
  const [isGettingPayments, setIsGettingPayments] = useState(false)
  const { t } = useTranslation(['dashboard'])
  const dispatch = useAppDispatch()
  const { page, limit, totalRecords } = paymentsData.meta
  const start = totalRecords > 0 ? (page - 1) * limit + 1 : 0
  const end = Math.min(page * limit, totalRecords)
  const [filterParams, setFilterParams] = useState<IFilterParams>({
    ...paymentsData.filters,
    from: fromDateFilter,
    to: toDateFilter,
    status: paymentStatusFilter,
    type: showPayment,
    search: searchQuery,
    page: getPageNumber().toString()
  })

  const handleTotalContribution = (event: SelectChangeEvent): void => {
    setTotalPaymentsSelect(event.target.value)
  }
  const handleOutgoingContribution = (event: SelectChangeEvent): void => {
    setOutgoingPaymentsSelect(event.target.value)
  }
  const handleIncomingContribution = (event: SelectChangeEvent): void => {
    setIncomingPaymentsSelect(event.target.value)
  }

  const handleShowPayment = (paymentType: string): void => {
    setShowPayment(paymentType)
    setFilterParams({ ...filterParams, type: paymentType })
    void getPayments({ ...filterParams, type: paymentType })
    // if (paymentType !== showPayment) {
    //   void getQueryParams(paymentType)
    // }
  }

  const handleFromDateFilter = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setFromDateFilter(event.target.value)
    setFilterParams({
      ...filterParams,
      from: event.target.value
    })
    // apiDebounce(async () => {
    await getPayments({
      ...filterParams,
      from: event.target.value
    })
    // })
  }
  const handlToDateFilter = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setToDateFilter(event.target.value)
    // apiDebounce(async () => {
    setFilterParams({
      ...filterParams,
      to: event.target.value
    })
    await getPayments({
      ...filterParams,
      to: event.target.value
    })
    // })
  }
  const handlePaymentStatusFilter = async (
    event: SelectChangeEvent
  ): Promise<void> => {
    setPaymentStatusFilter(event.target.value)
    // apiDebounce(async () => {
    setFilterParams({
      ...filterParams,
      status: event.target.value
    })
    await getPayments({
      ...filterParams,
      status: event.target.value
    })
    // })
  }

  const handleSearchTypeFilter = apiDebounce(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchQuery(event.target.value)
      // setFilterParams({
      //   ...allGroups.filters,
      //   search: event.target.value
      // })
      setFilterParams({
        ...filterParams,
        search: event.target.value
      })
      void getPayments({
        ...filterParams,
        search: event.target.value
      })
    }
  )

  const getQueryParams = (
    paramsData: IFilterParams
  ): Record<string, string> => {
    const params: Record<string, string> = { ...paramsData }
    params.page = paramsData.page?.toString() ?? getPageNumber().toString()
    if (paramsData.from !== '') {
      params.from = convertStringToISODate(paramsData.from)
    } else {
      params.from = paramsData.from
    }

    if (paramsData.to !== '') {
      params.to = convertStringToISODate(paramsData.to)
    } else {
      params.to = paramsData.to
    }
    params.status = paramsData.status
    params.type = paramsData.type?.toString()

    const filteredParams = Object.keys(params)
      .filter((key) => params[key as keyof IFilterParams] !== '')
      .reduce<Record<string, string>>((obj, key) => {
        obj[key] = params[key as keyof IFilterParams]
        return obj
      }, {})
    dispatch(setFilters({ ...paymentsData, filters: filteredParams }))
    return filteredParams
  }

  const getPayments = async (allParams: IFilterParams): Promise<void> => {
    setIsGettingPayments(true)
    const paramsData = getQueryParams(allParams)
    const searchParams = new URLSearchParams(paramsData)
    dispatch(
      setPayments({
        ...paymentsData,
        isLoading: true
      })
    )
    try {
      const response = await appGetRequest(
        `/transactions/${userData?.id as string}?${searchParams.toString()}`
      )
      dispatch(
        setPayments({
          ...paymentsData,
          payments: response.data.data,
          meta: response.data.meta,
          isLoading: false
        })
      )
    } catch (error) {
      console.error('Error: ', error)
    } finally {
      setIsGettingPayments(false)
    }
  }

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage)
    void getPayments({
      ...filterParams,
      page: newPage.toString()
    })
  }

  useEffect(() => {
    void getPayments({
      ...paymentsData.filters,
      from: fromDateFilter,
      to: toDateFilter,
      status: paymentStatusFilter,
      type: showPayment
    })
  }, [paymentsData.filters])
  return (
    <UserDashBoardLayout>
      <Stack className='w-full user-payments'>
        <Stack direction='column' className='w-full bg-white px-4 md:px-12'>
          <Stack
            direction='row'
            className='w-full items-center pt-4 pb-2 gap-4 flex-wrap'
          >
            <h4 className='w-auto'>{t('payments.payments')}</h4>
          </Stack>

          <Stack
            direction='row'
            className='justify-start gap-2 mt-2 g-inline-nav overflow-y-scroll'
          >
            <Stack
              className={`inline-nav-section ${
                showPayment === '' ? 'inline-active-nav' : ''
              }`}
              onClick={() => {
                handleShowPayment('')
              }}
            >
              <p
                className={`flex items-center justify-center ${
                  showPayment !== '' ? '!text-black' : ''
                }`}
              >
                {t('general.common.all')}
              </p>
            </Stack>
            <Stack
              className={`inline-nav-section ${
                showPayment === 'OUTGOING' ? 'inline-active-nav' : ''
              }`}
              onClick={() => {
                handleShowPayment('OUTGOING')
              }}
            >
              <p
                className={`flex items-center justify-center ${
                  showPayment !== 'OUTGOING' ? '!text-black' : ''
                }`}
              >
                {t('payments.outgoing_payments')}
              </p>
            </Stack>
            <Stack
              className={`inline-nav-section ${
                showPayment === 'INCOMING' ? 'inline-active-nav' : ''
              }`}
              onClick={() => {
                handleShowPayment('INCOMING')
              }}
            >
              <p
                className={`flex items-center justify-center ${
                  showPayment !== 'INCOMING' ? '!text-black' : ''
                }`}
              >
                {t('payments.payments_received')}
              </p>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction='column' className='w-full px-4 md:px-12'>
          <Stack
            direction='row'
            display='grid'
            className='grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 py-5'
          >
            {showPayment === '' && (
              <Stack
                direction='column'
                className='border border-secondary-xlight rounded bg-white w-full p-4'
                style={{ height: '130px' }}
              >
                <Stack direction='row' className='w-full gap-3'>
                  <img src={TotalPaymentIcon} alt='' />
                  <Stack>
                    <p className='text-black-2 font-semibold'>
                      {t('payments.total_payments')}
                    </p>
                    <h5 className='leading-6'>
                      XAF {paymentsData.meta?.totalPayments ?? 0}
                    </h5>
                  </Stack>
                </Stack>
                <Stack className='w-fit mt-auto'>
                  <Box sx={{ minWidth: 85 }} borderRadius='9999px'>
                    <FormControl
                      fullWidth
                      style={{ borderRadius: '9999px' }}
                      className='!border-0'
                    >
                      {/* <InputLabel id='demo-simple-select-label' className='small'>Date Sorting</InputLabel> */}
                      <Select
                        // labelId='demo-simple-select-label'
                        id='total-payment-sort-select'
                        value={totalPaymentsSelect}
                        label=''
                        onChange={handleTotalContribution}
                        style={{ borderRadius: '9999px', height: '25px' }}
                        className='bg-primary-3xlight !border-0 small'
                      >
                        <MenuItem value='all' className='small'>
                          All
                        </MenuItem>
                        <MenuItem value='this-year' className='small'>
                          This Year
                        </MenuItem>
                        <MenuItem value='last-year' className='small'>
                          Last Year
                        </MenuItem>
                        <MenuItem value='this-month' className='small'>
                          This Month
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Stack>
            )}

            {(showPayment === '' || showPayment === 'OUTGOING') && (
              <Stack
                direction='column'
                className='border border-secondary-xlight rounded bg-white w-full p-4'
                style={{ height: '130px' }}
              >
                <Stack direction='row' className='w-full gap-3'>
                  <img src={OutgoingPaymentIcon} alt='' />
                  <Stack>
                    <p className='text-black-2 font-semibold'>
                      {t('payments.outgoing_payments')}
                    </p>
                    <h5 className='leading-6'>
                      XAF {paymentsData.meta?.totalOutgoingPayments ?? 0}
                    </h5>
                  </Stack>
                </Stack>
                <Stack className='w-fit mt-auto'>
                  <Box sx={{ minWidth: 85 }} borderRadius='9999px'>
                    <FormControl
                      fullWidth
                      style={{ borderRadius: '9999px' }}
                      className='!border-0'
                    >
                      {/* <InputLabel id='demo-simple-select-label' className='small'>Date Sorting</InputLabel> */}
                      <Select
                        // labelId='demo-simple-select-label'
                        id='outgoing-payment-sort-select'
                        value={outgoingPaymentsSelect}
                        label=''
                        onChange={handleOutgoingContribution}
                        style={{ borderRadius: '9999px', height: '25px' }}
                        className='bg-primary-3xlight !border-0 small'
                      >
                        <MenuItem value='all' className='small'>
                          All
                        </MenuItem>
                        <MenuItem value='this-year' className='small'>
                          This Year
                        </MenuItem>
                        <MenuItem value='last-year' className='small'>
                          Last Year
                        </MenuItem>
                        <MenuItem value='this-month' className='small'>
                          This Month
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Stack>
            )}

            {(showPayment === '' || showPayment === 'INCOMING') && (
              <Stack
                direction='column'
                className='border border-secondary-xlight rounded bg-white w-full p-4'
                style={{ height: '130px' }}
              >
                <Stack direction='row' className='w-full gap-3'>
                  <img src={IncomingPaymentIcon} alt='' />
                  <Stack>
                    <p className='text-black-2 font-semibold'>
                      {t('payments.incoming_payments')}
                    </p>
                    <h5 className='leading-6'>
                      XAF {paymentsData.meta?.totalIncomingPayments ?? 0}
                    </h5>
                  </Stack>
                </Stack>
                <Stack className='w-fit mt-auto'>
                  <Box sx={{ minWidth: 85 }} borderRadius='9999px'>
                    <FormControl
                      fullWidth
                      style={{ borderRadius: '9999px' }}
                      className='!border-0'
                    >
                      {/* <InputLabel id='demo-simple-select-label' className='small'>Date Sorting</InputLabel> */}
                      <Select
                        // labelId='demo-simple-select-label'
                        id='incoming-payment-sort-select'
                        value={incomingPaymentsSelect}
                        label=''
                        onChange={handleIncomingContribution}
                        style={{ borderRadius: '9999px', height: '25px' }}
                        className='bg-primary-3xlight !border-0 small'
                      >
                        <MenuItem value='today' className='small'>
                          Today
                        </MenuItem>
                        <MenuItem value='this-year' className='small'>
                          This Year
                        </MenuItem>
                        <MenuItem value='last-year' className='small'>
                          Last Year
                        </MenuItem>
                        <MenuItem value='this-month' className='small'>
                          This Month
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Stack>
            )}

            {(showPayment === '' || showPayment === '3') && (
              <Stack
                direction='column'
                className='border border-secondary-xlight rounded bg-white w-full p-4'
                style={{ height: '130px' }}
              >
                <Stack direction='row' className='w-full gap-3'>
                  <img src={PendingPaymentIcon} alt='' />
                  <Stack>
                    <p className='text-black-2 font-semibold'>
                      {t('payments.pending_payments')}
                    </p>
                    <h5 className='leading-6'>XAF 900,000</h5>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>

          <Stack className='w-full gap-3'>
            <p className='text-black-2 font-semibold'>
              {t('payments.history_of_payments')}
            </p>
            <Stack
              direction='row'
              display='grid'
              className='w-full grid-cols-1 lg:grid-cols-3 gap-6 flex-wrap my-3'
            >
              <Stack direction='row' className='w-full col-span-1 items-center'>
                <input
                  type='search'
                  onChange={handleSearchTypeFilter}
                  placeholder={(t('groups.search_here') as string) + '...'}
                  className='w-full bg-white border !border-r-0 border-secondary-xlight !rounded-e-none'
                />
                <Stack
                  direction='row'
                  className='ml-auto items-center justify-center bg-white border !border-l-0 border-secondary-xlight input !rounded-s-none'
                >
                  <IconButton className='!p-0 !mr-1'>
                    <SearchOutlined className='!text-3xl text-primary-light' />
                  </IconButton>
                </Stack>
              </Stack>
              <Stack
                direction='row'
                className='w-full items-center gap-3 col-span-2 mt-3 font-semibold flex-wrap'
              >
                <p>{t('payments.filter')}</p>

                <Stack className=''>
                  <Box sx={{ minWidth: 150 }}>
                    <small>From:</small>
                    <FormControl fullWidth>
                      <input
                        type='date'
                        value={fromDateFilter}
                        placeholder='From'
                        onChange={handleFromDateFilter}
                        style={{ borderRadius: '6px', height: '45px' }}
                        className='bg-white'
                      />
                    </FormControl>
                  </Box>
                </Stack>

                <Stack className=''>
                  <Box sx={{ minWidth: 150 }}>
                    <small>To:</small>
                    <FormControl fullWidth>
                      <input
                        type='date'
                        value={toDateFilter}
                        placeholder='To'
                        onChange={handlToDateFilter}
                        style={{ borderRadius: '6px', height: '45px' }}
                        className='bg-white'
                      />
                    </FormControl>
                  </Box>
                </Stack>

                <Stack className=''>
                  <Box sx={{ minWidth: 195 }}>
                    <FormControl fullWidth>
                      <InputLabel
                        id='payment-status-select-label'
                        className='font-semibold text-th-black '
                        style={{ top: '-7px' }}
                      >
                        {t('payments.payment_status')}
                      </InputLabel>
                      <Select
                        labelId='payment-status-select-label'
                        id='payment-status-select'
                        value={paymentStatusFilter}
                        label='Payment Status'
                        onChange={handlePaymentStatusFilter}
                        style={{ borderRadius: '6px', height: '45px' }}
                        className='bg-white'
                      >
                        {/* <MenuItem value='' className='small'>Frequency</MenuItem> */}
                        <MenuItem value='SUCCESSFUL' className=''>
                          Complete
                        </MenuItem>
                        <MenuItem value='FAILED' className=''>
                          Failed
                        </MenuItem>
                        <MenuItem value='PENDING' className=''>
                          Pending
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>

                {!paymentsData.isLoading && (
                  <Stack direction='row' className='ml-auto items-center gap-5'>
                    <p className='font-medium'>
                      {start} - {end} {t('general.common.of').toLowerCase()}{' '}
                      {totalRecords}
                    </p>
                    <Stack direction='row' className='gap-3'>
                      <IconButton
                        className='!p-0 !m-0'
                        onClick={() => {
                          handlePageChange(currentPage - 1)
                        }}
                        disabled={currentPage <= 1}
                      >
                        <img
                          alt=''
                          src={
                            currentPage <= 1 ? NavLeftInactive : NavLeftActive
                          }
                        />
                      </IconButton>
                      <IconButton
                        className='!p-0 !m-0'
                        onClick={() => {
                          handlePageChange(currentPage + 1)
                        }}
                        disabled={
                          currentPage >=
                          Math.ceil(
                            paymentsData.meta?.totalRecords /
                              paymentsData.meta?.limit
                          )
                        }
                      >
                        <img
                          alt=''
                          src={
                            currentPage >=
                            Math.ceil(
                              paymentsData.meta?.totalRecords /
                                paymentsData.meta?.limit
                            )
                              ? NavRightInactive
                              : NavRightActive
                          }
                        />
                      </IconButton>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
            {isGettingPayments ? <IsLoading /> : <InOutGoingPayments />}
            {/* <Stack spacing={2}>
              <Pagination
                count={paymentsData.meta?.totalPages}
                color='standard'
                page={currentPage}
                onChange={(data, value) => {
                  setCurrentPage(value)
                  setFilterParams({
                    ...filterParams,
                    page: value.toString()
                  })
                  void getPayments({ ...filterParams, page: value.toString() })
                }}
              />
            </Stack> */}
          </Stack>
        </Stack>
      </Stack>
    </UserDashBoardLayout>
  )
}
