import { Stack } from '@mui/material'
import React, { type ReactNode } from 'react'

interface ISelectInput {
    name?: string
    value?: any
    placeholder?: any
    onChange?: any
    addClass?: string
    children?: ReactNode
}

export const SelectInput: React.FC<ISelectInput> = ({ name, value, placeholder, onChange, addClass, children }) => {
    return (
        <Stack direction='column' className='w-full items-start'>
            <select
                name={name as string}
                value={value}
                placeholder={placeholder as string}
                onChange={onChange}
                className={`!w-full !rounded-lg !pr-3 ${addClass as string}`}>
                {children}
            </select>
        </Stack>
    )
}
