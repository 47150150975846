import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'

import translationEnglish from './translation/en/dashboardTranslation.json'
import translationFrench from './translation/fr/dashboardTranslation.json'
import translationGerman from './translation/de/dashboardTranslation.json'
import translationEnglishWeb from './translation/en/webTranslation.json'
import translationFrenchWeb from './translation/fr/webTranslation.json'
import translationGermanWeb from './translation/de/webTranslation.json'

const resources = {
    en: {
        dashboard: translationEnglish,
        web: translationEnglishWeb
    },
    fr: {
        dashboard: translationFrench,
        web: translationFrenchWeb
    },
    de: {
        dashboard: translationGerman,
        web: translationGermanWeb
    }
}

i18next
    .use(initReactI18next)
    .use(detector)
    .init({
        interpolation: { escapeValue: false },
        resources,
        lng: 'en'
    }).then().catch(err => { console.error(err) })

export default i18next
